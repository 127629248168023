<template>
  <div>
    <div class="level">
      <div class="level-left">
        <p class="level-item">
          <b-button
            tag="router-link"
            type="is-success"
            :to="{ name: 'live.edit.vehicle.add' }"
            icon-left="plus"
            label="Nouveau"
            :disabled="isLiveFinished"
          />
        </p>
      </div>
      <div class="level-right">
        <p class="level-item">
          <live-vehicle-export-csv
            :loading="loading"
            :live-vehicles="liveVehicles"
            :fileName="`${live.slug}-vehicles`"
          />
        </p>
      </div>
    </div>
    <div class="level">
      <div class="level-left">
        <p class="level-item">
          <o-modal-button
            v-if="showDeleteAllButton"
            type="is-danger"
            icon="trash"
            label="Supprimer les participants"
            title="Supprimer tous les participants"
            @confirmModalAction="$emit('deleteAll')"
          >
            La suppression des participants est <strong>définitive</strong>.<br />Cela
            <strong>inclut la suppression des traces et des alertes</strong>.
          </o-modal-button>
        </p>
      </div>
      <div class="level-right">
        <b-field class="level-item">
          <b-input
            v-model="filters.keyword"
            type="text"
            placeholder="Nom, Dossard, Balise"
            icon-right-clickable
            icon-right="close-circle"
            @icon-right-click="filters.keyword = ''"
          />
        </b-field>
      </div>
    </div>
    <div class="box is-relative">
      <live-vehicle-table
        :loading="loading"
        :live-vehicles="liveVehiclesList"
        @exportGpx="exportGpx"
        @delete="openConfirmModal"
      />

      <live-vehicle-import-csv
        class="mt-3"
        :live="live"
        :showUploadButton="!loading && liveVehicles.length === 0 && !isLiveFinished"
        @done="$emit('import', $event)"
        @submit="$emit('submit')"
        @error="$emit('error')"
      />

      <o-modal
        :title="confirmModalTitle"
        :isOpen="liveVehicleSelected !== null"
        @closeModal="closeConfirmModal"
        @confirmModalAction="$emit('delete', liveVehicleSelected)"
      >
        <template>
          La suppression d'un véhicule est
          <strong>définitive</strong>.<br />
          Cela
          <strong>inclut la suppression de sa trace et de ses alertes</strong>.
        </template>
      </o-modal>
    </div>
  </div>
</template>

<script>
import { downloadFile } from '@helpers/downloadFile'
import { stringNormalizer } from '@helpers/stringNormalizer.js'
import { mapActions } from 'vuex'
import OModal from '@components/Modal.vue'
import OModalButton from '@components/ModalButton.vue'
import LiveVehicleTable from './LiveVehicleTable.vue'
import LiveVehicleExportCsv from './LiveVehicleExportCsv.vue'
import LiveVehicleImportCsv from './LiveVehicleImportCsv.vue'
import { STATUS_FINISHED, STATUS_PENDING } from '@constants/live/status'

export default {
  name: 'LiveVehicleList.vue',

  components: {
    OModal,
    OModalButton,
    LiveVehicleTable,
    LiveVehicleImportCsv,
    LiveVehicleExportCsv,
  },

  props: {
    loading: {
      type: Boolean,
      required: true,
    },
    live: {
      type: Object,
      required: true,
    },
    liveVehicles: {
      type: Array,
      required: true,
      validator: (v) =>
        v.every((obj) =>
          [
            'id',
            'name',
            'number',
            'gender',
            'country',
            'accessType',
            'trackers',
            'categoryName',
          ].every((key) => key in obj),
        ),
    },
  },

  data() {
    return {
      liveVehicleSelected: null,
      filters: {
        keyword: '',
      },
    }
  },

  computed: {
    confirmModalTitle() {
      return `Suppression du participant ${
        this.liveVehicleSelected && this.liveVehicleSelected.number
      } - ${this.liveVehicleSelected && this.liveVehicleSelected.name}`
    },

    liveVehiclesList() {
      return this.liveVehicles.filter((vehicle) => {
        const term = stringNormalizer(this.filters.keyword?.toLowerCase())
        const name = stringNormalizer(vehicle.name.toLowerCase())
        const number = stringNormalizer(vehicle.number.toLowerCase())
        const trackerNumbers = stringNormalizer(
          vehicle.trackers
            .map((tracker) => tracker.number)
            .join(' ')
            .toLowerCase(),
        )

        return (
          name.indexOf(term) >= 0 || number.indexOf(term) >= 0 || trackerNumbers.indexOf(term) >= 0
        )
      })
    },

    isLiveFinished() {
      return this.live.status === STATUS_FINISHED
    },

    showDeleteAllButton() {
      return this.liveVehicles.length > 0 && this.live.status === STATUS_PENDING
    },
  },

  methods: {
    ...mapActions('ui', ['addToastMessage']),

    openConfirmModal(liveVehicle) {
      this.liveVehicleSelected = liveVehicle
    },

    closeConfirmModal() {
      this.liveVehicleSelected = null
    },

    async exportGpx(liveVehicle) {
      const liveVehicleLocationGpx = await this.$services.liveVehicleService.exportGpx(
        liveVehicle.id,
        liveVehicle.name,
      )

      downloadFile(liveVehicleLocationGpx, 'gpx', `${this.live.slug}-${liveVehicle.number}`)
    },
  },
}
</script>
