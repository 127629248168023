<template>
  <div class="box live-vehicle-tracker-history-table">
    <h2 class="title is-6">Historique</h2>
    <span v-if="liveVehicleTrackerHistories.length === 0"> Aucun historique </span>
    <b-table
      v-else
      :data="sortedLiveVehicleTrackerHistories"
      detailed
      detail-transition="fade"
      show-detail-icon
      striped
    >
      <b-table-column v-slot="{ row }" centered field="fromLiveVehicleId" label="Participant 1">
        {{ getVehicleInformation(row.fromLiveVehicleId) }}
      </b-table-column>
      <b-table-column v-slot="{ row }" centered field="toTrackerId">
        <div class="live-vehicle-tracker-history-table__tags">
          <b-tag
            type="is-danger is-light"
            size="is-medium"
            style="text-decoration: line-through"
            class="is-flex-grow-1"
          >
            {{ getTrackerInformation(row.fromTrackerId, row.fromTrackerType) }}
          </b-tag>
          <b-tag type="is-success is-light" size="is-medium" class="is-flex-grow-1">
            {{ getTrackerInformation(row.toTrackerId, row.toTrackerType) }}
          </b-tag>
        </div>
      </b-table-column>
      <b-table-column centered>
        <b-icon icon="exchange-alt" type="is-primary" />
      </b-table-column>
      <b-table-column v-slot="{ row }" centered field="fromTrackerId">
        <div class="live-vehicle-tracker-history-table__tags">
          <b-tag
            type="is-danger is-light"
            size="is-medium"
            style="text-decoration: line-through"
            class="is-flex-grow-1"
          >
            {{ getTrackerInformation(row.toTrackerId, row.toTrackerType) }}
          </b-tag>
          <b-tag type="is-success is-light" size="is-medium" class="is-flex-grow-1">
            {{ getTrackerInformation(row.fromTrackerId, row.fromTrackerType) }}
          </b-tag>
        </div>
      </b-table-column>
      <b-table-column v-slot="{ row }" centered field="toLiveVehicleId" label="Participant 2">
        {{ getVehicleInformation(row.toLiveVehicleId) }}
      </b-table-column>
      <template #detail="{ row }">
        <div v-show="row.comment">
          {{ row.comment }}
          <hr />
        </div>
        Modifié par
        <strong>{{ administratorNames[row.administratorId] }}</strong>
        le {{ dateFormat(row.updatedAt) }}
      </template>
    </b-table>
  </div>
</template>

<script>
import { compareDesc, format } from 'date-fns'
import { QUECLINK_TRACKER_TYPE, SPOT_TRACKER_TYPE } from '@constants/tracker/type.js'

export default {
  name: 'LiveVehicleTrackerHistoryTable',

  props: {
    loading: {
      type: Boolean,
      required: true,
    },
    administrators: {
      type: Array,
      required: true,
      validator: (v) => v.every((obj) => ['id', 'firstname'].every((key) => key in obj)),
    },
    spotTrackers: {
      type: Object,
      required: true,
      validator: (v) =>
        Object.values(v).every((obj) => ['id', 'number'].every((key) => key in obj)),
    },
    queclinkTrackers: {
      type: Object,
      required: true,
      validator: (v) =>
        Object.values(v).every((obj) => ['id', 'number'].every((key) => key in obj)),
    },
    liveVehicles: {
      type: Array,
      required: true,
      validator: (v) => v.every((obj) => ['id', 'name', 'number'].every((key) => key in obj)),
    },
    liveVehicleTrackerHistories: {
      type: Array,
      required: true,
      validator: (v) =>
        v.every((obj) =>
          [
            'administratorId',
            'fromLiveVehicleId',
            'fromTrackerId',
            'fromTrackerType',
            'toLiveVehicleId',
            'toTrackerId',
            'toTrackerType',
            'comment',
          ].every((key) => key in obj),
        ),
    },
  },

  computed: {
    administratorNames() {
      return this.administrators.reduce(
        (administrators, administrator) => ({
          ...administrators,
          [administrator.id]: administrator.firstname,
        }),
        {},
      )
    },

    liveVehiclesList() {
      return this.liveVehicles.reduce(
        (vehicles, vehicle) => ({ ...vehicles, [vehicle.id]: { ...vehicle } }),
        {},
      )
    },

    sortedLiveVehicleTrackerHistories() {
      return [...this.liveVehicleTrackerHistories].sort((curr, next) => {
        const a = curr.updatedAt
        const b = next.updatedAt

        return compareDesc(a, b)
      })
    },
  },

  methods: {
    getVehicleInformation(vehicleId) {
      return `${this.liveVehiclesList[vehicleId].number} - ${this.liveVehiclesList[vehicleId].name}`
    },

    getTrackerInformation(trackerId, trackerType) {
      if (trackerType === SPOT_TRACKER_TYPE) {
        return `${SPOT_TRACKER_TYPE} - ${this.spotTrackers[trackerId].number}`
      }

      if (trackerType === QUECLINK_TRACKER_TYPE) {
        return `${QUECLINK_TRACKER_TYPE} - ${this.queclinkTrackers[trackerId].number}`
      }

      return '—'
    },

    dateFormat(date) {
      return format(date, 'dd/MM/yyyy HH:mm')
    },
  },
}
</script>

<style lang="scss">
.live-vehicle-tracker-history-table {
  &__tags {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 3px;
  }
}
</style>
