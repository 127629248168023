<template>
  <canvas />
</template>

<script>
import Chart from 'chart.js/auto'

export default {
  name: 'OChart',

  props: {
    options: {
      type: Object,
      required: true,
    },
  },

  mounted() {
    require('chartjs-adapter-date-fns')

    this.$chart = new Chart(this.$el, this.options)
  },

  watch: {
    options({ options, data }) {
      this.$chart.options = options
      this.$chart.data = data

      this.$chart.update()
    },
  },

  beforeDestroy() {
    this.$chart.destroy()
  },
}
</script>
