import { QUECLINK_TRACKER_TYPE, SPOT_TRACKER_TYPE } from '@constants/tracker/type'

export const ROUTES = {
  [SPOT_TRACKER_TYPE]: {
    name: 'spotTracker',
  },
  [QUECLINK_TRACKER_TYPE]: {
    name: 'queclinkTracker',
  },
}
