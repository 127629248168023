<template>
  <live-speed-zone-form
    :loading="loading"
    :live-stage-id="liveStageId"
    :stage-routes="stageRoutes"
    :stage-waypoints="stageWaypoints"
    :stage-speed-zones="stageSpeedZones"
    :live-categories="liveCategories"
    @submit="submitZones"
  />
</template>

<script>
import { mapActions } from 'vuex'
import LiveSpeedZoneForm from './LiveSpeedZoneForm'
import { STAGE_ACCESS_TYPE_PRIVATE, STAGE_ACCESS_TYPE_PUBLIC } from '@constants/stage/accessType'

export default {
  name: 'EditLiveAddSpeedZoneTab',

  components: {
    LiveSpeedZoneForm,
  },

  props: {
    id: {
      type: String,
      required: true,
    },
    liveStageId: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      loading: false,
      stageSpeedZones: [],
      stageWaypoints: [],
      stageRoutes: { public: [], private: [] },
      liveCategories: [],
    }
  },

  async mounted() {
    await this.load()
  },

  methods: {
    ...mapActions('ui', ['addToastMessage']),

    async load() {
      this.loading = true

      await Promise.all([
        this.fetchSpeedZones(),
        this.fetchWaypoints(),
        this.fetchStageByAccessType(STAGE_ACCESS_TYPE_PUBLIC),
        this.fetchStageByAccessType(STAGE_ACCESS_TYPE_PRIVATE),
        this.fetchLiveCategories(),
      ])

      this.loading = false
    },

    async submitZones(zones) {
      this.loading = true

      await Promise.all(zones.map((zone) => this.submit(zone)))
      await this.$router.push({ name: 'live.edit.stage.edit', params: { tab: 3 } })

      this.loading = false
    },

    async submit(zone) {
      try {
        const liveStageSpeedZone = await this.$services.liveStageSpeedZoneService.create(zone)

        this.addToastMessage({
          text: `La zone de vitesse "${liveStageSpeedZone.name}" a été créé.`,
          type: 'is-success',
        })
      } catch (err) {
        this.addToastMessage({
          text: `Création de la zone ${zone.name} échoué`,
          type: 'is-danger',
        })
        console.error(err)
      }
    },

    async fetchSpeedZones() {
      try {
        this.stageSpeedZones = await this.$services.liveStageSpeedZoneService.getAll(
          this.liveStageId,
        )
      } catch (e) {
        this.stageSpeedZones = []
      }
    },

    async fetchStageByAccessType(accessType) {
      try {
        this.stageRoutes[accessType] =
          await this.$services.liveStageRouteService.getAllByAccessType(
            this.liveStageId,
            accessType,
          )
      } catch (e) {
        this.stageRoutes[accessType] = []
      }
    },

    async fetchWaypoints() {
      try {
        this.stageWaypoints = await this.$services.liveStageWaypointService.getAll(this.liveStageId)
      } catch (e) {
        this.stageWaypoints = []
      }
    },

    async fetchLiveCategories() {
      try {
        this.liveCategories = await this.$services.liveCategoryService.getAll(this.id)
      } catch (e) {
        this.liveCategories = []
      }
    },
  },
}
</script>
