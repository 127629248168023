<template>
  <div class="box">
    <o-table
      :items="lives"
      paginated
      :default-sort="['startedAt', 'desc']"
      :loading="loading"
      :draggable="false"
    >
      <b-table-column v-slot="{ row }" field="visitorAccessType" label="Accès" centered>
        <b-icon
          size="is-small"
          :type="visitorAccessTypeIcon[row.visitorAccessType].type"
          :icon="visitorAccessTypeIcon[row.visitorAccessType].icon"
        />
      </b-table-column>
      <b-table-column v-slot="{ row }" field="name" label="Nom">
        <a target="_blank" :href="url + '/' + row.slug" rel="noreferrer noopener">
          {{ row.name }}
        </a>
      </b-table-column>
      <b-table-column v-slot="{ row }" field="status" label="Status" sortable>
        <b-tag :type="statusTag[row.status].type">
          {{ statusTag[row.status].label }}
        </b-tag>
      </b-table-column>
      <b-table-column v-slot="{ row }" field="startedAt" label="Début" sortable>
        {{ row.startedAt | date }}
      </b-table-column>
      <b-table-column v-slot="{ row }" field="endedAt" label="Fin" sortable>
        {{ row.endedAt | date }}
      </b-table-column>
      <b-table-column v-slot="{ row }" label="">
        <div class="buttons are-small is-right">
          <b-button
            type="is-primary"
            tag="router-link"
            :to="{ name: 'live.edit.informations', params: { id: row.id } }"
          >
            <b-icon icon="edit" />
          </b-button>
          <b-button type="is-danger" @click="openDeleteModal(row)">
            <b-icon icon="trash" />
          </b-button>
        </div>
      </b-table-column>
    </o-table>
    <o-modal
      :title="modalTitle"
      :isOpen="!!liveSelected"
      @closeModal="closeModal"
      @confirmModalAction="$emit('delete', liveSelected)"
    >
      <template>
        La suppression d'un live est <strong>définitive</strong>.<br />
        Cela<strong> inclut la suppression de ses catégories, véhicules, étapes et options </strong>
        .
      </template>
    </o-modal>
  </div>
</template>

<script>
import { format } from 'date-fns'
import OModal from '@components/Modal.vue'
import OTable from '@components/Table.vue'
import { statusTag, visitorAccessTypeIcon } from './index.js'

export default {
  name: 'ListLivesTable',

  components: {
    OModal,
    OTable,
  },

  props: {
    loading: {
      type: Boolean,
      required: true,
    },
    lives: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      liveSelected: null,
    }
  },

  created() {
    this.url = process.env.LIVE_WEBAPP_URL
    this.statusTag = statusTag
    this.visitorAccessTypeIcon = visitorAccessTypeIcon
  },

  computed: {
    modalTitle() {
      return `Suppression du live ${this.liveSelected && this.liveSelected.name}`
    },
  },

  methods: {
    openDeleteModal(live) {
      this.liveSelected = live
    },

    closeModal() {
      this.liveSelected = null
    },
  },

  filters: {
    date(value) {
      return format(value, 'dd/MM/yyyy')
    },
  },
}
</script>
