<template>
  <div class="live-stage-map">
    <map-view
      class="live-stage-map__map"
      :map-bounds="bounds"
      :map-center="center"
      @click="$emit('click', $event)"
    >
      <o-map-polyline
        v-for="route in filteredRoutes"
        :key="'route-' + route.id"
        :name="route.name"
        :path="route.path"
        :color="route.color"
        :opacity="routeOpacity"
        :dashed="true"
        :strokeWeight="4"
        @click="$emit('selectRoute', $event, route)"
      />

      <o-map-zone
        v-for="zone in stageSpeedZones"
        :key="zone.id"
        :name="zone.name"
        :path="zone.path"
        :color="zone.color"
        :radius="zone.distance"
        @click="$emit('selectZone', $event, zone)"
      />

      <o-map-square-marker
        v-for="waypoint in filteredWaypoints"
        :key="waypoint.id"
        :label="waypoint.name"
        :iconName="waypoint.icon"
        :color="waypoint.color"
        :lat-lng="waypoint.location"
        :opacity="waypointOpacity"
      />

      <slot />
    </map-view>
    <b-field class="live-stage-map__radio">
      <b-radio-button
        v-for="mapRadioButton in mapRadioButtons"
        :key="mapRadioButton.nativeValue"
        v-model="fields.accessType"
        :native-value="mapRadioButton.nativeValue"
      >
        <span>{{ mapRadioButton.label }}</span>
      </b-radio-button>
    </b-field>
  </div>
</template>

<script>
import MapView from '@views/MapView/index.vue'
import OMapZone from '@components/Map/MapZone.vue'
import OMapPolyline from '@components/Map/MapPolyline'
import OMapSquareMarker from '@components/Map/MapSquareMarker'
import { STAGE_ACCESS_TYPE_PUBLIC, STAGE_ACCESS_TYPE_PRIVATE } from '@constants/stage/accessType'
import { WAYPOINT_ACCESS_TYPE_PUBLIC } from '@constants/waypoint/accessType'

export default {
  name: 'LiveStageMap',

  components: {
    OMapZone,
    MapView,
    OMapPolyline,
    OMapSquareMarker,
  },

  props: {
    stageRoutes: {
      type: Object,
      default: () => ({ [STAGE_ACCESS_TYPE_PUBLIC]: [], [STAGE_ACCESS_TYPE_PRIVATE]: [] }),
      validator: (v) =>
        [STAGE_ACCESS_TYPE_PUBLIC, STAGE_ACCESS_TYPE_PRIVATE].every((key) => key in v),
    },
    routeOpacity: {
      type: Number,
      default: 1,
    },
    stageWaypoints: {
      type: Array,
      default: () => [],
    },
    waypointOpacity: {
      type: Number,
      default: 1,
    },
    stageSpeedZones: {
      type: Array,
      default: () => [],
    },
    center: {
      type: Array,
      default: () => [0, 0],
    },
  },

  data() {
    return {
      fields: {
        accessType: STAGE_ACCESS_TYPE_PRIVATE,
      },
    }
  },

  created() {
    this.mapRadioButtons = [
      { nativeValue: STAGE_ACCESS_TYPE_PUBLIC, label: 'Public' },
      { nativeValue: STAGE_ACCESS_TYPE_PRIVATE, label: 'Privé' },
    ]
  },

  computed: {
    filteredRoutes() {
      if (
        this.stageRoutes[STAGE_ACCESS_TYPE_PRIVATE].length === 0 &&
        this.stageRoutes[STAGE_ACCESS_TYPE_PUBLIC].length > 0
      ) {
        return this.stageRoutes[STAGE_ACCESS_TYPE_PUBLIC]
      }

      return this.stageRoutes[this.fields.accessType]
    },

    filteredWaypoints() {
      return this.fields.accessType === WAYPOINT_ACCESS_TYPE_PUBLIC
        ? this.stageWaypoints.filter((waypoint) => waypoint.accessType === this.fields.accessType)
        : this.stageWaypoints
    },

    bounds() {
      return this.filteredRoutes.length > 0 ? this.filteredRoutes[0].bounds : [0, 0]
    },
  },
}
</script>

<style lang="scss">
@import 'variables';

$map-view-min-height: 300px;

.live-stage-map {
  overflow: hidden;

  &__map {
    height: 100%;
    min-height: $map-view-min-height;
  }

  &__radio {
    position: absolute;
    top: $spacing-s;
    left: $spacing-s;
    z-index: 999;
  }
}
</style>
