<template>
  <div>
    <div class="level">
      <div class="level-left">
        <p class="level-item">
          <b-button tag="router-link" type="is-success" :to="{ name: 'live.edit.category.add' }">
            <b-icon icon="plus" size="is-small" />
            <span>Nouveau</span>
          </b-button>
        </p>
      </div>
    </div>
    <div class="box is-relative">
      <b-loading :is-full-page="false" :active="loading" />
      <b-table
        :data="liveCategories"
        striped
        hoverable
        :draggable="!loading"
        @drop="drop"
        @dragstart="dragstart"
        @dragover="dragover"
        @dragleave="dragleave"
      >
        <b-table-column>
          <b-icon size="is-small" icon="grip-lines" />
        </b-table-column>
        <b-table-column v-slot="{ row }" field="position" :visible="false">
          {{ row.position }}
        </b-table-column>
        <b-table-column v-slot="{ row }" field="accessType" label="Accès">
          <b-icon
            v-if="row.accessType === 'public'"
            size="is-small"
            type="is-success"
            icon="unlock"
          />
          <b-icon
            v-else-if="row.accessType === 'private'"
            size="is-small"
            type="is-danger"
            icon="lock"
          />
        </b-table-column>
        <b-table-column v-slot="{ row }" field="name" label="Nom">
          {{ row.name }}
        </b-table-column>
        <b-table-column v-slot="{ row }" field="icon" label="Icone">
          <o-icon :name="row.icon" />
        </b-table-column>
        <b-table-column v-slot="{ row }" field="color" label="Couleur">
          {{ row.color }}
        </b-table-column>
        <b-table-column v-slot="{ row }" field="useCheckpoint" label="Validation de CP">
          <b-icon
            size="is-medium"
            :type="row.useCheckpoint ? 'is-success' : 'is-danger'"
            :icon="row.useCheckpoint ? 'check' : 'times'"
          />
        </b-table-column>
        <b-table-column v-slot="{ row }" label="">
          <div class="buttons are-small is-right">
            <b-button
              type="is-primary"
              tag="router-link"
              :to="{
                name: 'live.edit.category.edit',
                params: { liveCategoryId: row.id },
              }"
            >
              <b-icon icon="edit" />
            </b-button>
            <b-button type="is-danger" @click="openModal(row)">
              <b-icon icon="trash" />
            </b-button>
          </div>
        </b-table-column>
      </b-table>
      <div v-show="!loading && errorMessage" class="has-text-grey has-text-centered">
        {{ errorMessage }}
      </div>
      <o-modal
        :title="modalTitle"
        :isOpen="!!liveCategorySelected"
        @closeModal="closeModal"
        @confirmModalAction="deleteLive"
      >
        <template>
          La suppression d'une catégorie est <strong>définitive</strong>.<br />
          Cela <strong>inclut la suppression de ses véhicules</strong>.
        </template>
      </o-modal>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import OModal from '@components/Modal.vue'

export default {
  name: 'EditLiveCategoriesTab',

  components: {
    OModal,
  },

  props: {
    live: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      loading: false,
      errorMessage: null,
      liveCategories: [],
      draggingRow: null,
      draggingRowIndex: null,
      liveCategorySelected: null,
    }
  },

  async mounted() {
    await this.fetch()
  },

  watch: {
    liveCategories(liveCategories) {
      if (!this.errorMessage && liveCategories.length === 0) {
        this.errorMessage = 'Aucune catégorie'
      }
    },
  },

  computed: {
    modalTitle() {
      return `Suppression de la catégorie ${
        this.liveCategorySelected && this.liveCategorySelected.name
      }`
    },
  },

  methods: {
    ...mapActions('ui', ['addToastMessage']),

    async fetch() {
      this.errorMessage = null
      this.loading = true

      try {
        this.liveCategories = await this.$services.liveCategoryService.getAll(this.live.id)
      } catch (err) {
        this.errorMessage = 'Une erreur interne est survenue.'
        console.error(err)
      }

      this.loading = false
    },

    async update(categories) {
      for (const [index, category] of categories.entries()) {
        if (category.position !== index) {
          // Update state
          this.liveCategories[index].position = index

          await this.$services.liveCategoryService.update(category.id, {
            ...category,
            position: index,
          })
        }
      }
    },

    async submit(categories) {
      this.errorMessage = null
      this.loading = true

      try {
        await this.update(categories)

        this.addToastMessage({
          text: `La catégorie "${this.draggingRow.name}" a été modifié.`,
          type: 'is-success',
        })
      } catch (err) {
        this.errorMessage = 'Une erreur interne est survenue.'
        console.error(err)
      }

      this.loading = false
    },

    dragstart(payload) {
      this.draggingRow = payload.row
      this.draggingRowIndex = payload.index
      payload.event.dataTransfer.effectAllowed = 'copy'
    },

    dragover(payload) {
      payload.event.dataTransfer.dropEffect = 'copy'
      payload.event.target.closest('tr').classList.add('is-selected')
      payload.event.preventDefault()
    },

    dragleave(payload) {
      payload.event.target.closest('tr').classList.remove('is-selected')
      payload.event.preventDefault()
    },

    drop(payload) {
      payload.event.target.closest('tr').classList.remove('is-selected')
      const droppedOnRowIndex = payload.index

      if (this.draggingRow.position !== droppedOnRowIndex) {
        this.liveCategories.splice(
          droppedOnRowIndex,
          0,
          ...this.liveCategories.splice(this.draggingRowIndex, 1),
        )

        this.submit(this.liveCategories)
      }
    },

    openModal(liveCategory) {
      this.liveCategorySelected = liveCategory
    },

    closeModal() {
      this.liveCategorySelected = null
    },

    async deleteLive() {
      try {
        const liveCategoryDeleted = await this.$services.liveCategoryService.delete(
          this.liveCategorySelected.id,
        )
        this.liveCategories = this.liveCategories.filter(
          (category) => category.id !== liveCategoryDeleted.id,
        )
        this.addToastMessage({
          text: `La catégorie "${liveCategoryDeleted.name}" a été supprimé.`,
          type: 'is-success',
        })
      } catch (err) {
        this.addToastMessage({
          text: err.message ? err.message : 'Une erreur interne est survenue.',
          type: 'is-danger',
        })
      }
    },
  },
}
</script>
