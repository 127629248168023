<template>
  <b-field :label="`Dates : ${formatDate(range[0])} - ${formatDate(range[1])}`">
    <b-slider
      v-model="range"
      :disabled="loading"
      :min="minMax.min"
      :max="minMax.max"
      :step="step30Min"
      :custom-formatter="formatDate"
      @change="onEnd"
    />
  </b-field>
</template>

<script>
import { format } from 'date-fns'

export default {
  name: 'EditLiveReviewTrackerDateRange',

  props: {
    live: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      range: [0, 0],
    }
  },

  created() {
    this.step30Min = 30 * 60 * 1000
    this.range = [this.minMax.min, this.minMax.max]
  },

  computed: {
    minMax() {
      return {
        min: this.live.startedAt.setHours(0, 0, 0),
        max: this.live.endedAt.setHours(23, 59, 59),
      }
    },
  },

  methods: {
    onEnd() {
      this.$emit('onEnd', { start: new Date(this.range[0]), end: new Date(this.range[1]) })
    },

    formatDate(date) {
      return format(new Date(date), 'dd/MM/yyyy HH:mm')
    },
  },
}
</script>
