<template>
  <div>
    <b-field label="Visualisation de la mauvaise réception des points > 5min en rouge ">
      <map-view ref="map" class="edit-live-review-tracker-map__map" style="border-radius: 3px">
        <o-map-polyline
          v-for="route in routes"
          :key="'route-' + route.id"
          :name="route.name"
          :path="route.path"
          :color="route.color"
          :strokeWeight="4"
        />
        <edit-live-review-tracker-markers
          v-for="tracker in filteredTrackers"
          :key="tracker.id"
          :tracker="tracker"
        />
      </map-view>
    </b-field>
    <div v-if="trackers.length > 1" class="is-flex is-justify-content-center">
      <div
        v-for="tracker in trackers"
        :key="tracker.id"
        :style="!showTrackerIds.includes(tracker.id) && { textDecoration: 'line-through' }"
        class="is-size-7 is-flex is-align-items-center mr-2 is-clickable"
        @click="toggleTracker(tracker.id)"
      >
        <div
          :style="{
            backgroundColor: tracker.color,
            width: '42px',
            height: '14px',
          }"
          class="mr-1"
        />
        {{ tracker.name }}
      </div>
    </div>
  </div>
</template>

<script>
import EditLiveReviewTrackerMarkers from './EditLiveReviewTrackerMarkers.vue'
import OMapPolyline from '@components/Map/MapPolyline'
import MapView from '@views/MapView/index.vue'

export default {
  name: 'EditLiveReviewTrackerMap',

  components: {
    EditLiveReviewTrackerMarkers,
    OMapPolyline,
    MapView,
  },

  props: {
    trackers: {
      type: Array,
    },
  },

  data() {
    return {
      showTrackerIds: [],
    }
  },

  watch: {
    trackers() {
      this.showTrackerIds = this.trackers.map(({ id }) => id)
    },
  },

  computed: {
    filteredTrackers() {
      return this.trackers.filter((tracker) => this.showTrackerIds.includes(tracker.id))
    },

    routes() {
      return this.filteredTrackers.map((tracker) => ({
        ...tracker,
        path: tracker.locations.map(({ location }) => location),
      }))
    },
  },

  methods: {
    async toggleTracker(trackerId) {
      if (this.showTrackerIds.includes(trackerId)) {
        this.showTrackerIds = this.showTrackerIds.filter((id) => id !== trackerId)
      } else {
        this.showTrackerIds.push(trackerId)
      }
    },
  },
}
</script>

<style lang="scss">
@import 'variables';

.edit-live-review-tracker-map {
  &__map {
    height: 500px;
  }
}
</style>
