<template>
  <form :novalidate="true" @submit.prevent="submit">
    <div class="columns is-multiline">
      <drag-drop
        v-model="fields.imageFile.value"
        label="Image (360x360)"
        :loading="loading"
        :errorMessage="fields.imageFile.error"
        class="column is-full-mobile"
      />
      <div class="column is-full-mobile">
        <b-field
          label="Nom"
          :message="fields.name.error"
          :type="{ 'is-danger': fields.name.error }"
        >
          <b-input
            v-model="fields.name.value"
            expanded
            placeholder="Nom du sponsor"
            type="text"
            :icon-right="fields.name.value ? 'close-circle' : ''"
            :icon-right-clickable="!!fields.name.value"
            @icon-right-click="fields.name.value = null"
          />
        </b-field>
        <b-field
          label="Website"
          :message="fields.websiteUrl.error"
          :type="{ 'is-danger': fields.websiteUrl.error }"
        >
          <b-input
            v-model="fields.websiteUrl.value"
            expanded
            placeholder="https://example.com"
            type="url"
            :icon-right="fields.websiteUrl.value ? 'close-circle' : ''"
            :icon-right-clickable="!!fields.websiteUrl.value"
            @icon-right-click="fields.websiteUrl.value = null"
          />
        </b-field>
      </div>
    </div>
    <b-button
      native-type="submit"
      type="is-primary"
      :loading="loading"
      :disabled="isFormInvalid"
      label="Ajouter"
    />
  </form>
</template>

<script>
import { mapActions } from 'vuex'
import { isURL } from '@helpers/isURL.js'
import { checkFileType } from '@helpers/checkFileType.js'
import { convertFileToString } from '@helpers/convertFileToString.js'
import DragDrop from '@components/DragDrop.vue'

export default {
  name: 'LiveAddSponsorForm',

  components: {
    DragDrop,
  },

  props: {
    liveId: {
      type: String,
      required: true,
    },
    position: {
      type: Number,
      default: 0,
    },
  },

  data() {
    return {
      loading: false,
      fields: {
        name: {
          value: null,
          error: null,
        },
        websiteUrl: {
          value: null,
          error: null,
        },
        imageFile: {
          value: null,
          error: null,
        },
      },
    }
  },

  created() {
    this.imageType = 'image/jpeg, image/png'
  },

  watch: {
    'fields.websiteUrl.value'(websiteUrl) {
      this.fields.websiteUrl.error =
        websiteUrl && websiteUrl.length > 0 && !isURL(websiteUrl) ? 'Url incorrecte' : null
    },

    'fields.imageFile.value'(imageFile) {
      if (imageFile) {
        this.fields.imageFile.error = checkFileType(imageFile, this.imageType)
      }
    },
  },

  computed: {
    isFormInvalid() {
      return (
        Object.values(this.fields).find((field) => field.error !== null) ||
        this.fields.imageFile.value === null ||
        this.loading
      )
    },
  },

  methods: {
    ...mapActions('ui', ['addToastMessage']),

    async submit() {
      this.loading = true

      try {
        const createdLiveSponsor = await this.$services.liveSponsorService.create({
          liveId: this.liveId,
          position: this.position,
          name: this.fields.name.value,
          websiteUrl: this.fields.websiteUrl.value,
          image: await convertFileToString(this.fields.imageFile.value),
        })

        this.resetFields()
        this.$emit('submit', createdLiveSponsor)

        this.addToastMessage({
          text: 'Le sponsor a été créé.',
          type: 'is-success',
        })
      } catch (err) {
        this.addToastMessage({
          text: 'Une erreur interne est survenue.',
          type: 'is-danger',
        })
      }

      this.loading = false
    },

    resetFields() {
      Object.values(this.fields).forEach((field) => {
        field.value = null
        field.error = null
      })
    },
  },
}
</script>
