<template>
  <form novalidate="true" @submit.prevent="submit">
    <b-tabs v-model="activeTab">
      <b-tab-item v-for="locale in tabs" :key="locale" :label="localeLabels[locale]">
        <live-tutorial-fields-translation
          v-show="locale !== localeFr"
          :from-fields="fieldsToTranslate"
          :to-locale="locale"
          class="mb-4"
          @translate="translate"
        />
        <live-tutorial-form-fields
          :fields="fields[locale]"
          @updateField="setFields(locale, $event)"
        />
      </b-tab-item>
    </b-tabs>
    <b-field label="Accès">
      <b-field>
        <b-radio-button v-model="accessType" native-value="public" type="is-success">
          Public
        </b-radio-button>
        <b-radio-button v-model="accessType" native-value="private" type="is-danger">
          Privé
        </b-radio-button>
      </b-field>
    </b-field>
    <hr />

    <b-button native-type="submit" type="is-primary" :loading="loading" :disabled="isFormInValid">
      Ajouter
    </b-button>
  </form>
</template>

<script>
import LiveTutorialFormFields from './LiveTutorialFormFields.vue'
import LiveTutorialFieldsTranslation from './LiveTutorialFieldsTranslation.vue'

const LOCALE_FR = 'fr'
const LOCALE_EN = 'en'
const LOCALE_ES = 'es'

const LOCALE_LABELS = {
  [LOCALE_FR]: 'Francais',
  [LOCALE_EN]: 'Anglais',
  [LOCALE_ES]: 'Espagnol',
}

const FIELDS = ['title', 'videoUrl', 'description', 'summary']

export default {
  name: 'LiveTutorialForm',

  components: {
    LiveTutorialFormFields,
    LiveTutorialFieldsTranslation,
  },

  props: {
    loading: {
      type: Boolean,
      required: true,
    },
    liveTutorial: {
      type: Object,
      default: null,
    },
  },

  data() {
    const fields = [LOCALE_FR, LOCALE_EN, LOCALE_ES].reduce(
      (locales, locale) => ({
        ...locales,
        [locale]: FIELDS.reduce(
          (fields, field) => ({
            ...fields,
            [field]: { value: null, error: null },
          }),
          {},
        ),
      }),
      {},
    )

    return {
      fields,
      activeTab: 0,
      accessType: 'public',
    }
  },

  created() {
    this.localeFr = LOCALE_FR
    this.localeLabels = LOCALE_LABELS
    this.tabs = [LOCALE_FR, LOCALE_EN, LOCALE_ES]
  },

  mounted() {
    if (this.liveTutorial) {
      this.initFields()
    }
  },

  computed: {
    isFormInValid() {
      const hasError = Object.values(this.fields).some((field) =>
        Object.values(field).some(({ value, error }) => error !== null || !value),
      )

      return this.loading || hasError
    },

    fieldsToTranslate() {
      const { title, description, summary } = this.fields[LOCALE_FR]

      return {
        title: title.value,
        summary: summary.value,
        description: description.value,
      }
    },
  },

  methods: {
    submit() {
      if (this.isFormInValid) {
        return
      }

      this.$emit('submit', {
        ...this.liveTutorial,
        ...this.formatFieldValues(),
        accessType: this.accessType,
      })
    },

    initFields() {
      this.accessType = this.liveTutorial.accessType

      for (let locale of Object.keys(this.fields)) {
        for (let field of Object.keys(this.fields[locale])) {
          if (this.liveTutorial[field] === null) {
            continue
          }

          this.fields[locale][field].value = this.liveTutorial[field][locale]
        }
      }
    },

    setFields(locale, { key, field }) {
      this.fields[locale][key] = field
    },

    formatFieldValues() {
      let fieldValues = {}

      for (const key of FIELDS) {
        let newObj = {}
        for (const lang in this.fields) {
          newObj[lang] = this.fields[lang][key].value
        }

        fieldValues[key] = newObj
      }

      return fieldValues
    },

    translate({ locale, translations }) {
      Object.keys(translations).forEach(
        (key) => (this.fields[locale][key].value = translations[key]),
      )
    },
  },
}
</script>
