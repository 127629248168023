<template>
  <b-tabs
    v-model="activeTab"
    class="o-tabs"
    :destroy-on-hide="true"
    position="is-centered"
    type="is-toggle"
  >
    <b-tab-item v-for="(label, index) in labels" :key="label" :label="label">
      <slot :name="index" />
    </b-tab-item>
  </b-tabs>
</template>

<script>
export default {
  name: 'OTabs',

  props: {
    labels: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      activeTab: 0,
    }
  },

  watch: {
    $route: {
      handler(route) {
        if (route.params?.tab >= 0 && route.params?.tab <= this.labels.length) {
          this.activeTab = route.params.tab
        }
      },
      immediate: true,
    },
  },
}
</script>

<style lang="scss">
.o-tabs {
  .tab-content {
    padding-left: 0;
    padding-right: 0;
  }
}
</style>
