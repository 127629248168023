<template>
  <b-field grouped group-multiline class="columns">
    <b-field label="Balises" class="column is-full-mobile">
      <b-autocomplete
        v-model="keywords"
        open-on-focus
        field="name"
        :data="filteredTrackers"
        clearable
        clear-on-select
        :loading="loading"
        placeholder="Selectionner une balise"
        @select="selectTracker"
      >
        <template #empty>Aucune balise sur ce live</template>
      </b-autocomplete>
    </b-field>
    <b-field
      class="column is-full-mobile"
      :label="`Balises selectionnées: ${value.length} / ${maxSelectedTrackers}`"
    >
      <b-taglist>
        <b-tag
          v-for="tracker in value"
          :key="tracker.id"
          :style="{ backgroundColor: tracker.color, color: 'white' }"
          closable
          @close="removeSelectedTracker(tracker)"
        >
          {{ tracker.name }}
        </b-tag>
      </b-taglist>
    </b-field>
  </b-field>
</template>

<script>
import { QUECLINK_TRACKER_TYPE, SPOT_TRACKER_TYPE } from '@constants/tracker/type'

const COLORS = ['#2D62DD', '#2DAF32', '#585858', '#A02DDD', '#FFCE00']

export default {
  name: 'EditLiveReviewTrackerSelection',

  components: {},

  props: {
    value: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    liveVehicles: {
      type: Array,
      required: true,
    },
    spotTrackers: {
      type: Array,
      required: true,
    },
    queclinkTrackers: {
      type: Array,
      required: true,
    },
    maxSelectedTrackers: {
      type: Number,
      default: 5,
    },
  },

  data() {
    return {
      keywords: '',
    }
  },

  computed: {
    trackers() {
      return [
        ...this.spotTrackers.map((tracker) => ({
          id: tracker.id,
          type: SPOT_TRACKER_TYPE,
          name: `${tracker.number} - ${this.vehicleNumbers[tracker.id]}`,
          number: tracker.number,
          vehicleNumber: this.vehicleNumbers[tracker.id],
        })),
        ...this.queclinkTrackers.map((tracker) => ({
          id: tracker.id,
          type: QUECLINK_TRACKER_TYPE,
          name: `${tracker.number} - ${this.vehicleNumbers[tracker.id]}`,
          number: tracker.number,
          vehicleNumber: this.vehicleNumbers[tracker.id],
        })),
      ].filter(
        (tracker) => !this.value.some((selectedTracker) => selectedTracker.id === tracker.id),
      )
    },

    filteredTrackers() {
      if (this.keywords.length === 0) {
        return this.trackers
      }

      return this.trackers.filter((tracker) => {
        const term = this.keywords?.toLowerCase()
        const vehicleNumber = tracker.vehicleNumber?.toLowerCase()
        const trackerNumber = tracker.number?.toLowerCase()

        return vehicleNumber?.startsWith(term) || trackerNumber?.startsWith(term)
      })
    },

    vehicleNumbers() {
      return this.liveVehicles.reduce(
        (vehicles, vehicle) => ({
          ...vehicles,
          ...(vehicle.spotTrackerId ? { [vehicle.spotTrackerId]: vehicle.number } : {}),
          ...(vehicle.queclinkTrackerId ? { [vehicle.queclinkTrackerId]: vehicle.number } : {}),
        }),
        {},
      )
    },
  },

  methods: {
    selectTracker(tracker) {
      this.keywords = ''

      if (this.value.length >= this.maxSelectedTrackers || !tracker) {
        return
      }

      const hasTracker = this.value.some((selectedTracker) => selectedTracker.id === tracker.id)
      if (!hasTracker) {
        const tr = {
          id: tracker.id,
          name: tracker.name,
          type: tracker.type,
          locations: [],
          color: COLORS.find(
            (color) => !this.value.some((selectedTracker) => selectedTracker.color === color),
          ),
        }

        this.$emit('input', [...this.value, tr])
        this.$emit('selectTracker', tr)
      }
    },

    removeSelectedTracker(tracker) {
      this.$emit(
        'input',
        this.value.filter((selectedTracker) => selectedTracker.id !== tracker.id),
      )
    },
  },
}
</script>
