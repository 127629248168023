<template>
  <div class="mt-5">
    <b-button
      tag="router-link"
      type="is-success"
      :to="{ name: 'live.edit.stage.edit.waypoint.add' }"
      icon-left="plus"
      label="Nouveau"
      class="mb-4"
    />
    <div class="level">
      <div class="level-left">
        <h2 class="level-item title is-5 mb-5">Waypoints</h2>
        <h3 class="level-item subtitle is-6">(Vérifier accès public/privé)</h3>
      </div>
      <div class="level-right">
        <div class="level-item">
          <live-stage-waypoint-upload
            :loading="loading"
            :stageWaypoints="stageWaypoints"
            @submit="uploadGpxFile"
          />
        </div>
        <div class="level-item">
          <o-export-button
            :disabled="loading || stageWaypoints.length === 0"
            :file="stageWaypointsGpx"
            file-type="gpx"
            :file-name="`${this.live.name}-${this.liveStage.name}-waypoints`"
            @export="exportGpx"
            @exported="stageWaypointsGpx = null"
          />
        </div>
      </div>
    </div>
    <live-stage-waypoint-caption />
    <live-stage-waypoint-table
      :loading="loading"
      :stage-waypoints="stageWaypoints"
      :live-categories="liveCategories"
      @submit="updateWaypoint"
      @submitAll="updateAllCheckedWaypoints"
      @deleteAll="deleteAllCheckedWaypoints"
      @drop="updatePositions"
      @deleteWaypoint="deleteWaypoint"
    />
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import OExportButton from '@components/ExportButton'
import LiveStageWaypointTable from './LiveStageWaypointTable.vue'
import LiveStageWaypointUpload from './LiveStageWaypointUpload.vue'
import LiveStageWaypointCaption from './LiveStageWaypointCaption.vue'

export default {
  name: 'EditLiveStageWaypointTab',

  components: {
    OExportButton,
    LiveStageWaypointCaption,
    LiveStageWaypointUpload,
    LiveStageWaypointTable,
  },

  props: {
    live: {
      type: Object,
      required: true,
      validator: (v) => ['name'].every((key) => key in v),
    },
    liveStage: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      loading: false,
      stageWaypoints: [],
      liveCategories: [],
      stageWaypointsGpx: null,
    }
  },

  async mounted() {
    this.loading = true

    await Promise.all([this.fetchWaypoints(), this.fetchLiveCategories()])

    this.loading = false
  },

  watch: {
    stageWaypoints(stageWaypoints) {
      this.$emit('update:stageWaypoints', stageWaypoints)
    },
  },

  methods: {
    ...mapActions('ui', ['addToastMessage']),

    async uploadGpxFile(gpxFile) {
      this.loading = true

      try {
        this.stageWaypoints = await this.$services.liveStageWaypointService.replace(
          this.liveStage.id,
          gpxFile,
        )

        this.addToastMessage({
          text: `Les waypoints de "${this.liveStage.name}" ont été mis à jour.`,
          type: 'is-success',
        })
      } catch (err) {
        this.addToastMessage({
          text: 'Une erreur interne est survenue.',
          type: 'is-danger',
        })
      }

      this.loading = false
    },

    async updateWaypoint(waypoint) {
      this.loading = true

      try {
        const updatedWaypoint = await this.$services.liveStageWaypointService.update(waypoint.id, {
          ...waypoint,
          label: waypoint.label?.length > 0 ? waypoint.label : null,
        })

        this.stageWaypoints = this.stageWaypoints.map((stageWaypoint) =>
          stageWaypoint.id === updatedWaypoint.id ? updatedWaypoint : stageWaypoint,
        )

        this.addToastMessage({
          text: `Le point "${updatedWaypoint.name}" a été mis à jour.`,
          type: 'is-success',
        })
      } catch (err) {
        this.addToastMessage({
          text: 'Une erreur interne est survenue.',
          type: 'is-danger',
        })
      }

      this.loading = false
    },

    async updateAllCheckedWaypoints(waypoints) {
      this.loading = true

      try {
        const updatedWaypoints = await this.$services.liveStageWaypointService.updateAll({
          liveStageId: this.liveStage.id,
          waypoints,
        })

        this.updateStageWaypointList(updatedWaypoints)

        this.addToastMessage({
          text: `Les waypoints ont été mis à jour.`,
          type: 'is-success',
        })
      } catch (err) {
        this.addToastMessage({
          text: 'Une erreur interne est survenue.',
          type: 'is-danger',
        })
      }

      this.loading = false
    },

    async deleteAllCheckedWaypoints(waypointIds) {
      this.loading = true

      try {
        const deletedWaypoints = await this.$services.liveStageWaypointService.deleteAll({
          waypointIds,
        })

        this.stageWaypoints = [
          ...this.stageWaypoints.filter((waypoint) => {
            const ids = new Set(deletedWaypoints.map((w) => w.id))
            return !ids.has(waypoint.id)
          }),
        ].sort((current, next) => {
          const a = current.position
          const b = next.position

          if (a < b) return -1
          if (a > b) return 1

          return 0
        })

        this.addToastMessage({
          text: `Les waypoints ont été supprimés.`,
          type: 'is-success',
        })
      } catch (err) {
        this.addToastMessage({
          text: 'Une erreur interne est survenue.',
          type: 'is-danger',
        })
      }

      this.loading = false
    },

    async updatePositions(droppedOnRowIndex, draggingRowIndex, waypoint) {
      await this.updateWaypoint({ ...waypoint, position: droppedOnRowIndex })

      this.stageWaypoints.splice(
        droppedOnRowIndex,
        0,
        ...this.stageWaypoints.splice(draggingRowIndex, 1),
      )

      for (const [index, waypoint] of this.stageWaypoints.entries()) {
        if (waypoint.position === index) {
          continue
        }

        this.stageWaypoints[index].position = index
      }
    },

    async deleteWaypoint(waypoint) {
      this.loading = true

      try {
        const deletedWaypoint = await this.$services.liveStageWaypointService.delete(waypoint.id)
        this.stageWaypoints = this.stageWaypoints.filter((wpt) => wpt.id !== deletedWaypoint.id)

        this.addToastMessage({
          text: `Le waypoint "${deletedWaypoint.name}" a été supprimé.`,
          type: 'is-success',
        })
      } catch (err) {
        this.addToastMessage({
          text: err.message ? err.message : 'Une erreur interne est survenue.',
          type: 'is-danger',
        })
      }

      this.loading = false
    },

    updateStageWaypointList(updatedWaypoints) {
      this.stageWaypoints = [
        ...updatedWaypoints,
        ...this.stageWaypoints.filter((waypoint) => {
          const ids = new Set(updatedWaypoints.map((w) => w.id))
          return !ids.has(waypoint.id)
        }),
      ].sort((current, next) => {
        const a = current.position
        const b = next.position

        if (a < b) return -1
        if (a > b) return 1

        return 0
      })
    },

    async exportGpx() {
      this.stageWaypointsGpx = await this.$services.liveStageWaypointService.exportGpx({
        liveStageId: this.liveStage.id,
      })
    },

    async fetchWaypoints() {
      try {
        this.stageWaypoints = await this.$services.liveStageWaypointService.getAll(
          this.liveStage.id,
        )
      } catch (e) {
        this.stageWaypoints = []
      }
    },

    async fetchLiveCategories() {
      try {
        this.liveCategories = await this.$services.liveCategoryService.getAll(this.live.id)
      } catch (e) {
        this.liveCategories = []
      }
    },
  },
}
</script>
