<template>
  <form novalidate="true" @submit.prevent="submit">
    <b-field grouped group-multiline class="columns">
      <b-field label="Pays" class="column">
        <b-select v-model="fields.country.value" placeholder="Selectionner un pays" expanded>
          <option :value="null">Selectionner un pays</option>
          <option v-for="country in countries" :key="country.code" :value="country.code">
            {{ country.name }}
          </option>
        </b-select>
      </b-field>
      <b-field label="Genre" class="column">
        <b-select v-model="fields.gender.value" placeholder="Selectionner le genre" expanded>
          <option :value="null">Selectionner le genre</option>
          <option v-for="gender in ['male', 'female', 'mixed']" :key="gender" :value="gender">
            {{ vehicleGender(gender) }}
          </option>
        </b-select>
      </b-field>
    </b-field>
    <b-field
      label="Catégorie"
      :message="fields.liveCategoryId.error"
      :type="fields.liveCategoryId.error !== null ? 'is-danger' : ''"
    >
      <b-select
        v-model="fields.liveCategoryId.value"
        placeholder="Selectionner une catégorie"
        expanded
      >
        <option
          v-for="liveCategory in liveCategories"
          :key="liveCategory.id"
          :value="liveCategory.id"
        >
          {{ liveCategory.name }}
        </option>
      </b-select>
    </b-field>
    <b-field
      label="Numéro"
      :message="fields.number.error"
      :type="fields.number.error !== null ? 'is-danger' : ''"
    >
      <b-input v-model="fields.number.value" type="text" />
    </b-field>
    <b-field
      label="Nom"
      :message="fields.name.error"
      :type="fields.name.error !== null ? 'is-danger' : ''"
    >
      <b-input v-model="fields.name.value" type="text" />
    </b-field>
    <b-field label="Commentaire">
      <b-input v-model="fields.comment.value" type="text" />
    </b-field>
    <b-field
      label="Type d'accès"
      :message="fields.accessType.error"
      :type="fields.accessType.error !== null ? 'is-danger' : ''"
    >
      <b-field>
        <b-radio-button v-model="fields.accessType.value" native-value="public">
          <span>Public</span>
        </b-radio-button>
        <b-radio-button v-model="fields.accessType.value" native-value="private">
          <span>Privé</span>
        </b-radio-button>
      </b-field>
    </b-field>
    <b-field
      v-for="trackerField in [spotTrackerField, queclinkTrackerField]"
      :key="trackerField.name"
      :label="trackerField.name"
      :message="trackerField.field.error"
      :type="trackerField.field.error !== null ? 'is-danger' : ''"
    >
      <b-field v-if="trackerField.field.value" :disabled="isLiveFinished">
        <div class="card">
          <div class="card-content">
            <div class="content">
              {{ trackerField.name }} #{{ trackerField.field.value.number }}
              <br />
              <small>{{ trackerField.getLabel(trackerField.field.value) }}</small>
            </div>
          </div>
          <footer class="card-footer">
            <b-button
              class="card-footer-item"
              label="Modifier"
              type="is-ghost"
              :disabled="isLiveFinished"
              @click="trackerField.field.value = null"
            />
          </footer>
        </div>
      </b-field>
      <b-autocomplete
        v-if="trackerField.field.value === null"
        :data="trackerField.tracker.data"
        :placeholder="trackerField.placeholder"
        :loading="trackerField.tracker.loading"
        field="number"
        :disabled="isLiveFinished"
        @typing="trackerField.emit"
        @select="(option) => (trackerField.field.value = option)"
      >
        <template v-slot="props">
          <div class="media">
            <div class="media-content">
              {{ trackerField.name }} #{{ props.option.number }}
              <br />
              <small>{{ trackerField.getLabel(props.option) }}</small>
            </div>
          </div>
        </template>
      </b-autocomplete>
    </b-field>
    <hr />
    <b-field>
      <div class="buttons">
        <b-button native-type="submit" type="is-primary" :loading="loading"> Modifier </b-button>
        <b-button
          type="is-danger"
          tag="router-link"
          :to="{ name: 'live.edit.vehicle.list', params: { id: live.id } }"
        >
          Annuler
        </b-button>
      </div>
    </b-field>
  </form>
</template>

<script>
import { STATUS_FINISHED } from '@constants/live/status'
import countries from '@data/countries'

export default {
  name: 'LiveEditVehicleForm',

  props: {
    live: {
      type: Object,
      required: true,
      validator: (v) => ['id', 'status'].every((key) => key in v),
    },
    liveVehicle: {
      type: Object,
      default: null,
      validator: (v) =>
        [
          'name',
          'number',
          'gender',
          'country',
          'comment',
          'accessType',
          'liveCategoryId',
          'spotTrackerId',
          'queclinkTrackerId',
        ].every((key) => key in v),
    },
    loading: {
      type: Boolean,
      required: true,
    },
    liveCategories: {
      type: Array,
      required: true,
    },
    spotTrackers: {
      type: Object,
      required: true,
      validator: (v) => ['data', 'loading'].every((key) => key in v),
    },
    queclinkTrackers: {
      type: Object,
      required: true,
      validator: (v) => ['data', 'loading'].every((key) => key in v),
    },
  },

  data() {
    return {
      fields: {
        liveCategoryId: {
          value: '',
          error: null,
        },
        number: {
          value: '',
          error: null,
        },
        name: {
          value: '',
          error: null,
        },
        country: {
          value: null,
        },
        accessType: {
          value: '',
          error: null,
        },
        spotTracker: {
          value: null,
          error: null,
        },
        queclinkTracker: {
          value: null,
          error: null,
        },
        gender: {
          value: null,
        },
        comment: {
          value: null,
        },
      },
    }
  },

  watch: {
    liveVehicle: {
      handler: 'loadLiveVehicle',
    },
  },

  created() {
    this.countries = countries
  },

  computed: {
    isLiveFinished() {
      return this.live.status === STATUS_FINISHED
    },

    spotTrackerField() {
      return {
        name: 'Balise SPOT',
        placeholder: 'Numéro ou ESN',
        tracker: this.spotTrackers,
        field: this.fields.spotTracker,
        getLabel: (tracker) => `ESN : ${tracker.serialNumber}`,
        emit: (input) => this.$emit('fetchSpotTrackers', input),
      }
    },

    queclinkTrackerField() {
      return {
        name: 'Balise QUECLINK',
        placeholder: 'Numéro ou IMEI',
        tracker: this.queclinkTrackers,
        field: this.fields.queclinkTracker,
        getLabel: (tracker) => `IMEI : ${tracker.imei}`,
        emit: (input) => this.$emit('fetchQueclinkTrackers', input),
      }
    },
  },

  methods: {
    async loadLiveVehicle() {
      this.fields.number.value = this.liveVehicle.number
      this.fields.liveCategoryId.value = this.liveVehicle.liveCategoryId
      this.fields.name.value = this.liveVehicle.name
      this.fields.country.value = this.liveVehicle.country
      this.fields.accessType.value = this.liveVehicle.accessType
      this.fields.gender.value = this.liveVehicle.gender
      this.fields.comment.value = this.liveVehicle.comment

      if (this.liveVehicle?.spotTrackerId) {
        this.fields.spotTracker.value = await this.$services.spotTrackerService.getById(
          this.liveVehicle.spotTrackerId,
        )
      }

      if (this.liveVehicle?.queclinkTrackerId) {
        this.fields.queclinkTracker.value = await this.$services.queclinkTrackerService.getById(
          this.liveVehicle.queclinkTrackerId,
        )
      }
    },

    submit() {
      this.$emit('submit', {
        liveCategoryId: this.fields.liveCategoryId.value,
        spotTrackerId: (this.fields.spotTracker.value && this.fields.spotTracker.value.id) || null,
        queclinkTrackerId:
          (this.fields.queclinkTracker.value && this.fields.queclinkTracker.value.id) || null,
        number: this.fields.number.value,
        name: this.fields.name.value,
        country: this.fields.country.value,
        accessType: this.fields.accessType.value,
        gender: this.fields.gender.value,
        comment: this.fields.comment.value,
      })
    },

    vehicleGender(gender) {
      const vehicleGenders = {
        male: 'Homme',
        female: 'Femme',
        mixed: 'Mixte',
      }

      return vehicleGenders[gender] ? vehicleGenders[gender] : null
    },
  },
}
</script>
