<template>
  <div class="live-tracker-availability container is-full">
    <b-tabs v-model="activeTab" :animated="false">
      <b-tab-item
        v-if="liveSpotTrackerAvailabilities.length > 0"
        label="Balises SPOT"
        :value="spotTrackerType"
      >
        <live-tracker-availability-checklist
          :loading="loading"
          :trackers="spotTrackers"
          :vehicleNumbers="vehicleNumbers"
          :trackerAvailabilities="spotTrackerAvailabilities"
          @loading="loading = $event"
          @updateTrackers="updateLiveSpotTrackers"
          @updateTrackerAvailabilities="updateLiveSpotTrackerAvailabilities"
        />
      </b-tab-item>
      <b-tab-item
        v-if="liveQueclinkTrackerAvailabilities.length > 0"
        label="Balises QUECLINK"
        :value="queclinkTrackerType"
      >
        <live-tracker-availability-checklist
          :loading="loading"
          :trackers="queclinkTrackers"
          :vehicleNumbers="vehicleNumbers"
          :trackerAvailabilities="queclinkTrackerAvailabilities"
          @loading="loading = $event"
          @updateTrackers="updateLiveQueclinkTrackers"
          @updateTrackerAvailabilities="updateLiveQueclinkTrackerAvailabilities"
        />
      </b-tab-item>
      <live-tracker-availability-table
        v-if="
          liveQueclinkTrackerAvailabilities.length === 0 &&
          liveSpotTrackerAvailabilities.length === 0
        "
        :loading="loading"
        :trackers="[]"
        :checked-trackers="[]"
        class="column is-full"
      />
    </b-tabs>
    <b-field v-if="enabledSubmitButton" class="column is-full-mobile">
      <b-button type="is-success" :loading="loading" @click="submit">
        Confirmer la réception
      </b-button>
    </b-field>
  </div>
</template>

<script>
import LiveTrackerAvailabilityTable from './LiveTrackerAvailabilityTable.vue'
import LiveTrackerAvailabilityChecklist from './LiveTrackerAvailabilityChecklist.vue'
import { AVAILABILITY_UNAVAILABLE } from '@constants/tracker/availability.js'
import { QUECLINK_TRACKER_TYPE, SPOT_TRACKER_TYPE } from '@constants/tracker/type'

export default {
  name: 'LiveTrackerAvailability',

  components: {
    LiveTrackerAvailabilityTable,
    LiveTrackerAvailabilityChecklist,
  },

  props: {
    live: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      activeTab: SPOT_TRACKER_TYPE,
      loading: false,
      liveVehicles: [],
      spotTrackers: [],
      queclinkTrackers: [],
      liveSpotTrackerAvailabilities: [],
      liveQueclinkTrackerAvailabilities: [],
    }
  },

  created() {
    this.spotTrackerType = SPOT_TRACKER_TYPE
    this.queclinkTrackerType = QUECLINK_TRACKER_TYPE
  },

  async mounted() {
    await this.load()
  },

  watch: {
    liveSpotTrackerAvailabilities(liveSpotTrackerAvailabilities) {
      if (liveSpotTrackerAvailabilities.length > 0) {
        this.activeTab = SPOT_TRACKER_TYPE
      }
    },

    liveQueclinkTrackerAvailabilities(liveQueclinkTrackerAvailabilities) {
      if (
        liveQueclinkTrackerAvailabilities.length > 0 &&
        this.liveSpotTrackerAvailabilities.length === 0
      ) {
        this.activeTab = QUECLINK_TRACKER_TYPE
      }
    },
  },

  computed: {
    vehicleNumbers() {
      return this.liveVehicles.reduce(
        (vehicles, vehicle) => ({
          ...vehicles,
          ...(vehicle.spotTrackerId ? { [vehicle.spotTrackerId]: vehicle.number } : {}),
          ...(vehicle.queclinkTrackerId ? { [vehicle.queclinkTrackerId]: vehicle.number } : {}),
        }),
        {},
      )
    },

    spotTrackerAvailabilities() {
      return this.liveSpotTrackerAvailabilities.map((trackerAvailability) => ({
        ...trackerAvailability,
        trackerId: trackerAvailability.spotTrackerId,
      }))
    },

    queclinkTrackerAvailabilities() {
      return this.liveQueclinkTrackerAvailabilities.map((trackerAvailability) => ({
        ...trackerAvailability,
        trackerId: trackerAvailability.queclinkTrackerId,
      }))
    },

    liveTrackerAvailabilitiesNotAvailable() {
      return [
        ...this.liveSpotTrackerAvailabilities,
        ...this.liveQueclinkTrackerAvailabilities,
      ].filter(
        (liveTrackerAvailability) =>
          liveTrackerAvailability.availability === AVAILABILITY_UNAVAILABLE,
      )
    },

    enabledSubmitButton() {
      return (
        this.liveTrackerAvailabilitiesNotAvailable.length === 0 &&
        (this.liveSpotTrackerAvailabilities.length > 0 ||
          this.liveQueclinkTrackerAvailabilities.length > 0)
      )
    },
  },

  methods: {
    async load() {
      this.loading = true

      const [
        spotTrackers,
        queclinkTrackers,
        liveVehicles,
        liveSpotTrackerAvailabilities,
        liveQueclinkTrackerAvailabilities,
      ] = await Promise.all([
        await this.fetchSpotTrackers(),
        await this.fetchQueclinkTrackers(),
        await this.fetchLiveVehicles(this.live.id),
        await this.fetchLiveSpotTrackerAvailabilities(this.live.id),
        await this.fetchLiveQueclinkTrackerAvailabilities(this.live.id),
      ])

      this.spotTrackers = spotTrackers
      this.queclinkTrackers = queclinkTrackers
      this.liveVehicles = liveVehicles
      this.liveSpotTrackerAvailabilities = liveSpotTrackerAvailabilities
      this.liveQueclinkTrackerAvailabilities = liveQueclinkTrackerAvailabilities

      this.loading = false
    },

    async fetchLiveSpotTrackerAvailabilities() {
      return await this.$services.liveSpotTrackerAvailabilityService.getAll(this.live.id)
    },

    async fetchLiveQueclinkTrackerAvailabilities() {
      return await this.$services.liveQueclinkTrackerAvailabilityService.getAll(this.live.id)
    },

    async fetchLiveVehicles(liveId) {
      return await this.$services.liveVehicleService.getAll(liveId)
    },

    async fetchSpotTrackers() {
      return await this.$services.spotTrackerService.getAll(this.live.id)
    },

    async fetchQueclinkTrackers() {
      return await this.$services.queclinkTrackerService.getAll(this.live.id)
    },

    async updateLiveSpotTrackers({ resolve, reject, trackers }) {
      try {
        const updatedSpotTrackers = await this.$services.spotTrackerService.updateAll({
          spotTrackers: trackers,
        })
        this.spotTrackers = this.updateList(this.spotTrackers, updatedSpotTrackers)

        resolve()
      } catch (err) {
        reject(err)
      }
    },

    async updateLiveQueclinkTrackers({ resolve, reject, trackers }) {
      try {
        const updatedQueclinkTrackers = await this.$services.queclinkTrackerService.updateAll({
          queclinkTrackers: trackers,
        })
        this.queclinkTrackers = this.updateList(this.queclinkTrackers, updatedQueclinkTrackers)

        resolve()
      } catch (err) {
        reject(err)
      }
    },

    async updateLiveSpotTrackerAvailabilities({ resolve, reject, trackerAvailabilities }) {
      try {
        const updatedLiveSpotTrackerAvailabilities =
          await this.$services.liveSpotTrackerAvailabilityService.updateAll({
            liveId: this.live.id,
            liveSpotTrackerAvailabilities: trackerAvailabilities,
          })

        this.liveSpotTrackerAvailabilities = this.updateList(
          this.liveSpotTrackerAvailabilities,
          updatedLiveSpotTrackerAvailabilities,
          'id',
        )

        resolve()
      } catch (err) {
        reject(err)
      }
    },

    async updateLiveQueclinkTrackerAvailabilities({ resolve, reject, trackerAvailabilities }) {
      try {
        const updatedLiveQueclinkTrackerAvailabilities =
          await this.$services.liveQueclinkTrackerAvailabilityService.updateAll({
            liveId: this.live.id,
            liveQueclinkTrackerAvailabilities: trackerAvailabilities,
          })

        this.liveQueclinkTrackerAvailabilities = this.updateList(
          this.liveQueclinkTrackerAvailabilities,
          updatedLiveQueclinkTrackerAvailabilities,
          'id',
        )

        resolve()
      } catch (err) {
        reject(err)
      }
    },

    updateList(list, updatedElements) {
      return [
        ...updatedElements,
        ...list.filter((item) => {
          const ids = new Set(updatedElements.map((e) => e.id))
          return !ids.has(item.id)
        }),
      ]
    },

    submit() {
      if (this.enabledSubmitButton) {
        this.$emit('submit')
      }
    },
  },
}
</script>

<style lang="scss">
@import 'variables';

.live-tracker-availability {
  .tab-content {
    background-color: $color-secondary-lightest;
    border-radius: 0 0 $border-radius-s $border-radius-s;
  }
}
</style>
