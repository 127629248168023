<template>
  <div>
    <h2 class="title is-6">Liens externes</h2>
    <live-add-external-link-form
      :liveId="live.id"
      :position="externalLinks.length + 1"
      @submit="createExternalLink"
    />
    <hr />
    <live-external-link-table
      :liveExternalLinks="externalLinks"
      @drop="updateExternalLinkIndexInArray"
      @submit="updateExternalLink"
      @delete="deleteExternalLink"
    />
  </div>
</template>

<script>
import LiveExternalLinkTable from './LiveExternalLinkTable.vue'
import LiveAddExternalLinkForm from './LiveAddExternalLinkForm.vue'

export default {
  name: 'EditLiveExternalLinkTab',

  components: {
    LiveExternalLinkTable,
    LiveAddExternalLinkForm,
  },

  props: {
    live: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      liveExternalLinks: [],
    }
  },

  async mounted() {
    this.liveExternalLinks = await this.$services.liveExternalLinkService.getAll(this.live.id)
  },

  computed: {
    externalLinks() {
      return [...this.liveExternalLinks].sort((current, next) => {
        const a = current.position
        const b = next.position

        if (a < b) return -1
        if (a > b) return 1

        return 0
      })
    },
  },

  methods: {
    async createExternalLink(createdExternalLink) {
      this.liveExternalLinks.splice(createdExternalLink.position - 1, 0, createdExternalLink)

      await this.updateExternalLinkPositions()
    },

    async updateExternalLink(updatedExternalLink) {
      this.liveExternalLinks = this.liveExternalLinks.map((liveExternalLink) =>
        liveExternalLink.id === updatedExternalLink.id ? updatedExternalLink : liveExternalLink,
      )
    },

    async deleteExternalLink(deletedExternalLink) {
      this.liveExternalLinks = this.liveExternalLinks.filter(
        (liveExternalLink) => liveExternalLink.id !== deletedExternalLink.id,
      )

      await this.updateExternalLinkPositions()
    },

    updateExternalLinkIndexInArray(currentIndex, targetIndex) {
      this.liveExternalLinks.splice(
        currentIndex,
        0,
        ...this.liveExternalLinks.splice(targetIndex, 1),
      )

      this.updateExternalLinkPositions()
    },

    async updateExternalLinkPositions() {
      for (let i = 0; i < this.liveExternalLinks.length; i++) {
        if (this.liveExternalLinks[i].position !== i) {
          this.liveExternalLinks[i].position = i

          await this.$services.liveExternalLinkService.update({
            ...this.liveExternalLinks[i],
            position: i,
          })
        }
      }
    },
  },
}
</script>
