<template>
  <live-tutorial-form :loading="loading" :live-tutorial="liveTutorial" @submit="submit" />
</template>

<script>
import { mapActions } from 'vuex'
import LiveTutorialForm from '@views/LiveTutorial/LiveTutorialForm.vue'

export default {
  name: 'EditLiveTutorial',

  components: {
    LiveTutorialForm,
  },

  props: {
    liveTutorial: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      loading: false,
    }
  },

  methods: {
    ...mapActions('ui', ['addToastMessage']),

    async submit(liveTutorial) {
      this.loading = true

      try {
        const updatedLiveTutorial = await this.$services.liveTutorialService.update(liveTutorial)

        this.$emit('update', updatedLiveTutorial)

        this.addToastMessage({
          text: `Le tutoriel a été modifié.`,
          type: 'is-success',
        })
      } catch (err) {
        this.addToastMessage({
          text: 'Une erreur interne est survenue.',
          type: 'is-danger',
        })
        console.error(err)
      }

      this.loading = false
    },
  },
}
</script>
