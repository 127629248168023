const STEP_INFO = { type: 'is-info', icon: 'history' }
const STEP_DANGER = { type: 'is-danger', icon: 'times' }
const STEP_SUCCESS = { type: 'is-success', icon: 'check' }

export const formatLiveSetupStep = (
  label,
  prop,
  errorMessages,
  formatErrorMessage,
  formatSuccessMessage,
) => {
  let isSuccess
  let isClickable
  const hasError = !!errorMessages

  if (Array.isArray(prop)) {
    isSuccess = prop.length > 0 && !errorMessages
    isClickable = !!(prop.length > 0 || errorMessages)
  } else {
    isSuccess = prop && !errorMessages
    isClickable = !!(prop || errorMessages)
  }

  const { type, icon } = isSuccess ? STEP_SUCCESS : hasError ? STEP_DANGER : STEP_INFO

  return {
    type,
    icon,
    label,
    prop,
    isClickable,
    errorMessages,
    formatErrorMessage,
    formatSuccessMessage,
    showErrorMessage: hasError,
    showSuccessMessage: !!prop,
  }
}
