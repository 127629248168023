<template>
  <div class="columns is-multiline">
    <b-field label="Actions" class="column is-12">
      <b-select v-model="fields.action" placeholder="Sélectionner la balise" expanded>
        <option
          v-for="availability in availabilities"
          :key="availability.availability"
          :value="availability.availability"
        >
          {{ availability.label }}
        </option>
      </b-select>
    </b-field>
    <b-field v-if="showSelectOwner" label="Propriétaire" class="column is-12">
      <b-select v-model="fields.owner" label="Choisir un propriétaire" icon="user" expanded>
        <option v-for="owner in owners" :key="owner" :value="owner">
          {{ capitalize(owner) }}
        </option>
      </b-select>
    </b-field>
    <b-field class="column is-12">
      <b-button type="is-primary" :loading="loading" :disabled="loading" @click="submit">
        Modifier la sélection
      </b-button>
    </b-field>
  </div>
</template>

<script>
import {
  AVAILABILITY_LOST,
  AVAILABILITY_BROKEN,
  AVAILABILITY_AVAILABLE,
  AVAILABILITY_UNAVAILABLE,
} from '@constants/tracker/availability.js'
import { OWNERS } from '@constants/tracker/owner.js'
import { capitalize } from '@helpers/capitalize.js'

export default {
  name: 'LiveTrackerAvailabilityForm',

  props: {
    loading: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      fields: {
        action: AVAILABILITY_AVAILABLE,
        owner: OWNERS[0],
      },
    }
  },

  created() {
    this.owners = OWNERS
    this.availabilities = [
      { availability: AVAILABILITY_LOST, label: 'Déclarer perdu' },
      { availability: AVAILABILITY_BROKEN, label: 'Déclarer cassé' },
      { availability: AVAILABILITY_AVAILABLE, label: 'Déclarer rendu' },
      { availability: AVAILABILITY_UNAVAILABLE, label: 'Déclarer non rendu' },
    ]
  },

  computed: {
    showSelectOwner() {
      return this.fields.action === AVAILABILITY_AVAILABLE
    },
  },

  methods: {
    submit() {
      this.$emit('submit', {
        availability: this.fields.action,
        owner: this.fields.owner,
      })
    },

    capitalize(value) {
      return capitalize(value)
    },
  },
}
</script>
