<template>
  <div style="display: none"></div>
</template>

<script>
export default {
  name: 'OMapZone',

  inject: ['onMap'],

  props: {
    name: {
      type: String,
      default: '',
    },

    path: {
      type: Array,
      default: () => [],
    },

    color: {
      type: String,
      default: 'blue',
    },

    opacity: {
      type: Number,
      default: 0.5,
    },

    radius: {
      type: Number,
      default: 500,
    },
  },

  async mounted() {
    this.$zone = this.createPolygon()

    this.createEventListener()
    this.bindTooltip()

    const map = await this.onMap
    map.addLayer(this.$zone)
    map.on('zoomend', this.onMapZoom)
  },

  watch: {
    path(path) {
      this.$zone.setLatLngs(path)
    },

    name(name) {
      this.$zone.setTooltipContent(name)
    },

    color(color) {
      this.$zone.setStyle({ color: color })
    },

    opacity(opacity) {
      this.$zone.setStyle({ opacity: opacity })
    },

    radius: {
      async handler(radius) {
        const map = await this.onMap
        this.updateWeight(map, radius)
      },
      immediate: true,
    },
  },

  methods: {
    createPolygon() {
      const { Polyline } = require('leaflet')

      return new Polyline(this.path, {
        color: this.color,
        opacity: this.opacity,
      })
    },

    computeWeight(map, radius) {
      return (radius * 2) / this.getMetersPerPixel(map)
    },

    getMetersPerPixel(map) {
      const { point } = require('leaflet')

      const centerContainerPoint = map.latLngToContainerPoint(map.getCenter())
      const temporaryContainerPoint = point(centerContainerPoint.x + 1, centerContainerPoint.y)

      const centerLatLng = map.containerPointToLatLng(centerContainerPoint)
      const temporaryLatLng = map.containerPointToLatLng(temporaryContainerPoint)

      return temporaryLatLng.distanceTo(centerLatLng)
    },

    onMapZoom(event) {
      this.updateWeight(event.sourceTarget)
    },

    updateWeight(map, radius = this.radius) {
      this.$zone.setStyle({ weight: this.computeWeight(map, radius) })
    },

    bindTooltip() {
      this.$zone.bindTooltip(this.name, {
        direction: 'top',
        sticky: true,
      })
    },

    createEventListener() {
      const { DomEvent } = require('leaflet')

      this.$zone.on('click', (e) => {
        DomEvent.stopPropagation(e)
        this.$emit('click', e)
      })
    },
  },

  async beforeDestroy() {
    const map = await this.onMap
    map.removeLayer(this.$zone)
    map.off('zoomEnd', this.onMapZoom)
  },
}
</script>
