<template>
  <b-field label="Niveau de batterie sur la période">
    <OChart :options="chartOptions" />
  </b-field>
</template>

<script>
import OChart from '@components/Chart.vue'
import { SPOT_TRACKER_TYPE } from '@constants/tracker/type'

const DEFAULT_CHART_OPTIONS = {
  animation: false,
  spanGaps: true,
  scales: {
    x: {
      type: 'time',
      time: {
        displayFormats: {
          millisecond: 'LLL d',
          second: 'LLL d',
          minute: 'LLL d',
          hour: 'LLL d',
          day: 'LLL d',
          week: 'LLL d',
          month: 'LLL d',
          quarter: 'LLL d',
          year: 'LLL d',
        },
        tooltipFormat: 'LLL d, HH:mm',
      },
    },
    y: {
      type: 'linear',
      suggestedMin: 0,
      suggestedMax: 100,
    },
  },
  plugins: {
    legend: {
      position: 'bottom',
    },
    tooltip: {
      callbacks: {
        label: function (context) {
          return ` ${context.dataset.label}: ${context.parsed.y} %`
        },
      },
    },
  },
}

export default {
  name: 'EditLiveReviewTrackerChart',

  components: { OChart },

  props: {
    trackers: {
      type: Array,
      required: true,
      validator: (v) =>
        v.every((obj) => ['id', 'name', 'color', 'locations'].every((key) => key in obj)),
    },
  },

  computed: {
    chartOptions() {
      return {
        type: 'line',
        options: DEFAULT_CHART_OPTIONS,
        data: {
          datasets: this.trackers.map((tracker) => {
            if (tracker.type === SPOT_TRACKER_TYPE) {
              return {
                label: tracker.name,
                borderColor: tracker.color,
                backgroundColor: tracker.color,
                pointRadius: 4,
                pointStyle: 'circle',
                data: tracker.locations.map((location) => ({
                  x: location.locatedAt.getTime(),
                  y: location.batteryState === 'LOW' ? 20 : 100,
                })),
              }
            }

            return {
              label: tracker.name,
              borderColor: tracker.color,
              backgroundColor: tracker.color,
              pointRadius: 4,
              pointStyle: 'circle',
              data: tracker.locations.map((location) => ({
                x: location.locatedAt.getTime(),
                y: location.batteryPercentage,
              })),
            }
          }),
        },
      }
    },
  },
}
</script>
