<template>
  <o-card title="Checklist">
    <template v-slot:content>
      <b-progress
        :type="progress.type"
        :value="progress.percentage"
        show-value
        :precision="0"
        format="percent"
      />
      <div
        v-for="item in liveChecklistItems"
        :key="item.id"
        class="columns is-multiline is-vcentered my-3"
      >
        <slot name="checkbox" v-bind:item="item">
          <b-checkbox
            :value="item.done"
            type="is-success"
            :disabled="loading"
            class="column is-full-mobile py-1"
            @click.native="$emit('check', item)"
          >
            {{ item.name }}
          </b-checkbox>
        </slot>
        <span v-show="item.administratorId" class="tag column is-narrow ml-3">
          {{ item.administratorName }} le {{ dateFormat(item.updatedAt) }}
        </span>
        <slot name="content" v-bind:item="item" />
      </div>
    </template>
    <template v-slot:footer>
      <b-button
        :loading="loading"
        :disabled="!areAllChecked"
        type="is-primary"
        :label="submitButton.label"
        :icon-right="submitButton.icon"
        @click="submit"
      />
    </template>
  </o-card>
</template>

<script>
import { STATUS_COMPLETED, STATUS_FINISHED, STATUS_PENDING } from '@constants/live/status.js'
import { nextStatus } from './index.js'
import { format } from 'date-fns'
import OCard from '@components/Card.vue'

export default {
  name: 'LiveChecklistItems',

  components: {
    OCard,
  },

  props: {
    loading: {
      type: Boolean,
      required: true,
    },
    liveStatus: {
      type: String,
      required: true,
      validator: (v) => [STATUS_PENDING, STATUS_COMPLETED, STATUS_FINISHED].indexOf(v) !== -1,
    },
    liveChecklistItems: {
      type: Array,
      required: true,
      validator: (v) =>
        v.every((obj) =>
          ['id', 'done', 'name', 'administratorId', 'administratorName'].every((key) => key in obj),
        ),
    },
  },

  computed: {
    areAllChecked() {
      return (
        this.liveChecklistItems.length > 0 && !this.liveChecklistItems.find((item) => !item.done)
      )
    },

    progress() {
      const totalChecked = this.liveChecklistItems.filter((item) => item.done).length

      return this.areAllChecked
        ? { percentage: 100, type: 'is-success' }
        : {
            percentage: (totalChecked / this.liveChecklistItems.length) * 100,
            type: 'is-primary',
          }
    },

    submitButton() {
      return this.liveStatus === STATUS_PENDING
        ? { label: "Passer à l'étape suivante", icon: 'arrow-right' }
        : { label: 'Terminer le live', icon: 'check' }
    },
  },

  methods: {
    submit() {
      if (this.areAllChecked) {
        this.$emit('submit', nextStatus[this.liveStatus] ?? null)
      }
    },

    dateFormat(date) {
      return format(date, 'dd/MM/yyyy')
    },
  },
}
</script>
