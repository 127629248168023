<template>
  <o-upload
    v-model="gpxFile"
    :loading="loading"
    :type="fileType"
    :label="uploadTextContent"
    @submit="submit"
    @deleteFile="deleteGpxFile"
  />
</template>

<script>
import OUpload from '@components/Upload.vue'

const FILE_TYPE = 'application/gpx+xml'

export default {
  name: 'LiveStageRouteUpload',

  components: {
    OUpload,
  },

  props: {
    loading: {
      type: Boolean,
      required: true,
    },
    stageRoutes: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      gpxFile: null,
    }
  },

  created() {
    this.fileType = FILE_TYPE
  },

  computed: {
    uploadTextContent() {
      return this.stageRoutes.length === 0 ? 'Importer un GPX' : 'Remplacer le GPX'
    },
  },

  methods: {
    submit() {
      this.$emit('submit', this.gpxFile)
      this.deleteGpxFile()
    },

    deleteGpxFile() {
      this.gpxFile = null
    },
  },
}
</script>
