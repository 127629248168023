<template>
  <b-field :label="label" :message="error" :type="error !== null ? 'is-danger' : ''">
    <b-input :value="location" type="text" lazy @input="inputLocation" />
  </b-field>
</template>

<script>
import { dmsToLatLng } from '@filters/dmsToLatLng'
import { ddmToLatLng } from '@filters/ddmToLatLng'
import { FORMAT_DD, FORMAT_DDM, FORMAT_DMS, formatLatLon } from '@filters/coordinate'

export default {
  name: 'OInputLocation',

  props: {
    value: {
      type: Array,
      required: true,
    },
    coordinateFormat: {
      type: String,
      required: true,
      validator: (v) => [FORMAT_DD, FORMAT_DDM, FORMAT_DMS].indexOf(v) !== -1,
    },
    label: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      error: null,
    }
  },

  watch: {
    coordinateFormat: {
      handler() {
        this.inputLocation(this.location)
      },
      immediate: true,
    },
  },

  computed: {
    location() {
      if (this.coordinateFormat === FORMAT_DD) {
        return this.value.join(', ')
      }

      return formatLatLon(this.value, this.coordinateFormat).join(', ')
    },
  },

  methods: {
    inputLocation(input) {
      const location = input.trim()
      let formattedLocation = null

      if (this.coordinateFormat === FORMAT_DDM) {
        formattedLocation = ddmToLatLng(location)
      }

      if (this.coordinateFormat === FORMAT_DMS) {
        formattedLocation = dmsToLatLng(location)
      }

      if (this.coordinateFormat === FORMAT_DD) {
        const latLng = location.split(', ')
        const isInValid = latLng
          .map((coord) => coord.split(' '))
          .find((splitedCoord) => splitedCoord.length > 1)
        const hasNaN = latLng.find((number) => isNaN(+number))

        formattedLocation =
          isInValid || hasNaN || latLng.length !== 2 ? null : latLng.map((number) => +number)
      }

      this.error = !formattedLocation ? 'Mauvais format' : null

      this.$emit('input', { latLng: formattedLocation, hasError: !!this.error })
    },
  },
}
</script>
