<template>
  <page-layout :title="title">
    <template v-slot:breadcrumb>
      <li><router-link :to="{ name: 'home' }">Dashboard</router-link></li>
      <li class="is-active"><a href="#">Lives</a></li>
    </template>
    <div class="level">
      <div class="level-left">
        <p class="level-item">
          <b-button tag="router-link" type="is-success" :to="{ name: 'live.add' }">
            <b-icon icon="plus" size="is-small" />
            <span>Nouveau</span>
          </b-button>
        </p>
      </div>
      <div class="level-right">
        <p class="level-item">
          <b-button tag="router-link" type="is-success" :to="{ name: 'live.setup' }">
            <b-icon icon="plus" size="is-small" />
            <span>Importer depuis Jotform</span>
          </b-button>
        </p>
      </div>
    </div>
    <list-lives-filter />
    <list-lives-table :lives="liveList" :loading="loading" @delete="deleteLive" />
  </page-layout>
</template>

<script>
import { mapActions } from 'vuex'
import { stringNormalizer } from '@helpers/stringNormalizer'
import PageLayout from '@components/PageLayout.vue'
import ListLivesTable from '@views/pages/ListLivesPage/ListLivesTable.vue'
import ListLivesFilter from '@views/pages/ListLivesPage/ListLivesFilter.vue'

export default {
  name: 'ListLivesPage',

  components: {
    ListLivesFilter,
    ListLivesTable,
    [PageLayout.name]: PageLayout,
  },

  data() {
    return {
      title: 'Liste des lives',
      loading: true,
      lives: [],
    }
  },

  async mounted() {
    await this.fetch()
  },

  computed: {
    liveList() {
      return this.lives.filter((live) => {
        let condition = true

        if (this.$route.query?.keyword) {
          const term = stringNormalizer(this.$route.query.keyword.toLowerCase())
          const name = stringNormalizer(live.name.toLowerCase())

          condition = condition && name.indexOf(term) >= 0
        }

        if (this.$route.query?.status) {
          condition = condition && this.$route.query?.status === live.status
        }

        if (this.$route.query?.visitorAccessType) {
          condition = condition && this.$route.query?.visitorAccessType === live.visitorAccessType
        }

        return condition
      })
    },
  },

  methods: {
    ...mapActions('ui', ['addToastMessage']),

    async fetch() {
      this.loading = true

      try {
        this.lives = await this.$services.liveService.getAll()
      } catch (err) {
        this.addToastMessage({
          text: err.message ? err.message : 'Une erreur interne est survenue.',
          type: 'is-danger',
        })
        console.error(err)
      }

      this.loading = false
    },

    async deleteLive(live) {
      this.loading = true

      try {
        const liveDeleted = await this.$services.liveService.delete(live.id)
        this.lives = this.lives.filter((live) => live.id !== liveDeleted.id)

        this.addToastMessage({
          text: `Le live "${liveDeleted.name}" a été supprimé.`,
          type: 'is-success',
        })
      } catch (err) {
        this.addToastMessage({
          text: err.message ? err.message : 'Une erreur interne est survenue.',
          type: 'is-danger',
        })
      }

      this.loading = false
    },
  },
}
</script>
