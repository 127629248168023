<template>
  <div>
    <h2 class="title is-6">Télécharger les documents</h2>
    <b-field label="Choix du contact">
      <b-select v-model="contact" icon="user">
        <option v-for="contact in contacts" :key="contact.name" :value="contact">
          {{ contact.name }}
        </option>
      </b-select>
    </b-field>

    <div class="columns is-multiline">
      <o-table :loading="loading" :items="documents" :draggable="false" class="column is-full">
        <b-table-column v-slot="{ row }" field="label" label="Document">
          {{ row.label }}
        </b-table-column>
        <b-table-column v-slot="{ row }" field="type" label="Type">
          {{ row.type }}
        </b-table-column>
        <b-table-column v-slot="{ row }" width="100" field="action">
          <b-button
            type="is-primary"
            :loading="loading"
            :disabled="loading"
            icon-right="file-export"
            @click="row.action"
          />
        </b-table-column>
      </o-table>
      <div class="column is-narrow">
        <b-button
          :loading="loading"
          :disabled="loading"
          label="Télécharger tous les documents"
          type="is-primary"
          @click="downloadAllDocuments"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import OTable from '@components/Table.vue'
import { downloadFile } from '@helpers/downloadFile'

const CONTACTS = [
  { name: 'Sophie', phone: '+33(0)6 30 19 02 69', email: 'sales@owaka.com' },
  { name: 'Myriam', phone: '+33(0)7 89 25 90 85', email: 'sales@owaka.com' },
  { name: 'Laurent', phone: '+33(0)7 85 36 16 81', email: 'sales@owaka.com' },
]

export default {
  name: 'EditLiveDocumentTab.vue',

  components: {
    OTable,
  },

  props: {
    live: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      loading: false,
      contact: CONTACTS.at(0),
    }
  },

  created() {
    this.contacts = CONTACTS
    this.documents = [
      { label: 'Liste des participants', type: 'PDF', action: this.downloadVehiclesPdf },
      { label: 'Liste des cautions', type: 'PDF', action: this.downloadDepositsPdf },
      { label: "Liste des contacts d'urgence", type: 'PDF', action: this.downloadAlertContactsPdf },
    ]
  },

  methods: {
    ...mapActions('ui', ['addToastMessage']),

    async downloadVehiclesPdf() {
      this.loading = true

      try {
        const vehicles = await this.$services.liveDocumentService.getVehicles({
          liveId: this.live.id,
          ...this.contact,
        })

        downloadFile(vehicles, 'pdf', `${this.live.slug}-participants`)
      } catch (err) {
        console.error(err)

        this.addToastMessage({
          text: 'Une erreur interne est survenue.',
          type: 'is-danger',
        })
      }

      this.loading = false
    },

    async downloadDepositsPdf() {
      this.loading = true

      try {
        const deposits = await this.$services.liveDocumentService.getDeposits({
          liveId: this.live.id,
          ...this.contact,
        })

        downloadFile(deposits, 'pdf', `${this.live.slug}-deposits`)
      } catch (err) {
        console.error(err)

        this.addToastMessage({
          text: 'Une erreur interne est survenue.',
          type: 'is-danger',
        })
      }

      this.loading = false
    },

    async downloadAlertContactsPdf() {
      this.loading = true

      try {
        const alertContacts = await this.$services.liveDocumentService.getAlertContacts({
          liveId: this.live.id,
          ...this.contact,
        })

        downloadFile(alertContacts, 'pdf', `${this.live.slug}-contacts`)
      } catch (err) {
        console.error(err)

        this.addToastMessage({
          text: 'Une erreur interne est survenue.',
          type: 'is-danger',
        })
      }

      this.loading = false
    },

    async downloadAllDocuments() {
      await Promise.all(this.documents.map((document) => document.action()))
    },
  },
}
</script>
