<template>
  <form :novalidate="true" @submit.prevent="submit">
    <h2 class="title is-5">Afficher sur l'interface</h2>
    <h2 class="title is-6">Fonctionnalités</h2>
    <div class="columns is-multiline">
      <div class="column is-full-mobile">
        <b-checkbox v-model="fields.showSwitchTrackers" type="is-success">
          <b-icon icon="repeat" />
          Inversion de balises
        </b-checkbox>
      </div>
    </div>
    <h2 class="title is-6">Alertes</h2>
    <div class="columns is-multiline">
      <div v-for="(field, key) in fields.alertStatuses" :key="key" class="column is-full-mobile">
        <b-checkbox v-model="field.isVisible.value" type="is-success">
          <b-icon :icon="alertStatuses[key].icon" />
          {{ alertStatuses[key].label }}
        </b-checkbox>
      </div>
    </div>

    <hr />
    <h2 class="title is-5">Carte</h2>
    <b-field grouped group-multiline class="columns">
      <b-field
        label="Fond de carte PUBLIQUE"
        :message="fields.defaultPublicMapLayer.error"
        :type="fields.defaultPublicMapLayer.error !== null ? 'is-danger' : ''"
        class="column is-full-mobile"
      >
        <b-select
          v-model="fields.defaultPublicMapLayer.value"
          expanded
          placeholder="Choisissez un fond de carte"
          icon="map"
        >
          <option v-for="name in mapLayers" :key="name" :value="name">
            {{ name }}
          </option>
        </b-select>
      </b-field>
      <b-field
        label="Fond de carte PRIVÉE"
        :message="fields.defaultPrivateMapLayer.error"
        :type="fields.defaultPrivateMapLayer.error !== null ? 'is-danger' : ''"
        class="column is-full-mobile"
      >
        <b-select
          v-model="fields.defaultPrivateMapLayer.value"
          expanded
          placeholder="Choisissez un fond de carte"
          icon="map"
        >
          <option v-for="name in mapLayers" :key="name" :value="name">
            {{ name }}
          </option>
        </b-select>
      </b-field>
    </b-field>

    <b-field
      label="Distance de zoom carte PUBLIQUE"
      class="column is-full-mobile"
      message="Distance approximative"
    >
      <b-slider
        v-model="fields.publicMapZoom.value"
        :min="mapZooms.at(0)"
        :max="mapZooms.at(-1)"
        :step="1"
        ticks
        :tooltip="false"
        indicator
        :custom-formatter="getApproximateMapScaleInMetersByZoom"
      />
    </b-field>

    <hr />
    <h2 class="title is-5">Divers</h2>
    <b-field grouped group-multiline class="columns">
      <b-field
        label="Format de coordonnées"
        :message="fields.coordinateFormat.error"
        :type="fields.coordinateFormat.error !== null ? 'is-danger' : ''"
        class="column is-full-mobile"
      >
        <b-select
          v-model="fields.coordinateFormat.value"
          expanded
          placeholder="Choisissez un format de coordonnées"
          icon="map-marker-alt"
        >
          <option v-for="format in coordinateFormats" :key="format.value" :value="format.value">
            {{ format.name }}{{ format.example }}
          </option>
        </b-select>
      </b-field>
      <b-field
        label="Afficher la trace avant le début de l'étape"
        :message="fields.showStageGeographyBeforeStart.error"
        :type="fields.showStageGeographyBeforeStart.error !== null ? 'is-danger' : ''"
        class="column is-full-mobile"
      >
        <b-select v-model="fields.showStageGeographyBeforeStart.value" expanded icon="route">
          <option v-for="bool in [true, false]" :key="bool" :value="bool">
            {{ bool ? 'oui' : 'non' }}
          </option>
        </b-select>
      </b-field>
    </b-field>

    <hr />
    <b-field>
      <b-button native-type="submit" type="is-primary" :loading="loading"> Modifier </b-button>
    </b-field>
  </form>
</template>

<script>
import { mapActions } from 'vuex'
import { MAP_APPROXIMATE_ZOOM_SCALE, MAP_LAYERS, MAP_ZOOMS } from '@constants/settings/mapLayer'
import { COORDINATE_FORMATS } from '@constants/settings/coordinate'
import {
  OK_ALERT,
  MEDICAL_ALERT,
  TECHNICAL_ALERT,
  LOW_BATTERY_ALERT,
  OFF_TRACK_ALERT,
} from '@constants/settings/alertStatuses'

const ALERT_STATUSES = {
  [OK_ALERT]: {
    icon: 'thumbs-up',
    label: 'OK',
  },
  [MEDICAL_ALERT]: {
    icon: 'heart',
    label: 'Médicale',
  },
  [TECHNICAL_ALERT]: {
    icon: 'wrench',
    label: 'Technique',
  },
  [LOW_BATTERY_ALERT]: {
    icon: 'battery-half',
    label: 'Batterie',
  },
  [OFF_TRACK_ALERT]: {
    icon: 'road-circle-exclamation',
    label: 'Hors zone',
  },
}

export default {
  name: 'LiveSettingsForm',

  props: {
    live: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      loading: false,
      settings: null,
      fields: {
        coordinateFormat: {
          value: '',
          error: null,
        },
        defaultPublicMapLayer: {
          value: '',
          error: null,
        },
        defaultPrivateMapLayer: {
          value: '',
          error: null,
        },
        showStageGeographyBeforeStart: {
          value: '',
          error: null,
        },
        publicMapZoom: {
          value: [0, 18],
          error: null,
        },
        alertStatuses: {},
        showSwitchTrackers: false,
      },
    }
  },

  watch: {
    settings: {
      handler: 'initFields',
    },
  },

  created() {
    this.mapLayers = MAP_LAYERS
    this.mapZooms = MAP_ZOOMS
    this.alertStatuses = ALERT_STATUSES
    this.coordinateFormats = COORDINATE_FORMATS
  },

  async mounted() {
    await this.load()
  },

  methods: {
    ...mapActions('ui', ['addToastMessage']),

    async load() {
      this.loading = true

      try {
        this.settings = await this.$services.liveSettingsService.getByLiveId(this.live.id)
      } catch (err) {
        this.settings = null

        this.addToastMessage({
          text: 'Une erreur interne est survenue.',
          type: 'is-danger',
        })
        console.error(err)
      }

      this.loading = false
    },

    initFields() {
      this.fields.coordinateFormat.value = this.settings?.coordinateFormat
      this.fields.defaultPublicMapLayer.value = this.settings?.defaultPublicMapLayer
      this.fields.defaultPrivateMapLayer.value = this.settings?.defaultPrivateMapLayer
      this.fields.showStageGeographyBeforeStart.value = this.settings?.showStageGeographyBeforeStart
      this.fields.publicMapZoom.value = this.formatPublicMapZoom(this.settings)
      this.fields.alertStatuses = this.createAlertStatusFields()
      this.fields.showSwitchTrackers = this.settings.showSwitchTrackers
    },

    async submit() {
      this.loading = true

      try {
        const { publicMapMinZoom, publicMapMaxZoom } = this.formatSettingsPublicMapZoom()

        this.settings = await this.$services.liveSettingsService.update(this.settings.id, {
          coordinateFormat: this.fields.coordinateFormat.value,
          defaultPublicMapLayer: this.fields.defaultPublicMapLayer.value,
          defaultPrivateMapLayer: this.fields.defaultPrivateMapLayer.value,
          showStageGeographyBeforeStart: this.fields.showStageGeographyBeforeStart.value,
          publicMapMinZoom,
          publicMapMaxZoom,
          alertStatuses: this.getAlertStatusFieldValues(),
          showSwitchTrackers: this.fields.showSwitchTrackers,
        })

        this.addToastMessage({
          text: `Les configurations du live "${this.live.name}" ont été mis à jour.`,
          type: 'is-success',
        })
      } catch (err) {
        this.addToastMessage({
          text: 'Une erreur interne est survenue.',
          type: 'is-danger',
        })
        console.error(err)
      }

      this.loading = false
    },

    getApproximateMapScaleInMetersByZoom(zoom) {
      const meters = MAP_APPROXIMATE_ZOOM_SCALE[this.mapZooms.at(-1) - zoom]
      const kMeters = meters / 1000

      return kMeters >= 1 ? `${kMeters}km` : `${meters}m`
    },

    createAlertStatusFields() {
      return Object.keys(this.settings?.alertStatuses).reduce(
        (alerts, alert) => ({
          ...alerts,
          [alert]: Object.keys(this.settings?.alertStatuses[alert]).reduce(
            (fields, field) => ({
              ...fields,
              [field]: {
                value: this.settings?.alertStatuses[alert][field],
                error: null,
              },
            }),
            {},
          ),
        }),
        {},
      )
    },

    getAlertStatusFieldValues() {
      return Object.keys(this.fields.alertStatuses).reduce(
        (alerts, alert) => ({
          ...alerts,
          [alert]: { isVisible: this.fields.alertStatuses[alert].isVisible.value },
        }),
        {},
      )
    },

    formatPublicMapZoom({ publicMapMinZoom, publicMapMaxZoom }) {
      const maxZoom = this.mapZooms.at(-1)
      const min = maxZoom - publicMapMaxZoom
      const max = maxZoom - publicMapMinZoom

      return [min, max]
    },

    formatSettingsPublicMapZoom() {
      const maxZoom = this.mapZooms.at(-1)
      const min = maxZoom - this.fields.publicMapZoom.value.at(-1)
      const max = maxZoom - this.fields.publicMapZoom.value.at(0)

      return {
        publicMapMinZoom: min,
        publicMapMaxZoom: max,
      }
    },
  },
}
</script>
