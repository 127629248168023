<template>
  <form :novalidate="true" class="box" @submit.prevent="submit">
    <h2 class="title is-4">Création de zone(s)</h2>
    <div class="columns is-vcentered">
      <b-field label="Type de création de zone" class="column">
        <b-select v-model="zoneCreationType" expanded icon="draw-polygon">
          <option v-for="type in zoneCreationTypes" :key="type" :value="type">
            {{ type }}
          </option>
        </b-select>
      </b-field>
      <div v-show="generateZoneButton || resetZoneButton" class="column is-narrow mt-4">
        <b-button
          v-show="generateZoneButton"
          type="is-primary"
          label="Générer"
          @click="generateZones"
        />
        <b-button
          v-show="resetZoneButton"
          type="is-danger"
          label="Réinitialiser"
          @click="resetZones"
        />
      </div>
    </div>

    <b-field :message="zoneCreationMessage">
      <live-stage-map
        style="height: 400px"
        class="card mb-3"
        :stage-routes="stageRoutes"
        :stage-waypoints="stageWaypoints"
        :stage-speed-zones="stageSpeedZones"
        :route-opacity="0.5"
        @selectRoute="createZoneOnRoute"
        @click="createZoneOffRoute"
      >
        <template v-if="selectedZone">
          <o-map-drag-marker
            :lat-lng="selectedZone.start"
            @drag="onDrag($event, 'start', selectedZone)"
          />
          <o-map-drag-marker
            :lat-lng="selectedZone.end"
            @drag="onDrag($event, 'end', selectedZone)"
          />
        </template>
        <o-map-zone
          v-for="zone in zones"
          :key="zone.id"
          :name="zone.name"
          :path="zone.path"
          :radius="zone.distance"
          :color="zone.color"
          @click="selectedZone = zone"
        />
      </live-stage-map>
    </b-field>

    <div v-if="zones.length > 0" class="columns is-multiline">
      <b-field label="Format de coordonnées" class="column is-full">
        <b-select
          v-model="coordinateFormat"
          expanded
          placeholder="Choisissez un format de coordonnées"
          icon="map-marker-alt"
        >
          <option v-for="format in coordinateFormats" :key="format.value" :value="format.value">
            {{ format.name }}{{ format.example }}
          </option>
        </b-select>
      </b-field>
      <hr />
      <div v-for="zone in zones" :key="'zone-' + zone.id" class="column is-full">
        <live-speed-zone-fields
          :coordinate-format="coordinateFormat"
          :is-open="selectedZone !== null && selectedZone.id === zone.id"
          :zone="zone"
          :live-categories="filteredCategories"
          @click="selectedZone = zone"
          @delete="resetZone"
          @updateField="onInput($event, zone)"
        />
      </div>
    </div>

    <hr />
    <b-field class="buttons">
      <b-button
        native-type="submit"
        type="is-primary"
        :loading="loading"
        label="Ajouter"
        :disabled="loading || isFormInvalid"
      />
      <b-button
        tag="router-link"
        :to="{ name: 'live.edit.stage.edit', params: { tab: 3 } }"
        type="is-danger"
        label="Annuler"
      />
    </b-field>
  </form>
</template>

<script>
import { mapActions } from 'vuex'
import OMapZone from '@components/Map/MapZone.vue'
import OMapDragMarker from '@components/Map/MapDragMarker.vue'
import LiveStageMap from '@views/MapView/LiveStageMap.vue'
import LiveSpeedZoneFields from './LiveSpeedZoneFields.vue'
import { FORMAT_DD } from '@filters/coordinate'
import { COORDINATE_FORMATS } from '@constants/settings/coordinate'
import { SpeedZone } from '../index'

const ZONE_CREATION_TYPE_ALL_ROUTE = 'Sur tout le parcours'
const ZONE_CREATION_TYPE_ON_ROUTE = 'Sur une partie du parcours'
const ZONE_CREATION_TYPE_OFF_ROUTE = 'Sans parcours'

export default {
  name: 'LiveSpeedZoneForm',

  components: {
    OMapZone,
    OMapDragMarker,
    LiveStageMap,
    LiveSpeedZoneFields,
  },

  props: {
    loading: {
      type: Boolean,
      required: true,
    },
    liveStageId: {
      type: String,
      required: true,
    },
    stageRoutes: {
      type: Object,
      validator: (v) => ['public', 'private'].every((key) => key in v),
      required: true,
    },
    stageSpeedZones: {
      type: Array,
      default: () => [],
    },
    stageWaypoints: {
      type: Array,
      default: () => [],
    },
    liveCategories: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      zones: [],
      selectedZone: null,
      zoneCreationType: null,
      coordinateFormat: FORMAT_DD,
    }
  },

  created() {
    this.coordinateFormats = COORDINATE_FORMATS
  },

  watch: {
    zoneCreationTypes(zoneCreationTypes) {
      this.zoneCreationType = zoneCreationTypes[0]
    },

    zoneCreationType() {
      if (this.zones.length > 0) {
        this.resetZones()
      }
    },
  },

  computed: {
    zoneCreationTypes() {
      return this.routes.length === 0
        ? [ZONE_CREATION_TYPE_OFF_ROUTE]
        : [ZONE_CREATION_TYPE_ALL_ROUTE, ZONE_CREATION_TYPE_ON_ROUTE, ZONE_CREATION_TYPE_OFF_ROUTE]
    },

    zoneCreationMessage() {
      const messages = {
        [ZONE_CREATION_TYPE_ALL_ROUTE]:
          'Appuyez sur le bouton pour générer une zone sur toute la trace',
        [ZONE_CREATION_TYPE_ON_ROUTE]: 'Selectionnez la/les route(s) pour générer une zone.',
        [ZONE_CREATION_TYPE_OFF_ROUTE]: 'CLiquez sur la carte pour dessiner une zone',
      }

      return messages[this.zoneCreationType]
    },

    generateZoneButton() {
      return this.zoneCreationType === ZONE_CREATION_TYPE_ALL_ROUTE
    },

    resetZoneButton() {
      return this.zones.length > 0
    },

    isFormInvalid() {
      return (
        this.zones.length === 0 || !!this.zones.find(({ hasErrorOnCreate }) => hasErrorOnCreate)
      )
    },

    routes() {
      return this.stageRoutes.private.length > 0
        ? this.stageRoutes.private
        : this.stageRoutes.public.length > 0
        ? this.stageRoutes.public
        : []
    },

    filteredCategories() {
      return this.liveCategories.filter((category) => category.useCheckpoint)
    },
  },

  methods: {
    ...mapActions('ui', ['addToastMessage']),

    submit() {
      if (this.isFormInvalid) {
        return
      }

      const formattedZones = this.zones.map((zone) => {
        return {
          liveStageId: this.liveStageId,
          liveStageRouteId: zone.liveStageRouteId,
          liveCategoryId: zone.liveCategoryId,
          name: zone.name,
          color: zone.color,
          speed: zone.speed,
          distance: zone.distance,
          path: zone.path.map(([latitude, longitude]) => ({ latitude, longitude })),
        }
      })

      this.$emit('submit', formattedZones)
    },

    async generateZones() {
      if (this.zoneCreationType !== ZONE_CREATION_TYPE_ALL_ROUTE) {
        return
      }

      this.resetZones()
      this.zones = this.routes.map(
        ({ id, name, path, liveCategoryId }, index) =>
          new SpeedZone({
            id: `generate-zone-${index}`,
            name,
            path,
            liveStageRouteId: id,
            liveStageRoutePath: path,
            liveCategoryId,
          }),
      )
      this.selectedZone = this.zones[0]
    },

    createZoneOnRoute(event, { id, name, path, liveCategoryId }) {
      if (this.zoneCreationType !== ZONE_CREATION_TYPE_ON_ROUTE) {
        return
      }

      const start = [event.latlng.lat, event.latlng.lng]
      const end = start

      const zone = new SpeedZone({
        id: this.incrementedId(),
        name,
        path: [start, end],
        liveStageRouteId: id,
        liveStageRoutePath: path,
        liveCategoryId,
      })

      this.selectedZone = zone
      this.zones.push(zone)
    },

    createZoneOffRoute(event) {
      if (this.zoneCreationType !== ZONE_CREATION_TYPE_OFF_ROUTE) {
        return
      }

      const start = [event.latlng.lat, event.latlng.lng]
      const end = start
      const id = this.incrementedId()

      const zone = new SpeedZone({
        id: id,
        name: `Zone ${id}`,
        path: [start, end],
      })

      this.selectedZone = zone
      this.zones.push(zone)
    },

    onDrag(event, position, zone) {
      zone[position] = [event.latlng.lat, event.latlng.lng]
    },

    onInput({ field, value }, zone) {
      zone[field] = value
    },

    resetZone(zone) {
      if (this.selectedZone?.id === zone.id) {
        this.selectedZone = null
      }

      this.zones = this.zones.filter((z) => z.id !== zone.id)
    },

    resetZones() {
      this.selectedZone = null
      this.zones = []
    },

    incrementedId() {
      return this.zones.length > 0 ? Math.max(...this.zones.map(({ id }) => id)) + 1 : 1
    },
  },
}
</script>
