<template>
  <form novalidate="true" @submit.prevent="submit">
    <b-field
      label="Nom"
      :message="fields.name.error"
      :type="fields.name.error !== null ? 'is-danger' : ''"
    >
      <b-input v-model="fields.name.value" type="text" />
    </b-field>
    <b-field
      label="Type d'accès"
      :message="fields.accessType.error"
      :type="fields.accessType.error !== null ? 'is-danger' : ''"
    >
      <b-field>
        <b-radio-button v-model="fields.accessType.value" native-value="public">
          <span>Public</span>
        </b-radio-button>
        <b-radio-button v-model="fields.accessType.value" native-value="private">
          <span>Privé</span>
        </b-radio-button>
      </b-field>
    </b-field>
    <b-field grouped group-multiline class="columns">
      <b-field
        :label="`Date de début (${utcOffset})`"
        :message="fields.startedAt.error"
        :type="fields.startedAt.error !== null ? 'is-danger' : ''"
        class="column is-full-mobile"
      >
        <o-date-time-picker
          v-model="fields.startedAt.value"
          :max-datetime="fields.endedAt.value"
          :timezone="live.timezone"
        />
      </b-field>
      <b-field
        :label="`Date de fin (${utcOffset})`"
        :message="fields.endedAt.error"
        :type="fields.endedAt.error !== null ? 'is-danger' : ''"
        class="column is-full-mobile"
      >
        <o-date-time-picker
          v-model="fields.endedAt.value"
          :min-datetime="fields.startedAt.value"
          :timezone="live.timezone"
        />
      </b-field>
    </b-field>
    <div>Fuseau horaire: {{ live.timezone }}</div>
    <b-field
      class="mt-2"
      label="Distance alerte hors zone (m)"
      :message="fields.offTrackLimit.error"
      :type="fields.offTrackLimit.error !== null ? 'is-danger' : ''"
    >
      <b-input v-model="fields.offTrackLimit.value" type="number" />
    </b-field>
    <hr />
    <b-field>
      <div class="buttons">
        <b-button native-type="submit" type="is-primary" :loading="loading"> Modifier </b-button>
      </div>
    </b-field>
  </form>
</template>

<script>
import { getTimezoneOffset } from 'date-fns-tz'
import { mapActions } from 'vuex'
import ODateTimePicker from '@components/DateTimePicker.vue'

export default {
  name: 'EditLiveStageForm',

  components: { ODateTimePicker },

  props: {
    live: {
      type: Object,
      required: true,
    },
    liveStage: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      loading: false,
      fields: {
        name: {
          value: '',
          error: null,
        },
        accessType: {
          value: 'public',
          error: null,
        },
        startedAt: {
          value: new Date(),
          error: null,
        },
        endedAt: {
          value: new Date(),
          error: null,
        },
        offTrackLimit: {
          value: null,
          error: null,
        },
      },
    }
  },

  watch: {
    liveStage: {
      immediate: true,
      handler: 'load',
    },
  },

  computed: {
    utcOffset() {
      const offset = getTimezoneOffset(this.live.timezone) / 1000 / 60 / 60
      return `UTC${offset >= 0 ? '+' : ''}${offset}`
    },
  },

  methods: {
    ...mapActions('ui', ['addToastMessage']),

    load() {
      this.fields.name.value = this.liveStage.name
      this.fields.accessType.value = this.liveStage.accessType
      this.fields.startedAt.value = this.liveStage.startedAt
      this.fields.endedAt.value = this.liveStage.endedAt
      this.fields.offTrackLimit.value = this.liveStage.offTrackLimit
    },

    async submit() {
      this.loading = true

      try {
        const liveStage = await this.$services.liveStageService.update(this.liveStage.id, {
          name: this.fields.name.value,
          accessType: this.fields.accessType.value,
          startedAt: this.fields.startedAt.value,
          endedAt: this.fields.endedAt.value,
          offTrackLimit:
            this.fields.offTrackLimit.value > 0 ? +this.fields.offTrackLimit.value : null,
        })

        this.$emit('update:liveStage', liveStage)
        this.addToastMessage({
          text: `L'étape "${liveStage.name}" a été mis à jour.`,
          type: 'is-success',
        })
      } catch (err) {
        this.addToastMessage({
          text: err.message,
          type: 'is-danger',
        })

        console.error(err)
      }
      this.loading = false
    },
  },
}
</script>
