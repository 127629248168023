<template>
  <live-add-vehicle-form
    class="box"
    :live="live"
    :loading="loading"
    :liveCategories="liveCategories"
    :spotTrackers="spotTrackers"
    :queclinkTrackers="queclinkTrackers"
    @submit="submit"
    @fetchSpotTrackers="fetchSpotTrackers"
    @fetchQueclinkTrackers="fetchQueclinkTrackers"
  />
</template>

<script>
import { mapActions } from 'vuex'
import LiveAddVehicleForm from './LiveAddVehicleForm.vue'
import { AVAILABILITY_AVAILABLE } from '@constants/tracker/availability'
import { isPast } from 'date-fns'

export default {
  name: 'EditLiveAddVehicleTab',

  components: {
    LiveAddVehicleForm,
  },

  props: {
    live: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      loading: false,
      liveCategories: [],
      spotTrackers: {
        loading: false,
        data: [],
      },
      queclinkTrackers: {
        loading: false,
        data: [],
      },
    }
  },

  async mounted() {
    this.loading = true
    this.liveCategories = await this.$services.liveCategoryService.getAll(this.live.id)
    this.loading = false
  },

  methods: {
    ...mapActions('ui', ['addToastMessage']),

    async submit(vehicle) {
      this.loading = true

      try {
        const liveVehicle = await this.$services.liveVehicleService.create({
          liveCategoryId: vehicle.liveCategoryId,
          number: vehicle.number,
          name: vehicle.name,
          country: vehicle.country,
          accessType: vehicle.accessType,
          spotTrackerId: vehicle.spotTrackerId,
          queclinkTrackerId: vehicle.queclinkTrackerId,
          gender: vehicle.gender,
          comment: vehicle.comment,
        })

        this.addToastMessage({
          text: `Le véhicule "${liveVehicle.name}" a été créé.`,
          type: 'is-success',
        })

        this.$router.push({
          name: 'live.edit.vehicle.list',
          params: { id: this.live.id },
        })
      } catch (err) {
        this.addToastMessage({
          text: 'Une erreur interne est survenue.',
          type: 'is-danger',
        })
        console.error(err)
      }

      this.loading = false
    },

    async fetchSpotTrackers(input) {
      const keyword = input.trim().toUpperCase()

      if (keyword.length < 2) {
        this.spotTrackers.data = []
        return
      }

      this.spotTrackers.loading = true
      const spotTrackers = await this.$services.spotTrackerService.getAll({
        availability: AVAILABILITY_AVAILABLE,
        keyword,
      })
      this.spotTrackers.data = spotTrackers.filter(
        (spotTracker) => spotTracker.expiredAt && !isPast(spotTracker.expiredAt),
      )
      this.spotTrackers.loading = false
    },

    async fetchQueclinkTrackers(input) {
      const keyword = input.trim().toUpperCase()

      if (keyword.length < 2) {
        this.queclinkTrackers.data = []
        return
      }

      this.queclinkTrackers.loading = true
      this.queclinkTrackers.data = await this.$services.queclinkTrackerService.getAll({
        availability: 'available',
        keyword,
      })
      this.queclinkTrackers.loading = false
    },
  },
}
</script>
