<template>
  <div class="columns is-multiline">
    <div v-if="showLiveChecklistItems" class="column is-three-fifths-desktop is-full-mobile">
      <live-checklist-items
        :loading="loading"
        :live-status="live.status"
        :live-checklist-items="liveChecklistItems"
        style="height: 100%"
        @submit="updateLiveStatus"
        @check="updateLiveChecklistItem"
      >
        <template #checkbox="{ item }">
          <template v-if="showTrackerAvailability(item)">
            <b-checkbox type="is-success" disabled class="column is-full-mobile py-1">
              {{ item.name }}
            </b-checkbox>
          </template>
        </template>
        <template #content="{ item }">
          <template v-if="showTrackerAvailability(item)">
            <live-tracker-availability
              :live="live"
              class="column is-full-mobile"
              @submit="updateLiveChecklistItem(item)"
            />
          </template>
        </template>
      </live-checklist-items>
    </div>
    <div class="column is-full-mobile">
      <live-comment
        :loading="loading"
        :comment="live.comment"
        style="height: 100%"
        @submit="updateLiveComment"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import LiveComment from './LiveComment.vue'
import LiveChecklistItems from './LiveChecklistItems.vue'
import LiveTrackerAvailability from './LiveTrackerAvailability/index.vue'
import { STATUS_FINISHED } from '@constants/live/status.js'

export default {
  name: 'EditLiveChecklistItemTab',

  components: {
    LiveComment,
    LiveChecklistItems,
    LiveTrackerAvailability,
  },

  props: {
    live: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      loading: false,
      administratorNames: [],
      liveChecklistItems: [],
    }
  },

  async mounted() {
    if (this.showLiveChecklistItems) {
      this.administratorNames = await this.fetchAdministratorNames()
      this.liveChecklistItems = await this.fetchLiveChecklistItems(this.live.id)
    }
  },

  watch: {
    async live(live) {
      if (this.showLiveChecklistItems) {
        this.liveChecklistItems = await this.fetchLiveChecklistItems(live.id)
      }
    },
  },

  computed: {
    ...mapState('auth', ['identity']),

    showLiveChecklistItems() {
      return this.live.status !== STATUS_FINISHED
    },
  },

  methods: {
    ...mapActions('ui', ['addToastMessage']),

    async fetchAdministratorNames() {
      return (await this.$services.administratorService.getAll()).reduce(
        (administrators, administrator) => ({
          ...administrators,
          [administrator.id]: administrator.firstname,
        }),
        {},
      )
    },

    async fetchLiveChecklistItems(liveId) {
      return (await this.$services.liveChecklistItemService.getAll(liveId)).map((item) => ({
        ...item,
        administratorName: this.administratorNames[item.administratorId],
      }))
    },

    async updateLiveChecklistItem(item) {
      this.loading = true

      try {
        const updatedChecklistItem = await this.$services.liveChecklistItemService.update({
          id: item.id,
          done: !item.done,
          administratorId: this.identity.id,
        })
        updatedChecklistItem.administratorName =
          this.administratorNames[updatedChecklistItem.administratorId]

        this.liveChecklistItems = this.liveChecklistItems.map((item) =>
          item.id === updatedChecklistItem.id ? updatedChecklistItem : item,
        )

        this.addToastMessage({
          text: 'La tâche a été modifiée',
          type: 'is-success',
        })
      } catch (err) {
        console.log(err)
        this.addToastMessage({
          text: 'Une erreur interne est survenue.',
          type: 'is-danger',
        })
      }

      this.loading = false
    },

    async updateLiveStatus(status) {
      await this.updateLiveInformations(
        { status },
        `Le status du live "${this.live.name}" a été mis à jour.`,
      )
    },

    async updateLiveComment(comment) {
      await this.updateLiveInformations(
        { comment },
        `Le commentaire du live "${this.live.name}" a été mis à jour.`,
      )
    },

    async updateLiveInformations(information, successMessage) {
      this.loading = true

      try {
        const live = await this.$services.liveService.updateInformations(this.live.id, {
          ...this.live,
          ...information,
        })

        this.$emit('update:live', live)

        this.addToastMessage({
          text: successMessage,
          type: 'is-success',
        })
      } catch (err) {
        console.log(err)
        this.addToastMessage({
          text: 'Une erreur interne est survenue.',
          type: 'is-danger',
        })
      }

      this.loading = false
    },

    showTrackerAvailability(item) {
      return !item.done && item.name === 'Réceptionner les balises'
    },
  },
}
</script>
