<template>
  <div class="columns is-multiline">
    <div class="column is-12">
      <b-field label="Actions">
        <b-select v-model="fields.action">
          <option v-for="action in actions" :key="action.key" :value="action.key">
            {{ action.label }}
          </option>
        </b-select>
      </b-field>
      <b-field v-show="showUpdateCheckboxes">
        <b-checkbox-button
          v-for="update in updates"
          :key="update.key"
          v-model="fields.updates"
          type="is-primary is-light"
          :native-value="update.key"
        >
          <span>{{ update.label }}</span>
        </b-checkbox-button>
      </b-field>
    </div>
    <div v-show="fields.updates.length > 0" class="column is-12">
      <div class="columns">
        <div v-show="fields.radius.show" class="column is-narrow">
          <b-field label="Rayon">
            <o-dropdown
              v-model="fields.radius.value"
              :items="radiuses"
              @input="fields.radius.value = $event"
            >
              <template #label="{ label }">
                {{ radius(label) }}
              </template>
              <template #item="{ item }">
                {{ radius(item) }}
              </template>
            </o-dropdown>
          </b-field>
        </div>
        <div v-show="fields.liveCategoryId.show" class="column is-narrow">
          <b-field
            label="Catégorie"
            :message="fields.liveCategoryId.error"
            :type="fields.liveCategoryId.error !== null ? 'is-danger' : ''"
          >
            <b-select v-model="fields.liveCategoryId.value" expanded>
              <option :value="null">—</option>
              <option v-for="category in liveCategories" :key="category.id" :value="category.id">
                {{ category.name }}
              </option>
            </b-select>
          </b-field>
        </div>
        <div v-show="fields.color.show" class="column is-narrow">
          <b-field label="Couleur">
            <o-dropdown
              v-model="fields.color.value"
              :items="colors"
              @input="fields.color.value = $event"
            />
          </b-field>
        </div>
        <div v-show="fields.icon.show" class="column is-narrow">
          <b-field label="Icône">
            <o-icon-picker v-model="fields.icon.value" :icons="icons" />
          </b-field>
        </div>
        <div v-show="fields.accessType.show" class="column is-narrow">
          <b-field label="Accès">
            <o-dropdown
              v-model="fields.accessType.value"
              :items="accessTypes"
              @input="fields.accessType.value = $event"
            >
              <template #label="{ label }">
                <b-icon
                  size="is-small"
                  :type="accessTypeIcons[label].type"
                  :icon="accessTypeIcons[label].icon"
                />
              </template>
              <template #item="{ item }">
                <b-icon
                  size="is-small"
                  :type="accessTypeIcons[item].type"
                  :icon="accessTypeIcons[item].icon"
                />
              </template>
            </o-dropdown>
          </b-field>
        </div>
      </div>
    </div>
    <b-field class="column is-12">
      <b-button type="is-primary" :loading="loading" :disabled="disabledSubmit" @click="submit">
        {{ actions[fields.action].label }} la sélection
      </b-button>
    </b-field>
  </div>
</template>

<script>
import { COLORS } from '@constants/waypoint/color'
import { RADIUSES } from '@constants/waypoint/radiuses'
import {
  WAYPOINT_ACCESS_TYPE_ICONS,
  WAYPOINT_ACCESS_TYPE_PRIVATE,
  WAYPOINT_ACCESS_TYPE_PUBLIC,
} from '@constants/waypoint/accessType'
import ODropdown from '@components/Dropdown'
import { iconNames } from '@data/icons'
import OIconPicker from '@components/IconPicker.vue'

const UPDATE_ACTION = 'update'
const DELETE_ACTION = 'delete'

const COLOR = 'color'
const ICON = 'icon'
const RADIUS = 'radius'
const CATEGORY = 'liveCategoryId'
const ACCESS_TYPE = 'accessType'

export default {
  name: 'LiveStageWaypointForm',

  components: { OIconPicker, ODropdown },

  props: {
    loading: {
      type: Boolean,
      required: true,
    },
    liveCategories: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      fields: {
        action: UPDATE_ACTION,
        updates: [],
        color: {
          show: false,
          value: COLORS[0],
        },
        icon: {
          show: false,
          value: null,
        },
        radius: {
          show: false,
          value: RADIUSES[0],
        },
        accessType: {
          show: false,
          value: WAYPOINT_ACCESS_TYPE_PUBLIC,
        },
        liveCategoryId: {
          show: false,
          value: null,
          error: null,
        },
      },
    }
  },

  created() {
    this.colors = COLORS
    this.icons = iconNames
    this.radiuses = [null, ...RADIUSES]
    this.accessTypeIcons = WAYPOINT_ACCESS_TYPE_ICONS
    this.accessTypes = [WAYPOINT_ACCESS_TYPE_PUBLIC, WAYPOINT_ACCESS_TYPE_PRIVATE]

    this.updates = {
      [COLOR]: { key: COLOR, label: 'Couleur' },
      [ICON]: { key: ICON, label: 'Icône' },
      [RADIUS]: { key: RADIUS, label: 'Rayon' },
      [CATEGORY]: { key: CATEGORY, label: 'Catégorie' },
      [ACCESS_TYPE]: { key: ACCESS_TYPE, label: 'Accès' },
    }

    this.actions = {
      [UPDATE_ACTION]: { key: UPDATE_ACTION, label: 'Modifier' },
      [DELETE_ACTION]: { key: DELETE_ACTION, label: 'Supprimer' },
    }
  },

  watch: {
    'fields.action'() {
      this.fields.updates = []
    },

    'fields.updates'() {
      this.fields.color.show = this.fields.updates.indexOf(COLOR) !== -1
      this.fields.icon.show = this.fields.updates.indexOf(ICON) !== -1
      this.fields.radius.show = this.fields.updates.indexOf(RADIUS) !== -1
      this.fields.liveCategoryId.show = this.fields.updates.indexOf(CATEGORY) !== -1
      this.fields.accessType.show = this.fields.updates.indexOf(ACCESS_TYPE) !== -1
    },

    'fields.radius.show'(show) {
      if (show && this.fields.liveCategoryId.error) {
        this.fields.liveCategoryId.error = null
      }
    },

    'fields.radius.value'(radius) {
      this.fields.liveCategoryId.error =
        !radius && this.fields.liveCategoryId.value ? 'Rayon requis' : null
    },

    'fields.liveCategoryId.value'(categoryId) {
      this.fields.liveCategoryId.error =
        !this.fields.radius.show || (!this.fields.radius.value && categoryId)
          ? 'Rayon requis'
          : null
    },
  },

  computed: {
    disabledSubmit() {
      return (
        this.loading ||
        this.fields.liveCategoryId.error ||
        (this.fields.action === UPDATE_ACTION && this.fields.updates.length === 0)
      )
    },

    showUpdateCheckboxes() {
      return this.fields.action === UPDATE_ACTION
    },
  },

  methods: {
    submit() {
      if (this.fields.action === DELETE_ACTION) {
        this.$emit('delete')
        return
      }

      const fields = this.fields.updates.reduce(
        (updates, update) => ({ ...updates, [update]: this.fields[update].value }),
        {},
      )

      this.$emit('submit', fields)
    },

    radius(radius) {
      return radius ? `${radius}m` : '—'
    },
  },
}
</script>
