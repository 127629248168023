<template>
  <b-button :type="type" @click="copyToClipboard">
    <span class="icon is-small">
      <i class="fas" :class="icon"></i>
    </span>
  </b-button>
</template>

<script>
export default {
  name: 'CopyButton',

  props: {
    textToCopy: {
      type: String,
      default: '',
      required: true,
    },
  },

  data() {
    return {
      isCopied: false,
    }
  },

  computed: {
    type() {
      return this.isCopied ? 'is-success' : 'is-primary'
    },

    icon() {
      return this.isCopied ? 'fa-check' : 'fa-copy'
    },
  },

  methods: {
    copyToClipboard() {
      if (!this.isCopied) {
        const textArea = document.createElement('textarea')
        textArea.textContent = this.textToCopy
        document.body.append(textArea)
        textArea.select()
        document.execCommand('copy')
        textArea.remove()

        this.isCopied = true

        setTimeout(() => {
          this.isCopied = false
        }, 1000)
      }
    },
  },
}
</script>
