<template>
  <o-card title="Commentaire" class="live-comment">
    <template v-slot:content>
      <b-input v-model="field.comment" type="textarea" class="live-comment__textarea"></b-input>
    </template>
    <template v-slot:footer>
      <b-button
        type="is-primary"
        :loading="loading"
        :disabled="loading"
        label="Modifier le commentaire"
        @click="$emit('submit', field.comment)"
      />
    </template>
  </o-card>
</template>

<script>
import OCard from '@components/Card.vue'

export default {
  name: 'LiveComment',

  components: {
    OCard,
  },

  props: {
    loading: {
      type: Boolean,
      required: true,
    },
    comment: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      field: {
        comment: '',
      },
    }
  },

  watch: {
    comment: {
      handler(comment) {
        this.field.comment = comment
      },
      immediate: true,
    },
  },
}
</script>

<style lang="scss">
.live-comment {
  .textarea,
  &__textarea {
    height: 100%;
  }
}
</style>
