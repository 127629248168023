<template>
  <div class="columns is-multiline">
    <edit-live-review-tracker-selection
      v-model="selectedTrackers"
      class="column is-full"
      :loading="loading"
      :liveVehicles="liveVehicles"
      :spotTrackers="spotTrackers"
      :queclinkTrackers="queclinkTrackers"
      @selectTracker="fetchTrackerLocations"
    />
    <edit-live-review-tracker-date-range
      class="column is-full"
      :live="live"
      :loading="loading"
      @onEnd="fetchTrackersLocations"
    />
    <edit-live-review-tracker-chart
      v-if="selectedTrackers.length"
      :trackers="selectedTrackers"
      class="column is-full"
    />
    <edit-live-review-tracker-map
      v-if="selectedTrackers.length"
      :trackers="selectedTrackers"
      class="column is-full"
    />
  </div>
</template>

<script>
import { QUECLINK_TRACKER_TYPE, SPOT_TRACKER_TYPE } from '@constants/tracker/type'
import EditLiveReviewTrackerSelection from './EditLiveReviewTrackerSelection.vue'
import EditLiveReviewTrackerDateRange from './EditLiveReviewTrackerDateRange.vue'
import EditLiveReviewTrackerChart from './EditLiveReviewTrackerChart.vue'
import EditLiveReviewTrackerMap from './EditLiveReviewTrackerMap.vue'
import { mapActions } from 'vuex'

export default {
  name: 'EditLiveReviewTracker',

  components: {
    EditLiveReviewTrackerSelection,
    EditLiveReviewTrackerDateRange,
    EditLiveReviewTrackerChart,
    EditLiveReviewTrackerMap,
  },

  props: {
    live: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      loading: false,
      liveVehicles: [],
      spotTrackers: [],
      queclinkTrackers: [],
      selectedTrackers: [],
      interval: null,
    }
  },

  async mounted() {
    this.loading = true

    await this.fetchLiveVehicles()
    await Promise.all([this.fetchSpotTrackers(), this.fetchQueclinkTrackers()])

    this.loading = false
  },

  methods: {
    ...mapActions('ui', ['addToastMessage']),

    async fetchLiveVehicles() {
      try {
        this.liveVehicles = await this.$services.liveVehicleService.getAll(this.live.id)
      } catch (error) {
        this.liveVehicles = []
      }
    },

    async fetchSpotTrackers() {
      try {
        this.spotTrackers = await this.$services.spotTrackerService.getAllByLiveId(this.live.id)
      } catch (error) {
        this.spotTrackers = []
      }
    },

    async fetchQueclinkTrackers() {
      try {
        this.queclinkTrackers = await this.$services.queclinkTrackerService.getAllByLiveId(
          this.live.id,
        )
      } catch (error) {
        this.queclinkTrackers = []
      }
    },

    async fetchTrackerLocations(tracker) {
      this.loading = true

      let locations = tracker.locations
      try {
        if (tracker.type === SPOT_TRACKER_TYPE) {
          locations = await this.$services.spotTrackerLocationService.getAllBySpotTrackerId(
            tracker.id,
            this.interval?.start || this.live.startedAt,
            this.interval?.end || this.live.endedAt,
          )
        }

        if (tracker.type === QUECLINK_TRACKER_TYPE) {
          locations = await this.$services.queclinkTrackerLocationService.getAllByQueclinkTrackerId(
            tracker.id,
            this.interval?.start || this.live.startedAt,
            this.interval?.end || this.live.endedAt,
          )
        }
      } catch (error) {
        console.error(error)
      }

      this.selectedTrackers = this.selectedTrackers.map((selectedTracker) => {
        if (selectedTracker.id === tracker.id) {
          return {
            ...selectedTracker,
            locations,
          }
        }

        return selectedTracker
      })

      this.loading = false
    },

    async fetchTrackersLocations(interval) {
      this.interval = interval

      const promises = []
      for (const tracker of this.selectedTrackers) {
        promises.push(this.fetchTrackerLocations(tracker))
      }

      await Promise.all(promises)
    },
  },
}
</script>
