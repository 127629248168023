<template>
  <div>
    <o-table
      :items="liveStats"
      :loading="loading"
      :draggable="!selectedLiveStat"
      @drop="
        (droppedOnRowIndex, draggingRowIndex) => $emit('drop', droppedOnRowIndex, draggingRowIndex)
      "
    >
      <template>
        <b-table-column v-slot="{ row }" field="label" label="Label">
          <template v-if="!showRow(row)">
            {{ row.label }}
          </template>
          <template v-else-if="showRow(row)">
            <b-field
              expanded
              :message="fields.label.error"
              :type="fields.label.error !== null ? 'is-danger' : ''"
            >
              <b-input
                v-model="fields.label.value"
                expanded
                placeholder="Label de la statistique"
                type="text"
                :icon-right="fields.label.value ? 'close-circle' : ''"
                :icon-right-clickable="!!fields.label.value"
                @icon-right-click="fields.label.value = null"
              />
            </b-field>
          </template>
        </b-table-column>
        <b-table-column v-slot="{ row }" field="value" label="Valeur">
          <template v-if="!showRow(row)">
            {{ row.value }}
          </template>
          <template v-else-if="showRow(row)">
            <b-field
              expanded
              :message="fields.value.error"
              :type="{ 'is-danger': fields.value.error }"
            >
              <b-input
                v-model="fields.value.value"
                expanded
                placeholder="Valeur de la statistique"
                type="text"
                :icon-right="fields.value.value ? 'close-circle' : ''"
                :icon-right-clickable="!!fields.value.value"
                @icon-right-click="fields.value.value = null"
              />
            </b-field>
          </template>
        </b-table-column>
        <b-table-column v-slot="{ row }" field="icon" label="Icône" width="25%">
          <template v-if="!showRow(row)">
            <o-icon :name="row.icon" />
          </template>
          <template v-else-if="showRow(row)">
            <b-field
              :message="fields.icon.error"
              :type="fields.icon.error !== null ? 'is-danger' : ''"
            >
              <o-icon-picker v-model="fields.icon.value" :icons="icons" :expanded="false" />
            </b-field>
          </template>
        </b-table-column>
        <b-table-column v-slot="{ row }" label="" width="100">
          <div v-if="!showRow(row)" class="buttons are-small is-right">
            <b-button type="is-primary" @click="selectedLiveStat = row">
              <b-icon icon="edit" />
            </b-button>
            <b-button type="is-danger" :loading="loading" @click="toggleModal(row)">
              <b-icon icon="trash" />
            </b-button>
          </div>
          <div v-else-if="showRow(row)" class="buttons are-small is-right">
            <b-button
              type="is-success"
              :loading="loading"
              :disabled="isFormInvalid"
              @click="submit"
            >
              <b-icon icon="check" />
            </b-button>
            <b-button type="is-warning" @click="selectedLiveStat = null">
              <b-icon icon="times" />
            </b-button>
          </div>
        </b-table-column>
      </template>
    </o-table>
    <o-modal
      :title="modalTitle"
      :isOpen="!!selectedLiveStat && isOpenModal"
      @closeModal="toggleModal"
      @confirmModalAction="deleteLiveStat"
    >
      <template> La suppression d'une statistique est <strong>définitive</strong>. </template>
    </o-modal>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { iconNames } from '@data/icons'
import OTable from '@components/Table.vue'
import OModal from '@components/Modal.vue'
import OIconPicker from '@components/IconPicker.vue'

export default {
  name: 'LiveStatTable',

  components: {
    OTable,
    OModal,
    OIconPicker,
  },

  props: {
    liveStats: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      loading: false,
      isOpenModal: false,
      selectedLiveStat: null,
      fields: {
        label: {
          value: null,
          error: null,
        },
        value: {
          value: null,
          error: null,
        },
        icon: {
          value: null,
          error: null,
        },
      },
    }
  },

  created() {
    this.icons = iconNames
  },

  watch: {
    selectedLiveStat(liveStat) {
      if (!liveStat) {
        this.resetFields()
        return
      }

      this.fields.label.value = liveStat.label
      this.fields.value.value = liveStat.value
      this.fields.icon.value = liveStat.icon
    },

    'fields.label.value'(label) {
      this.fields.label.error = !label || label.length === 0 ? 'Label requis' : null
    },

    'fields.value.value'(value) {
      this.fields.value.error = !value || value.length === 0 ? 'Valeur requise' : null
    },

    'fields.icon.value'(icon) {
      this.fields.icon.error = !icon || icon.length === 0 ? 'Icône requise' : null
    },
  },

  computed: {
    isFormInvalid() {
      return (
        Object.values(this.fields).find((field) => field.error !== null || field.value === null) ||
        this.loading
      )
    },

    modalTitle() {
      return `Suppression de la statistique ${
        this.selectedLiveStat && this.selectedLiveStat.position + 1
      }`
    },
  },

  methods: {
    ...mapActions('ui', ['addToastMessage']),

    async submit() {
      this.loading = true

      try {
        const updatedLiveStat = await this.$services.liveStatService.update({
          ...this.selectedLiveStat,
          label: this.fields.label.value,
          value: this.fields.value.value,
          icon: this.fields.icon.value,
        })

        this.selectedLiveStat = null
        this.$emit('submit', updatedLiveStat)

        this.addToastMessage({
          text: 'La statistique a été modifiée.',
          type: 'is-success',
        })
      } catch (err) {
        this.addToastMessage({
          text: 'Une erreur interne est survenue.',
          type: 'is-danger',
        })
      }

      this.loading = false
    },

    async deleteLiveStat() {
      this.loading = true

      try {
        const deletedLiveLiveStat = await this.$services.liveStatService.delete(
          this.selectedLiveStat.id,
        )

        this.selectedLiveStat = null
        this.$emit('delete', deletedLiveLiveStat)

        this.addToastMessage({
          text: 'La statistique a été supprimée.',
          type: 'is-success',
        })
      } catch (err) {
        this.addToastMessage({
          text: 'Une erreur interne est survenue.',
          type: 'is-danger',
        })
      }

      this.loading = false
    },

    resetFields() {
      Object.values(this.fields).forEach((field) => {
        field.value = null
        field.error = null
      })
    },

    showRow(row) {
      return this.selectedLiveStat && !this.isOpenModal && row.id === this.selectedLiveStat.id
    },

    toggleModal(liveStat = null) {
      this.isOpenModal = !this.isOpenModal
      this.selectedLiveStat = liveStat
    },
  },
}
</script>
