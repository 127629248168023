<template>
  <o-tabs :labels="tabItems">
    <template #0>
      <edit-live-informations-form
        :live="live"
        class="box"
        @update:live="$emit('update:live', $event)"
      />
      <b-field label="Code d'intégration du live" class="box">
        <b-field>
          <b-input :value="iframe" expanded readonly />
          <p class="control">
            <copy-button :textToCopy="iframe" />
          </p>
        </b-field>
      </b-field>
    </template>
    <template #1>
      <edit-live-stat-tab :live="live" class="box" />
    </template>
    <template #2>
      <edit-live-external-link-tab :live="live" class="box" />
    </template>
    <template #3>
      <image-upload-form
        :imageType="imageType"
        :imageUrl="live.sponsorUrl"
        :submit="uploadOrganizerImage"
        label="Image organisateur"
        class="box column is-6 ml-0"
      />
      <edit-live-sponsor-tab :live="live" class="box column is-12" />
    </template>
    <template #4>
      <edit-live-document-tab :live="live" class="box" />
    </template>
    <template #5>
      <edit-live-review-tab :live="live" class="box" />
    </template>
  </o-tabs>
</template>

<script>
import { mapActions } from 'vuex'
import OTabs from '@components/Tabs.vue'
import CopyButton from '@components/CopyButton.vue'
import ImageUploadForm from '@components/ImageUploadForm.vue'
import EditLiveStatTab from './EditLiveStatTab'
import EditLiveReviewTab from './EditLiveReviewTab'
import EditLiveDocumentTab from './EditLiveDocumentTab.vue'
import EditLiveSponsorTab from './EditLiveSponsorTab'
import EditLiveExternalLinkTab from './EditLiveExternalLinkTab'
import EditLiveInformationsForm from './EditLiveInformationsForm.vue'

export default {
  name: 'EditInformationsLiveTab',

  components: {
    OTabs,
    CopyButton,
    ImageUploadForm,
    EditLiveStatTab,
    EditLiveReviewTab,
    EditLiveSponsorTab,
    EditLiveDocumentTab,
    EditLiveExternalLinkTab,
    EditLiveInformationsForm,
  },

  props: {
    live: {
      type: Object,
      required: true,
    },
  },

  created() {
    this.imageType = 'image/jpeg, image/png'
    this.tabItems = ['Général', 'Statistiques', 'Liens externes', 'Sponsors', 'Documents', 'Bilan']
  },

  computed: {
    iframe() {
      return `<iframe src="https://owaka.live/${this.live.slug}" width="100%" height="600"></iframe>`
    },
  },

  methods: {
    ...mapActions('ui', ['addToastMessage']),

    async uploadOrganizerImage(image) {
      try {
        const live = await this.$services.liveService.addLiveSponsor(this.live.id, image)
        this.$emit('update:live', live)

        this.addToastMessage({
          text: "L'image de l'organisateur a été mise à jour.",
          type: 'is-success',
        })
      } catch (err) {
        this.addToastMessage({
          text: 'Une erreur interne est survenue.',
          type: 'is-danger',
        })
      }
    },
  },
}
</script>
