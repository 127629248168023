<template>
  <div class="box">
    <div class="columns is-multiline">
      <div class="column is-full-mobile">
        <live-vehicle-tracker-card
          title="Participant 1"
          :live-vehicles="liveVehicleList"
          :to-tracker="toTracker"
          @hasError="fromTrackerHasError = $event"
          @selectVehicle="fromLiveVehicle = $event"
          @selectTracker="fromTracker = $event"
          @updateSelectedVehicle="fromLiveVehicle = $event"
        />
      </div>
      <div class="column is-full-mobile">
        <live-vehicle-tracker-card
          title="Participant 2"
          :live-vehicles="liveVehicleList"
          :to-tracker="fromTracker"
          @hasError="toTrackerHasError = $event"
          @selectVehicle="toLiveVehicle = $event"
          @selectTracker="toTracker = $event"
          @updateSelectedVehicle="toLiveVehicle = $event"
        />
      </div>
      <div class="column is-12">
        <b-field label="Commentaire">
          <b-input v-model="comment" type="textarea" class="o-card__textarea" />
        </b-field>
        <b-button
          type="is-primary"
          :loading="loading"
          :disabled="!isFormValid"
          label="Inverser les balises"
          @click="submit"
        />
      </div>
    </div>
  </div>
</template>

<script>
import LiveVehicleTrackerCard from './LiveVehicleTrackerCard.vue'

export default {
  name: 'LiveVehicleTrackerForm',

  components: {
    LiveVehicleTrackerCard,
  },

  props: {
    loading: {
      type: Boolean,
      required: true,
    },
    liveVehicles: {
      type: Array,
      required: true,
      validator: (v) => v.every((obj) => ['id'].every((key) => key in obj)),
    },
  },

  data() {
    return {
      fromTrackerHasError: false,
      toTrackerHasError: false,
      keywords: '',
      fromTracker: null,
      toTracker: null,
      fromLiveVehicle: null,
      toLiveVehicle: null,
      comment: null,
    }
  },

  watch: {
    liveVehicles() {
      this.fromTrackerHasError = false
      this.toTrackerHasError = false
      this.keywords = ''
      this.fromTracker = null
      this.toTracker = null
      this.fromLiveVehicle = null
      this.toLiveVehicle = null
      this.comment = null
    },
  },

  computed: {
    isFormValid() {
      return (
        !this.loading &&
        !this.fromTrackerHasError &&
        !this.toTrackerHasError &&
        this.fromLiveVehicle &&
        this.toLiveVehicle &&
        this.fromTracker &&
        this.toTracker
      )
    },

    liveVehicleList() {
      return this.liveVehicles.filter(
        (vehicle) =>
          vehicle.id !== this.toLiveVehicle?.id && vehicle.id !== this.fromLiveVehicle?.id,
      )
    },
  },

  methods: {
    submit() {
      if (this.isFormValid) {
        this.$emit('submit', {
          fromLiveVehicle: this.fromLiveVehicle,
          fromTracker: this.fromTracker,
          toLiveVehicle: this.toLiveVehicle,
          toTracker: this.toTracker,
          comment: this.comment,
        })
      }
    },
  },
}
</script>
