<template>
  <div class="mt-5 pb-5">
    <div class="level">
      <div class="level-left">
        <h3 class="title is-5 level-item">{{ title }}</h3>
      </div>
      <div class="level-right">
        <div class="level-item">
          <live-stage-route-upload
            :loading="loading"
            :stageRoutes="stageRoutes"
            @submit="uploadGpxFile"
          />
        </div>
        <div class="level-item">
          <o-export-button
            :disabled="loading || stageRoutes.length === 0"
            :file="stageRoutesGpx"
            file-type="gpx"
            :file-name="`${this.live.name}-${this.liveStage.name}-routes-${this.accessType}`"
            @export="exportGpx"
            @exported="stageRoutesGpx = null"
          />
        </div>
      </div>
    </div>
    <live-stage-route-table
      :loading="loading"
      :stageRoutes="stageRoutes"
      @submit="updateRoute"
      @drop="dropRoute"
      @delete="deleteRoute"
    />
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import LiveStageRouteTable from './LiveStageRouteTable.vue'
import LiveStageRouteUpload from './LiveStageRouteUpload.vue'
import OExportButton from '@components/ExportButton'

export default {
  name: 'EditLiveStageRouteTab',

  components: {
    OExportButton,
    LiveStageRouteUpload,
    LiveStageRouteTable,
  },

  props: {
    live: {
      type: Object,
      required: true,
      validator: (v) => ['name'].every((key) => key in v),
    },
    liveStage: {
      type: Object,
      required: true,
    },
    accessType: {
      type: String,
      required: true,
      validator: (value) => ['public', 'private'].indexOf(value) !== -1,
    },
  },

  data() {
    return {
      loading: false,
      stageRoutes: [],
      stageRoutesGpx: null,
    }
  },

  async mounted() {
    this.loading = true
    this.stageRoutes = await this.$services.liveStageRouteService.getAllByAccessType(
      this.liveStage.id,
      this.accessType,
    )
    this.loading = false
  },

  watch: {
    stageRoutes(stageRoutes) {
      this.$emit('update:stageRoutes', stageRoutes)
    },
  },

  computed: {
    title() {
      return this.accessType === 'private' ? 'Parcours privés' : 'Parcours publics'
    },
  },

  methods: {
    ...mapActions('ui', ['addToastMessage']),

    async uploadGpxFile(gpxFile) {
      this.loading = true

      try {
        this.stageRoutes = await this.$services.liveStageRouteService.create(
          this.liveStage.id,
          this.accessType,
          gpxFile,
        )

        this.addToastMessage({
          text: `Les parcours de "${this.liveStage.name}" ont été mis à jour.`,
          type: 'is-success',
        })
      } catch (err) {
        this.addToastMessage({
          text: 'Une erreur interne est survenue.',
          type: 'is-danger',
        })
      }

      this.loading = false
    },

    async updateRoute(route) {
      try {
        const updatedRoute = await this.$services.liveStageRouteService.update(route.id, {
          ...route,
        })

        this.stageRoutes = this.stageRoutes.map((stageRoute) =>
          stageRoute.id === updatedRoute.id
            ? { ...updatedRoute, pathLength: stageRoute.pathLength }
            : stageRoute,
        )

        this.addToastMessage({
          text: `La route "${updatedRoute.name}" a été mis à jour.`,
          type: 'is-success',
        })
      } catch (err) {
        this.addToastMessage({
          text: 'Une erreur interne est survenue.',
          type: 'is-danger',
        })
      }
    },

    dropRoute(droppedOnRowIndex, draggingRowIndex) {
      this.stageRoutes.splice(droppedOnRowIndex, 0, ...this.stageRoutes.splice(draggingRowIndex, 1))

      this.updatePositions()
    },

    async updatePositions() {
      this.loading = true

      for (const [index, route] of this.stageRoutes.entries()) {
        if (route.position === index) {
          continue
        }

        this.stageRoutes[index].position = index

        try {
          await this.$services.liveStageRouteService.update(route.id, {
            ...route,
            position: index,
          })
        } catch (err) {
          this.addToastMessage({
            text: 'Une erreur interne est survenue.',
            type: 'is-danger',
          })
        }
      }

      this.loading = false
    },

    async deleteRoute(route) {
      try {
        const deletedRoute = await this.$services.liveStageRouteService.delete(route.id)
        this.stageRoutes = this.stageRoutes.filter((r) => r.id !== deletedRoute.id)

        this.addToastMessage({
          text: `La route "${deletedRoute.name}" a été supprimé.`,
          type: 'is-success',
        })
      } catch (err) {
        this.addToastMessage({
          text: err.message ? err.message : 'Une erreur interne est survenue.',
          type: 'is-danger',
        })
      }
    },

    async exportGpx() {
      this.stageRoutesGpx = await this.$services.liveStageRouteService.exportGpx({
        liveStageId: this.liveStage.id,
        accessType: this.accessType,
      })
    },
  },
}
</script>
