<template>
  <edit-live-add-waypoint-form
    :loading="loading"
    :stage-routes="stageRoutes"
    :stage-waypoints="stageWaypoints"
    :live-categories="liveCategories"
    @submit="submit"
  />
</template>

<script>
import { mapActions } from 'vuex'
import EditLiveAddWaypointForm from './EdiltLiveAddWaypointForm'

export default {
  name: 'EditLiveAddWaypointTab',

  components: {
    EditLiveAddWaypointForm,
  },

  props: {
    id: {
      type: String,
      required: true,
    },
    liveStageId: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      loading: false,
      stageRoutes: { public: [], private: [] },
      stageWaypoints: [],
      liveCategories: [],
    }
  },

  async mounted() {
    this.loading = true

    await Promise.all([
      this.fetchWaypoints(),
      this.fetchLiveCategories(),
      this.fetchStageByAccessType('public'),
      this.fetchStageByAccessType('private'),
    ])

    this.loading = false
  },

  methods: {
    ...mapActions('ui', ['addToastMessage']),

    async submit(waypoint) {
      this.loading = true

      try {
        const liveStageWaypoint = await this.$services.liveStageWaypointService.create({
          liveStageId: this.liveStageId,
          ...waypoint,
        })

        this.addToastMessage({
          text: `Le waypoint "${liveStageWaypoint.name}" a été créé.`,
          type: 'is-success',
        })

        await this.$router.push({ name: 'live.edit.stage.edit', params: { tab: 1 } })
      } catch (err) {
        this.addToastMessage({
          text: 'Une erreur interne est survenue.',
          type: 'is-danger',
        })
        console.error(err)
      }

      this.loading = false
    },

    async fetchWaypoints() {
      try {
        this.stageWaypoints = await this.$services.liveStageWaypointService.getAll(this.liveStageId)
      } catch (e) {
        this.stageWaypoints = []
      }
    },

    async fetchStageByAccessType(accessType) {
      try {
        this.stageRoutes[accessType] =
          await this.$services.liveStageRouteService.getAllByAccessType(
            this.liveStageId,
            accessType,
          )
      } catch (e) {
        this.stageRoutes[accessType] = []
      }
    },

    async fetchLiveCategories() {
      try {
        this.liveCategories = await this.$services.liveCategoryService.getAll(this.id)
      } catch (e) {
        this.liveCategories = []
      }
    },
  },
}
</script>
