export const csvHeader = {
  line: 'Line',
  accessType: 'Public/Private',
  type: 'Type',
  trackerNumber: 'Device',
  number: 'Number',
  name: 'Name',
  gender: 'Gender',
  country: 'Country',
  categoryName: 'Category',
  comment: 'Comment',
}
