<template>
  <div style="display: none">
    <div ref="marker" class="o-map-drag-marker__content">
      <o-icon class="o-map-drag-marker__icon" name="map-marker-alt" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'OMapDragMarker',

  inject: ['onMap'],

  props: {
    latLng: {
      type: Array,
      default: null,
    },
  },

  mounted() {
    const { Marker, DivIcon } = require('leaflet')

    this.$divIcon = new DivIcon({
      iconSize: null,
      iconAnchor: [10, 20],
      className: 'o-map-drag-marker',
      html: this.$refs['marker'],
    })

    this.$marker = new Marker(this.latLng, {
      icon: this.$divIcon,
      draggable: true,
    })

    this.$marker.on('drag', (e) => this.$emit('drag', e))

    this.onMap.then((map) => {
      if (this.latLng.length === 0) {
        this.$marker.setLatLng(map.getBounds().getCenter())
      }

      map.addLayer(this.$marker)
    })
  },

  watch: {
    latLng(latLng) {
      if (latLng?.length > 0) {
        this.$marker.setLatLng(latLng)
      }
    },
  },

  beforeDestroy() {
    this.onMap.then((map) => {
      map.removeLayer(this.$marker)
    })
  },
}
</script>

<style lang="scss">
@import 'variables';

.o-map-drag-marker {
  &__icon {
    font-size: $font-size-xl;
  }
}
</style>
