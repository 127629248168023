<template>
  <div class="box">
    <o-table :loading="loading" :items="stagePaths" :show-total="false" :draggable="false">
      <b-table-column v-slot="{ row }" field="name" label="Nom">
        <template v-if="!showRow(row)">
          {{ row.name }}
        </template>
        <template v-else-if="showRow(row)">
          <b-field>
            <b-input v-model="name" type="text" />
          </b-field>
        </template>
      </b-table-column>
      <b-table-column v-slot="{ row }" field="liveCategoryId" label="Catégorie">
        <template v-if="!showRow(row)">
          {{ getCategoryName(row.liveCategoryId) }}
        </template>
        <template v-else>
          <b-field>
            <b-select v-model="liveCategoryId" expanded>
              <option :value="null">—</option>
              <option
                v-for="category in filteredCategories"
                :key="category.id"
                :value="category.id"
              >
                {{ category.name }}
              </option>
            </b-select>
          </b-field>
        </template>
      </b-table-column>
      <b-table-column v-slot="{ row }" field="points" label="Points" centered>
        {{ row.pathLength }}
      </b-table-column>
      <b-table-column v-slot="{ row }" label="">
        <div v-if="!showRow(row)" class="buttons are-small is-right">
          <b-button type="is-primary" @click="editPath(row)">
            <b-icon icon="edit" />
          </b-button>
          <b-button type="is-danger" @click="toggleModal(row)">
            <b-icon icon="trash" />
          </b-button>
        </div>
        <div v-else-if="showRow(row)" class="buttons are-small is-right">
          <b-button type="is-success" @click="submit">
            <b-icon icon="check" />
          </b-button>
          <b-button type="is-warning" @click="selectedPath = null">
            <b-icon icon="times" />
          </b-button>
        </div>
      </b-table-column>
      <template #empty>
        <div class="has-text-grey has-text-centered">Aucune route complète</div>
      </template>
    </o-table>
    <o-modal
      :title="modalTitle"
      :isOpen="!!selectedPath && isOpenModal"
      @closeModal="toggleModal"
      @confirmModalAction="$emit('delete', selectedPath)"
    >
      <template> La suppression d'une route complète est <strong>définitive</strong>. </template>
    </o-modal>
  </div>
</template>

<script>
import OTable from '@components/Table.vue'
import OModal from '@components/Modal.vue'

export default {
  name: 'LiveStagePathTable',

  components: {
    OModal,
    OTable,
  },

  props: {
    loading: {
      type: Boolean,
      required: true,
    },
    stagePaths: {
      type: Array,
      required: true,
    },
    liveCategories: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      name: '',
      selectedPath: null,
      liveCategoryId: null,
      isOpenModal: false,
    }
  },

  computed: {
    filteredCategories() {
      const existingCategories = this.stagePaths.map((path) => path.liveCategoryId)

      return this.liveCategories.filter((category) => {
        if (this.selectedPath && category.id === this.selectedPath.liveCategoryId) {
          return true
        }

        return !existingCategories.includes(category.id)
      })
    },

    modalTitle() {
      return `Suppression de la route ${this.selectedRoute && this.selectedRoute.name}`
    },
  },

  methods: {
    showRow(row) {
      return this.selectedPath && !this.isOpenModal && row.id === this.selectedPath.id
    },

    submit() {
      this.$emit('submit', {
        ...this.selectedPath,
        name: this.name,
        liveCategoryId: this.liveCategoryId,
      })

      this.selectedPath = null
    },

    editPath(route) {
      this.selectedPath = route
      this.name = route.name
      this.liveCategoryId = route.liveCategoryId
    },

    getCategoryName(categoryId) {
      const category = this.liveCategories.find((cat) => cat.id === categoryId)

      return category?.name || '—'
    },

    toggleModal(path = null) {
      this.isOpenModal = !!path
      this.selectedPath = path
    },
  },
}
</script>
