<template>
  <page-layout :title="title">
    <template v-slot:breadcrumb>
      <li><router-link :to="{ name: 'home' }">Dashboard</router-link></li>
      <li><router-link :to="{ name: 'live.list' }">Lives</router-link></li>
      <li class="is-active">
        <a href="#">{{ title }}</a>
      </li>
    </template>
    <div class="box is-6">
      <live-setup-form />
    </div>
  </page-layout>
</template>

<script>
import PageLayout from '@components/PageLayout.vue'
import LiveSetupForm from './LiveSetupForm.vue'

export default {
  name: 'LiveSetupFormPage',

  components: {
    PageLayout,
    LiveSetupForm,
  },

  data() {
    return {
      title: 'Importer un live depuis Jotform',
    }
  },
}
</script>
