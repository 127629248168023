import { capitalize } from '../../../helpers/capitalize.js'

export const getInvalidSubmissionErrorMessages = (submission) => {
  return getInvalidSubmissionValues(submission).map(
    (value) =>
      `${capitalize(Object.keys(value).toString())} : missing value ${Object.values(value).join(
        ', ',
      )}`,
  )
}

const getInvalidSubmissionValues = (submission) => {
  const invalidSubmissionValues = []

  const live = filterInvalidValues(Object.keys(submission['live']), submission['live'])
  if (live.length > 0) {
    invalidSubmissionValues.push({ live })
  }

  const settings = filterInvalidValues(Object.keys(submission['settings']), submission['settings'])
  if (settings.length > 0) {
    invalidSubmissionValues.push({ settings })
  }

  const stages = submission['stages'].reduce(reducer(), {})
  if (Object.values(stages).length > 0) {
    Object.keys(stages).map((key) =>
      invalidSubmissionValues.push({
        [`stage ${parseInt(key) + 1}`]: stages[key],
      }),
    )
  }

  const categories = submission['categories'].reduce(reducer(), {})
  if (Object.values(categories).length > 0) {
    Object.keys(categories).map((key) =>
      invalidSubmissionValues.push({
        [`category ${parseInt(key) + 1}`]: categories[key],
      }),
    )
  }

  const alertContacts = submission['alertContacts'].reduce(reducer(['email', 'phoneNumber']), {})
  if (Object.values(alertContacts).length > 0) {
    Object.keys(alertContacts).map((key) =>
      invalidSubmissionValues.push({
        [`contact ${parseInt(key) + 1}`]: alertContacts[key],
      }),
    )
  }

  return invalidSubmissionValues
}

const filterInvalidValues = (keys, object, excludedKeys = []) => {
  return keys.filter(
    (key) => !excludedKeys.includes(key) && (object[key] === null || object[key].length === 0),
  )
}

const reducer =
  (excludedKeys = []) =>
  (acc, curr, index) => {
    const invalidValues = filterInvalidValues(Object.keys(curr), curr, excludedKeys)
    if (invalidValues.length > 0) {
      return { ...acc, [index]: invalidValues }
    }

    return acc
  }
