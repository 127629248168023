<template>
  <div>
    <o-table
      :items="liveSponsors"
      :loading="loading"
      :draggable="!selectedSponsor"
      @drop="
        (droppedOnRowIndex, draggingRowIndex) => $emit('drop', droppedOnRowIndex, draggingRowIndex)
      "
    >
      <template>
        <b-table-column v-slot="{ row }" field="name" label="Nom">
          <template v-if="!showRow(row)">
            {{ row.name }}
          </template>
          <template v-else-if="showRow(row)">
            <b-field
              :message="fields.name.error"
              :type="fields.name.error !== null ? 'is-danger' : ''"
            >
              <b-input
                v-model="fields.name.value"
                expanded
                placeholder="Nom du sponsor"
                type="text"
                :icon-right="fields.name.value ? 'close-circle' : ''"
                :icon-right-clickable="!!fields.name.value"
                @icon-right-click="fields.name.value = null"
              />
            </b-field>
          </template>
        </b-table-column>
        <b-table-column v-slot="{ row }" field="websiteUrl" label="Website">
          <template v-if="!showRow(row)">
            {{ row.websiteUrl }}
          </template>
          <template v-else-if="showRow(row)">
            <b-field
              :message="fields.websiteUrl.error"
              :type="{ 'is-danger': fields.websiteUrl.error }"
            >
              <b-input
                v-model="fields.websiteUrl.value"
                expanded
                placeholder="https://example.com"
                type="url"
                :icon-right="fields.websiteUrl.value ? 'close-circle' : ''"
                :icon-right-clickable="!!fields.websiteUrl.value"
                @icon-right-click="fields.websiteUrl.value = null"
              />
            </b-field>
          </template>
        </b-table-column>
        <b-table-column v-slot="{ row }" field="imageUrl" label="Image (360x360)" width="20%">
          <template v-if="!showRow(row)">
            <img :src="`${row.imageUrl}?${timestamp}`" class="image" width="100px" />
          </template>
          <template v-else-if="showRow(row)">
            <drag-drop
              v-model="fields.imageFile.value"
              :errorMessage="fields.imageFile.error"
              :loading="loading"
            />
          </template>
        </b-table-column>
        <b-table-column v-slot="{ row }" label="" width="100">
          <div v-if="!showRow(row)" class="buttons are-small is-right">
            <b-button type="is-primary" @click="selectedSponsor = row">
              <b-icon icon="edit" />
            </b-button>
            <b-button type="is-danger" :loading="loading" @click="toggleModal(row)">
              <b-icon icon="trash" />
            </b-button>
          </div>
          <div v-else-if="showRow(row)" class="buttons are-small is-right">
            <b-button
              type="is-success"
              :loading="loading"
              :disabled="isFormInvalid"
              @click="submit"
            >
              <b-icon icon="check" />
            </b-button>
            <b-button type="is-warning" @click="selectedSponsor = null">
              <b-icon icon="times" />
            </b-button>
          </div>
        </b-table-column>
      </template>
    </o-table>
    <o-modal
      :title="modalTitle"
      :isOpen="!!selectedSponsor && isOpenModal"
      @closeModal="toggleModal"
      @confirmModalAction="deleteSponsor"
    >
      <template> La suppression d'un sponsor est <strong>définitif</strong>. </template>
    </o-modal>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { isURL } from '@helpers/isURL.js'
import { checkFileType } from '@helpers/checkFileType.js'
import { convertFileToString } from '@helpers/convertFileToString.js'
import OTable from '@components/Table.vue'
import OModal from '@components/Modal.vue'
import DragDrop from '@components/DragDrop.vue'

export default {
  name: 'LiveSponsorTable',

  components: {
    OTable,
    OModal,
    DragDrop,
  },

  props: {
    liveSponsors: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      loading: false,
      isOpenModal: false,
      selectedSponsor: null,
      timestamp: new Date().getTime(),
      fields: {
        name: {
          value: null,
          error: null,
        },
        websiteUrl: {
          value: null,
          error: null,
        },
        imageFile: {
          value: null,
          error: null,
        },
      },
    }
  },

  created() {
    this.imageType = 'image/jpeg, image/png'
  },

  watch: {
    selectedSponsor(sponsor) {
      if (!sponsor) {
        this.resetFields()
        return
      }

      this.fields.name.value = sponsor.name
      this.fields.websiteUrl.value = sponsor.websiteUrl
    },

    'fields.websiteUrl.value'(websiteUrl) {
      this.fields.websiteUrl.error =
        websiteUrl && websiteUrl.length > 0 && !isURL(websiteUrl) ? 'Url incorrecte' : null
    },

    'fields.imageFile.value'(imageFile) {
      if (imageFile) {
        this.fields.imageFile.error = checkFileType(imageFile, this.imageType)
      }
    },
  },

  computed: {
    isFormInvalid() {
      return Object.values(this.fields).find((field) => field.error !== null) || this.loading
    },

    modalTitle() {
      return `Suppression du sponsor ${this.selectedSponsor && this.selectedSponsor.position + 1}`
    },
  },

  methods: {
    ...mapActions('ui', ['addToastMessage']),

    async submit() {
      this.loading = true

      try {
        const sponsorImage = this.fields.imageFile.value
          ? await convertFileToString(this.fields.imageFile.value)
          : null

        const updatedLiveSponsor = await this.$services.liveSponsorService.update({
          ...this.selectedSponsor,
          name: this.fields.name.value,
          websiteUrl: this.fields.websiteUrl.value,
          image: sponsorImage,
        })

        this.selectedSponsor = null
        this.timestamp = new Date().getTime()
        this.$emit('submit', updatedLiveSponsor)

        this.addToastMessage({
          text: 'Le sponsor a été modifié.',
          type: 'is-success',
        })
      } catch (err) {
        this.addToastMessage({
          text: 'Une erreur interne est survenue.',
          type: 'is-danger',
        })
      }

      this.loading = false
    },

    async deleteSponsor() {
      this.loading = true

      try {
        const deletedSponsor = await this.$services.liveSponsorService.delete(
          this.selectedSponsor.id,
        )

        this.selectedSponsor = null
        this.$emit('delete', deletedSponsor)

        this.addToastMessage({
          text: 'Le sponsor a été supprimé.',
          type: 'is-success',
        })
      } catch (err) {
        this.addToastMessage({
          text: 'Une erreur interne est survenue.',
          type: 'is-danger',
        })
      }

      this.loading = false
    },

    resetFields() {
      Object.values(this.fields).forEach((field) => {
        field.value = null
        field.error = null
      })
    },

    showRow(row) {
      return this.selectedSponsor && !this.isOpenModal && row.id === this.selectedSponsor.id
    },

    toggleModal(liveSponsor = null) {
      this.isOpenModal = !this.isOpenModal
      this.selectedSponsor = liveSponsor
    },
  },
}
</script>
