export const iconNames = [
  'ambulance',
  'ban',
  'battery-half',
  'bed',
  'beer',
  'bell',
  'bicycle',
  'broom',
  'bolt',
  'camera',
  'campground',
  'car',
  'chart-area',
  'check',
  'circle',
  'clipboard-check',
  'clock',
  'comments',
  'comment-medical',
  'envelope',
  'eye',
  'eye-slash',
  'feather',
  'flag',
  'flag-checkered',
  'gas-pump',
  'globe',
  'heartbeat',
  'hiking',
  'home',
  'key',
  'map-marker',
  'map-marker-alt',
  'map-pin',
  'motorcycle',
  'mountain',
  'plane',
  'play',
  'pause',
  'route',
  'star',
  'search',
  'tachometer',
  'tag',
  'thumbs-up',
  'times',
  'times-circle',
  'tools',
  'truck',
  'truck-pickup',
  'undo',
  'user',
  'user-md',
  'user-lock',
  'user-slash',
  'user-check',
  'users',
  'utensils',
  'walking',
  'wrench',
  'plus',
  'minus',
  'map',
  'trash',
  'ruler',
  'location-arrow',
  'ssv',
  'quad',
  'arrow-left',
  'arrow-right',
  'lock',
  'facebook',
  'instagram',
  'twitter',
  'youtube',
  'link',
  'external-link-alt',
  'exclamation-triangle',
  'info-circle',
  'venus',
  'mars',
  'venus-mars',
  'arrow-down',
  'arrow-up',
  'arrow-trend-up',
  'calendar',
  'stopwatch',
  'character-a',
  'character-b',
  'character-c',
  'character-d',
  'character-e',
  'character-f',
  'character-g',
  'character-h',
  'character-i',
  'character-j',
  'character-k',
  'character-l',
  'character-m',
  'character-n',
  'character-o',
  'character-p',
  'character-q',
  'character-r',
  'character-s',
  'character-t',
  'character-u',
  'character-v',
  'character-w',
  'character-x',
  'character-y',
  'character-z',
  'character-0',
  'character-1',
  'character-2',
  'character-3',
  'character-4',
  'character-5',
  'character-6',
  'character-7',
  'character-8',
  'character-9',
  'dog',
  'ship',
  'linkedin',
]
