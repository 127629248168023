<template>
  <form :novalidate="true" class="box" @submit.prevent="submit">
    <div class="columns is-multiline">
      <b-field
        label="Nom*"
        :message="fields.name.error"
        :type="fields.name.error !== null ? 'is-danger' : ''"
        class="column is-full-mobile"
      >
        <b-input v-model="fields.name.value" type="text" />
      </b-field>
      <b-field
        label="Label"
        :message="fields.label.error"
        :type="fields.label.error !== null ? 'is-danger' : ''"
        class="column is-full-mobile"
      >
        <b-input
          v-model="fields.label.value"
          type="text"
          maxlength="6"
          :icon-right="fields.label.value ? 'close-circle' : ''"
          :icon-right-clickable="!!fields.label.value"
          @icon-right-click="fields.label.value = null"
        />
      </b-field>
    </div>
    <div class="columns is-multiline">
      <b-field
        label="Color*"
        :message="fields.color.error"
        :type="fields.color.error !== null ? 'is-danger' : ''"
        class="column is-full-mobile"
      >
        <o-dropdown
          v-model="fields.color.value"
          :items="colors"
          @input="(value) => (fields.color.value = value)"
        />
      </b-field>
      <b-field
        label="Icône*"
        :message="fields.icon.error"
        :type="fields.icon.error !== null ? 'is-danger' : ''"
        class="column is-full-mobile"
      >
        <o-icon-picker v-model="fields.icon.value" :icons="icons" />
      </b-field>
    </div>
    <div class="columns is-multiline">
      <b-field
        label="Format de coordonnées"
        :message="fields.coordinateFormat.error"
        :type="fields.coordinateFormat.error !== null ? 'is-danger' : ''"
        class="column is-full-mobile"
      >
        <b-select
          v-model="fields.coordinateFormat.value"
          expanded
          placeholder="Choisissez un format de coordonnées"
          icon="map-marker-alt"
        >
          <option v-for="format in coordinateFormats" :key="format.value" :value="format.value">
            {{ format.name }}{{ format.example }}
          </option>
        </b-select>
      </b-field>
      <b-field
        label="Localisation*"
        :message="fields.location.error"
        :type="fields.location.error !== null ? 'is-danger' : ''"
        class="column is-full-mobile"
      >
        <b-input :value="fields.location.value" type="text" lazy @input="inputLocation" />
      </b-field>
    </div>
    <b-field message="Déplacez le marker gris sur la carte pour enregistrer les coordonnées">
      <live-stage-map
        ref="liveStageMap"
        class="card mb-3"
        :stage-routes="stageRoutes"
        :stage-waypoints="stageWaypoints"
        :route-opacity="0.5"
        :waypoint-opacity="0.5"
        :center="mapCenter"
      >
        <o-map-drag-marker v-if="!loading" :lat-lng="latLng" @drag="drag" />
      </live-stage-map>
    </b-field>
    <div class="columns is-multiline">
      <b-field
        label="Rayon"
        :message="fields.radius.error"
        :type="fields.radius.error !== null ? 'is-danger' : ''"
        class="column is-full-mobile"
      >
        <o-dropdown
          v-model="fields.radius.value"
          :items="radiuses"
          @input="(value) => (fields.radius.value = value)"
        >
          <template v-slot:label="{ label }">
            {{ radius(label) }}
          </template>
          <template v-slot:item="{ item }">
            {{ radius(item) }}
          </template>
        </o-dropdown>
      </b-field>
      <b-field
        v-show="fields.radius.value"
        label="Role"
        :message="fields.role.error"
        :type="fields.role.error !== null ? 'is-danger' : ''"
        class="column is-full-mobile"
      >
        <o-dropdown v-model="fields.role.value" :items="roles" @input="fields.role.value = $event">
          <template v-slot:label="{ label }">
            {{ role(label) }}
          </template>
          <template v-slot:item="{ item }">
            {{ role(item) }}
          </template>
        </o-dropdown>
      </b-field>
      <b-field
        v-show="fields.radius.value"
        label="Catégorie de validation"
        class="column is-full-mobile"
      >
        <b-select v-model="fields.liveCategoryId.value" expanded>
          <option :value="null">—</option>
          <option v-for="category in filteredCategories" :key="category.id" :value="category.id">
            {{ category.name }}
          </option>
        </b-select>
      </b-field>
    </div>
    <b-field
      label="Type d'accès"
      :message="fields.accessType.error"
      :type="fields.accessType.error !== null ? 'is-danger' : ''"
    >
      <b-field>
        <b-radio-button v-model="fields.accessType.value" native-value="public">
          <span>Public</span>
        </b-radio-button>
        <b-radio-button v-model="fields.accessType.value" native-value="private">
          <span>Privé</span>
        </b-radio-button>
      </b-field>
    </b-field>
    <hr />
    <b-field class="buttons">
      <b-button native-type="submit" type="is-primary" :loading="loading" label="Ajouter" />
      <b-button
        tag="router-link"
        :to="{ name: 'live.edit.stage.edit', params: { tab: 1 } }"
        type="is-danger"
        label="Annuler"
      />
    </b-field>
  </form>
</template>

<script>
import { mapActions } from 'vuex'
import LiveStageMap from '@views/MapView/LiveStageMap'
import ODropdown from '@components/Dropdown'
import OIconPicker from '@components/IconPicker'
import OMapDragMarker from '@components/Map/MapDragMarker'
import { iconNames } from '@data/icons'
import { COLORS } from '@constants/waypoint/color'
import { RADIUSES } from '@constants/waypoint/radiuses'
import { COORDINATE_FORMATS } from '@constants/settings/coordinate'
import { FORMAT_DD, FORMAT_DDM, FORMAT_DMS, formatLatLon } from '@filters/coordinate'
import { dmsToLatLng } from '@filters/dmsToLatLng'
import { ddmToLatLng } from '@filters/ddmToLatLng'
import { WAYPOINT_ROLE_FINISH, WAYPOINT_ROLE_START } from '@constants/waypoint/role'

export default {
  name: 'EditLiveAddWaypointForm',

  components: {
    LiveStageMap,
    ODropdown,
    OIconPicker,
    OMapDragMarker,
  },

  props: {
    loading: {
      type: Boolean,
      required: true,
    },
    stageRoutes: {
      type: Object,
      default: () => ({ public: [], private: [] }),
      validator: (v) => ['public', 'private'].every((key) => key in v),
    },
    stageWaypoints: {
      type: Array,
      default: () => [],
    },
    liveCategories: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      mapCenter: [0, 0],
      latLng: [],
      fields: {
        name: {
          value: '',
          error: null,
        },
        icon: {
          value: '',
          error: null,
        },
        color: {
          value: '',
          error: null,
        },
        location: {
          value: '',
          error: null,
        },
        accessType: {
          value: 'public',
          error: null,
        },
        radius: {
          value: '',
          error: null,
        },
        role: {
          value: null,
          error: null,
        },
        label: {
          value: '',
          error: null,
        },
        coordinateFormat: {
          value: FORMAT_DMS,
          error: null,
        },
        liveCategoryId: {
          value: null,
          error: null,
        },
      },
    }
  },

  created() {
    this.colors = COLORS
    this.icons = iconNames
    this.radiuses = [null, ...RADIUSES]
    this.roles = [null, WAYPOINT_ROLE_START, WAYPOINT_ROLE_FINISH]
    this.coordinateFormats = COORDINATE_FORMATS
  },

  watch: {
    'fields.name.value'() {
      this.fields.name.error = this.fieldNameError
    },

    'fields.color.value'() {
      this.fields.color.error = this.fieldColorError
    },

    'fields.icon.value'() {
      this.fields.icon.error = this.fieldIconError
    },

    'fields.location.value'() {
      this.fields.location.error = this.fieldLocationError
    },

    'fields.coordinateFormat.value'() {
      if (this.latLng?.length > 0) {
        this.fields.location.value = this.fieldLocationValue
      }

      if (this.validLatLng) {
        this.latLng = this.validLatLng
        this.mapCenter = this.validLatLng
      }

      this.fields.location.error = this.fieldLocationError
    },

    'fields.radius.value'(radius) {
      if (!radius) {
        this.fields.role.value = null
        this.fields.liveCategoryId.value = null
      }
    },

    fieldRoleError(error) {
      this.fields.role.error = error
    },
  },

  computed: {
    isFormValid() {
      return !Object.values(this.fields).find((field) => field.error !== null) && !!this.validLatLng
    },

    validLatLng() {
      if (this.fields.coordinateFormat.value === FORMAT_DDM) {
        return ddmToLatLng(this.fields.location.value)
      }

      if (this.fields.coordinateFormat.value === FORMAT_DMS) {
        return dmsToLatLng(this.fields.location.value)
      }

      const latLng = this.fields.location.value.split(' ')
      if (latLng.length !== 2 || latLng.find((number) => isNaN(+number))) {
        return null
      }

      return this.fields.location.value.split(' ').map((number) => +number)
    },

    fieldNameError() {
      return this.fields.name.value?.length > 0 ? null : 'Le nom est requis'
    },

    fieldColorError() {
      return this.fields.color.value?.length > 0 ? null : 'La couleur est requise'
    },

    fieldIconError() {
      return this.fields.icon.value?.length > 0 ? null : "L'icône est requise"
    },

    fieldLocationError() {
      return this.fields.location.value?.length > 0 && !!this.validLatLng
        ? null
        : this.fields.location.value?.length > 0 && !this.validLatLng
        ? 'Mauvais format'
        : 'La Localisation est requise'
    },

    fieldLocationValue() {
      return this.fields.coordinateFormat.value === FORMAT_DD
        ? this.latLng.join(' ')
        : formatLatLon(this.latLng, this.fields.coordinateFormat.value).join(' ')
    },

    fieldRoleError() {
      const waypointWithSameRole = this.stageWaypoints.find((waypoint) => {
        const condition = waypoint.role && waypoint.role === this.fields.role.value
        const optionalCondition =
          waypoint.liveCategoryId === this.fields.liveCategoryId.value ||
          waypoint.liveCategoryId === null

        return this.fields.liveCategoryId.value ? condition && optionalCondition : condition
      })

      return waypointWithSameRole
        ? `Rôle déjà attribué au waypoint ${waypointWithSameRole.name}`
        : null
    },

    filteredCategories() {
      return this.liveCategories.filter((category) => category.useCheckpoint)
    },
  },

  methods: {
    ...mapActions('ui', ['addToastMessage']),

    setFormErrors() {
      this.fields.name.error = this.fieldNameError
      this.fields.color.error = this.fieldColorError
      this.fields.icon.error = this.fieldIconError
      this.fields.location.error = this.fieldLocationError
    },

    submit() {
      if (!this.isFormValid) {
        this.setFormErrors()
        return
      }

      this.$emit('submit', {
        name: this.fields.name.value,
        icon: this.fields.icon.value,
        color: this.fields.color.value,
        accessType: this.fields.accessType.value,
        location: {
          latitude: this.latLng[0],
          longitude: this.latLng[1],
        },
        radius: this.fields.radius.value > 0 ? this.fields.radius.value : null,
        role: this.fields.role.value,
        label: this.fields.label.value.length > 0 ? this.fields.label.value : null,
        liveCategoryId: this.fields.liveCategoryId.value,
      })
    },

    radius(radius) {
      return radius ? `${radius}m` : '—'
    },

    role(role) {
      return role ? role : '—'
    },

    drag(marker) {
      this.latLng = [marker.latlng.lat, marker.latlng.lng]
      this.fields.location.value = this.fieldLocationValue
    },

    inputLocation(input) {
      this.fields.location.value = input.trim()

      if (!this.validLatLng) {
        this.latLng = []
        return
      }

      this.latLng = this.validLatLng
      this.mapCenter = this.validLatLng
    },
  },
}
</script>
