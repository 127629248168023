<template>
  <div style="display: none"></div>
</template>

<script>
import { format } from 'date-fns'

export default {
  name: 'EditLiveReviewTrackerMarkers',

  props: {
    tracker: {
      type: Object,
      required: true,
      validator: (v) => ['id', 'name', 'color', 'locations'].every((key) => key in v),
    },
  },

  data() {
    return {
      featureGroup: null,
    }
  },

  inject: ['onMap'],

  watch: {
    'tracker.locations': {
      async handler() {
        const { FeatureGroup, CircleMarker } = require('leaflet')
        const map = await this.onMap

        if (this.featureGroup) {
          this.featureGroup.clearLayers()
        }

        if (!this.featureGroup) {
          this.featureGroup = new FeatureGroup()
          map.addLayer(this.featureGroup)
        }

        for (const location of this.tracker.locations) {
          const circleMarker = new CircleMarker(location.location, {
            weight: 3,
            stroke: true,
            fillOpacity: 1,
            color:
              (location.createdAt - location.locatedAt) / 1000 > 300
                ? '#ce2819'
                : this.tracker.color,
            fillColor: this.tracker.color,
            radius: 7,
            pane: 'markerPane',
          })

          circleMarker.bindTooltip(
            `
            Tracker: ${this.tracker.name}<br>
            Battery: ${location.batteryState || location.batteryPercentage}<br>
            Located at: ${format(location.locatedAt, 'dd/MM/yyyy HH:mm:ss')}<br>
            Created at: ${format(location.createdAt, 'dd/MM/yyyy HH:mm:ss')}
          `,
            {
              direction: 'top',
            },
          )

          this.featureGroup.addLayer(circleMarker)
        }

        if (this.featureGroup.getBounds().isValid()) {
          map.panTo(this.featureGroup.getBounds().getCenter())
          map.fitBounds(this.featureGroup.getBounds())
        }
      },
      immediate: true,
    },
  },

  async beforeDestroy() {
    if (this.featureGroup) {
      this.featureGroup.clearLayers()
      const map = await this.onMap
      map.removeLayer(this.featureGroup)
    }
  },
}
</script>
