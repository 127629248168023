<template>
  <b-field
    :message="hasError ? 'Au moins un champ d\'origine (FR) est vide' : ''"
    :type="hasError ? 'is-danger' : ''"
    class="column is-full"
  >
    <b-button
      label="Traduire depuis FR"
      type="is-primary"
      :loading="loading"
      :disabled="loading || hasError"
      @click="translate"
    />
  </b-field>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'LiveTutorialFieldsTranslation',

  props: {
    toLocale: {
      type: String,
      required: true,
    },
    fromLocale: {
      type: String,
      default: 'fr',
    },
    fromFields: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      loading: false,
    }
  },

  computed: {
    hasError() {
      return Object.values(this.fromFields).some((value) => value === null || value.length === 0)
    },
  },

  methods: {
    ...mapActions('ui', ['addToastMessage']),

    async translate() {
      if (this.toLocale === this.fromLocale) {
        return
      }

      this.loading = true

      try {
        const translations = {}
        for (let key of Object.keys(this.fromFields)) {
          const response = await this.$services.translationService.translate({
            value: this.fromFields[key],
            fromLocale: this.fromLocale,
            toLocale: this.toLocale,
          })

          translations[key] = response.value
        }

        this.$emit('translate', { locale: this.toLocale, translations })
      } catch (err) {
        this.addToastMessage({
          text: err.message ? err.message : 'Une erreur interne est survenue.',
          type: 'is-danger',
        })
        console.error(err)
      }

      this.loading = false
    },
  },
}
</script>
