<template>
  <b-button
    type="is-outlined"
    class="has-background-light"
    icon-left="file-export"
    :label="label"
    :disabled="disabled"
    @click="$emit('export')"
  />
</template>

<script>
import { downloadFile } from '@helpers/downloadFile'

export default {
  name: 'OExportButton',

  props: {
    disabled: {
      type: Boolean,
      required: true,
    },
    file: {
      type: String,
      default: null,
    },
    fileType: {
      type: String,
      required: true,
      validator: (v) => ['gpx', 'csv'].indexOf(v) !== -1,
    },
    fileName: {
      type: String,
      required: true,
    },
  },

  watch: {
    file(file) {
      if (file !== null) {
        downloadFile(this.file, this.fileType, this.fileName)
        this.$emit('exported')
      }
    },
  },

  computed: {
    label() {
      return `Export ${this.fileType.toUpperCase()}`
    },
  },
}
</script>
