<template>
  <b-steps
    v-model="activeStep"
    label-position="right"
    size="is-small"
    mobile-mode="compact"
    :has-navigation="false"
  >
    <b-step-item
      v-for="step in steps"
      :key="step.label"
      :label="step.label"
      :type="step.type"
      :icon="step.icon"
      :clickable="step.isClickable"
    >
      <template v-if="Array.isArray(step.prop)">
        <div v-for="prop in step.prop" :key="prop.id">
          <b-icon type="is-success" icon="check" />
          {{ step.formatSuccessMessage(prop) }}
        </div>
        <template v-if="step.showErrorMessage">
          <div v-for="(errorMessage, index) in step.errorMessages" :key="index">
            <b-icon type="is-danger" icon="times" />
            {{ step.formatErrorMessage(index, errorMessage) }}
          </div>
        </template>
      </template>
      <template v-else>
        <div v-if="step.showSuccessMessage">
          <b-icon type="is-success" icon="check" />
          {{ step.formatSuccessMessage }}
        </div>
        <div v-else-if="step.showErrorMessage">
          <b-icon type="is-danger" icon="times" />
          {{ step.formatErrorMessage }}
        </div>
      </template>
    </b-step-item>
  </b-steps>
</template>

<script>
import { formatLiveSetupStep } from './formatLiveSetupStep.js'

export default {
  name: 'LiveSetupStep',

  props: {
    errorMessages: {
      type: Object,
      default: null,
    },
    live: {
      type: Object,
      default: null,
    },
    liveSettings: {
      type: Object,
      default: null,
    },
    liveStages: {
      type: Array,
      default: () => [],
    },
    liveCategories: {
      type: Array,
      default: () => [],
    },
    liveAlertContacts: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      activeStep: 0,
    }
  },

  watch: {
    live() {
      this.activeStep = 0
    },

    liveSettings(liveSettings) {
      if (liveSettings || this.errorMessages?.liveSettings) {
        this.activeStep = 1
      }
    },

    liveStages(liveStages) {
      if (liveStages.length > 0 || this.errorMessages?.liveStages) {
        this.activeStep = 2
      }
    },

    liveCategories(liveCategories) {
      if (liveCategories.length > 0 || this.errorMessages?.liveCategories) {
        this.activeStep = 3
      }
    },

    liveAlertContacts(liveAlertContacts) {
      if (liveAlertContacts.length > 0 || this.errorMessages?.liveAlertContacts) {
        this.activeStep = 4
      }
    },
  },

  computed: {
    steps() {
      return [
        this.liveStep,
        this.liveSettingsStep,
        this.liveStagesStep,
        this.liveCategoriesStep,
        this.liveAlertContactsStep,
      ]
    },

    liveStep() {
      const formatErrorMessage = `Échec de la création du live: ${this.errorMessages?.live}`
      const formatSuccessMessage = `Le live "${this.live?.name}" a été ajouté`

      return formatLiveSetupStep(
        'Live',
        this.live,
        this.errorMessages?.live,
        formatErrorMessage,
        formatSuccessMessage,
      )
    },

    liveSettingsStep() {
      const formatErrorMessage = `Échec de la création des settings: ${this.errorMessages?.liveSettings}`
      const formatSuccessMessage = `Les settings ont été ajoutés`

      return formatLiveSetupStep(
        'Settings',
        this.liveSettings,
        this.errorMessages?.liveSettings,
        formatErrorMessage,
        formatSuccessMessage,
      )
    },

    liveStagesStep() {
      const formatErrorMessage = (index, error) =>
        `Échec de la création de l'étape ${parseInt(index) + 1}: ${error}`
      const formatSuccessMessage = ({ name }) => `L'étape "${name}" a été ajoutée`

      return formatLiveSetupStep(
        'Étapes',
        this.liveStages,
        this.errorMessages?.liveStages,
        formatErrorMessage,
        formatSuccessMessage,
      )
    },

    liveCategoriesStep() {
      const formatErrorMessage = (index, error) =>
        `Échec de la création de l'étape ${parseInt(index) + 1}: ${error}`
      const formatSuccessMessage = ({ name }) => `La catégorie "${name}" a été ajoutée`

      return formatLiveSetupStep(
        'Catégories',
        this.liveCategories,
        this.errorMessages?.liveCategories,
        formatErrorMessage,
        formatSuccessMessage,
      )
    },

    liveAlertContactsStep() {
      const formatErrorMessage = (index, error) =>
        `Échec de la création du contact ${parseInt(index) + 1}: ${error}`
      const formatSuccessMessage = ({ name }) => `Le contact "${name}" a été ajouté`

      return formatLiveSetupStep(
        'Alertes',
        this.liveAlertContacts,
        this.errorMessages?.liveAlertContacts,
        formatErrorMessage,
        formatSuccessMessage,
      )
    },
  },
}
</script>
