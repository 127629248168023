<template>
  <live-tutorial-form :loading="loading" @submit="submit" />
</template>

<script>
import { mapActions } from 'vuex'
import LiveTutorialForm from '@views/LiveTutorial/LiveTutorialForm.vue'

export default {
  name: 'AddLiveTutorial',

  components: {
    LiveTutorialForm,
  },

  data() {
    return {
      loading: false,
    }
  },

  methods: {
    ...mapActions('ui', ['addToastMessage']),

    async submit(liveTutorial) {
      this.loading = true

      try {
        await this.$services.liveTutorialService.create(liveTutorial)

        this.addToastMessage({
          text: `Le tutoriel a été ajouté.`,
          type: 'is-success',
        })

        this.$router.push({ name: 'tutorial.list' })
      } catch (err) {
        this.addToastMessage({
          text: 'Une erreur interne est survenue.',
          type: 'is-danger',
        })
        console.error(err)
      }

      this.loading = false
    },
  },
}
</script>
