<template>
  <div class="columns is-multiline">
    <b-field
      label="Titre*"
      :message="fields.title.error"
      :type="fields.title.error !== null ? 'is-danger' : ''"
      class="column is-full-mobile is-6"
    >
      <b-input
        :value="fields.title.value"
        expanded
        type="text"
        @blur="updateField('title', { error: fieldTitleError })"
        @input="updateField('title', { value: $event })"
      />
    </b-field>
    <b-field
      label="Url de la vidéo*"
      :message="fields.videoUrl.error"
      :type="fields.videoUrl.error !== null ? 'is-danger' : ''"
      class="column is-full-mobile is-6"
    >
      <b-input
        :value="fields.videoUrl.value"
        expanded
        type="text"
        @blur="updateField('videoUrl', { error: fieldVideoUrlError })"
        @input="updateField('videoUrl', { value: $event })"
      />
    </b-field>
    <b-field
      label="Résumé*"
      :message="fields.summary.error"
      :type="fields.summary.error !== null ? 'is-danger' : ''"
      class="column is-full"
    >
      <b-input
        :value="fields.summary.value"
        expanded
        type="textarea"
        @blur="updateField('summary', { error: fieldSummaryError })"
        @input="updateField('summary', { value: $event })"
      />
    </b-field>
    <b-field
      label="Description*"
      :message="fields.description.error"
      :type="fields.description.error !== null ? 'is-danger' : ''"
      class="column is-full"
    >
      <b-input
        :value="fields.description.value"
        expanded
        type="textarea"
        @blur="updateField('description', { error: fieldDescriptionError })"
        @input="updateField('description', { value: $event })"
      />
    </b-field>
  </div>
</template>

<script>
import { isURL } from '@helpers/isURL'

export default {
  name: 'LiveTutorialFormFields',

  props: {
    fields: {
      type: Object,
      required: true,
      validator: (v) =>
        ['title', 'videoUrl', 'description', 'summary'].every((key) => key in v) &&
        Object.values(v).every((obj) => ['value', 'error'].every((key) => key in obj)),
    },
  },

  watch: {
    'fields.title.value'() {
      this.updateField('title', { error: this.fieldTitleError })
    },

    'fields.videoUrl.value'() {
      this.updateField('videoUrl', { error: this.fieldVideoUrlError })
    },

    'fields.summary.value'() {
      this.updateField('summary', { error: this.fieldSummaryError })
    },

    'fields.description.value'() {
      this.updateField('description', { error: this.fieldDescriptionError })
    },
  },

  computed: {
    fieldTitleError() {
      return this.fields.title.value?.length > 0 ? null : 'Titre requis'
    },

    fieldVideoUrlError() {
      return this.fields.videoUrl.value?.length > 0
        ? isURL(this.fields.videoUrl.value)
          ? null
          : 'Url incorrecte'
        : 'Url requise'
    },

    fieldSummaryError() {
      return this.fields.summary.value?.length > 0 ? null : 'Résumé requis'
    },

    fieldDescriptionError() {
      return this.fields.description.value?.length > 0 ? null : 'Description requise'
    },
  },

  methods: {
    updateField(key, value) {
      this.$emit('updateField', { key, field: { ...this.fields[key], ...value } })
    },
  },
}
</script>
