<template>
  <div>
    <b-field label="Profil du live">
      <b-select v-model="selectedSpotTrackerProfileId" expanded :disabled="disableUpdate">
        <option :value="null" disabled>Choisir un profil</option>
        <option
          v-for="spotTrackerProfile in spotTrackerProfiles"
          :key="spotTrackerProfile.id"
          :value="spotTrackerProfile.id"
        >
          {{ spotTrackerProfile.name }}
        </option>
      </b-select>
      <p class="control">
        <b-button
          :type="action.type"
          :loading="loading"
          :disabled="action.disabled"
          @click="action.handler"
        >
          {{ action.label }}
        </b-button>
      </p>
      <p
        v-if="liveSpotTrackerConfig && selectedSpotTrackerProfileId && !disableUpdate"
        class="control"
      >
        <b-button type="is-danger" @click="disableUpdate = true"> Annuler </b-button>
      </p>
    </b-field>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'ListSpotTrackerProfiles',

  components: {},

  props: {
    live: {
      type: Object,
      required: true,
    },
    liveSpotTrackerConfig: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      loading: false,
      spotTrackerProfiles: [],
      selectedSpotTrackerProfileId: null,
      disableUpdate: false,
    }
  },

  async mounted() {
    await this.fetchSpotTrackerProfiles()
  },

  watch: {
    liveSpotTrackerConfig: {
      handler(liveSpotTrackerConfig) {
        if (liveSpotTrackerConfig) {
          this.disableUpdate = true
          this.selectedSpotTrackerProfileId = this.liveSpotTrackerConfig.profileId
        }
      },
      immediate: true,
    },
  },

  computed: {
    action() {
      return this.disableUpdate
        ? {
            type: 'is-danger',
            disabled: false,
            label: 'Modifier',
            handler: () => {
              this.disableUpdate = false
            },
          }
        : {
            type: 'is-primary',
            disabled:
              this.loading ||
              !this.selectedSpotTrackerProfileId ||
              this.selectedSpotTrackerProfileId === this.liveSpotTrackerConfig?.profileId,
            label: 'Valider',
            handler: this.submitSpotTrackerProfile,
          }
    },
  },

  methods: {
    ...mapActions('ui', ['addToastMessage']),

    async fetchSpotTrackerProfiles() {
      this.loading = true

      try {
        this.spotTrackerProfiles = await this.$services.spotTrackerProfileService.getAll()
      } catch (err) {
        console.error(err)
        this.addToastMessage({
          text: err.message ? err.message : 'Une erreur interne est survenue.',
          type: 'is-danger',
        })
      }

      this.loading = false
    },

    async submitSpotTrackerProfile() {
      this.loading = true

      try {
        if (!this.selectedSpotTrackerProfileId) {
          return
        }

        const liveSpotTrackerConfig = await this.$services.liveSpotTrackerConfigService.update({
          id: this.live.id,
          profileId: this.selectedSpotTrackerProfileId,
        })

        this.disableUpdate = true

        this.$emit('input', liveSpotTrackerConfig)

        this.addToastMessage({
          text: 'Le profil a été mis à jour.',
          type: 'is-success',
        })
      } catch (err) {
        console.error(err)
        this.addToastMessage({
          text: 'Une erreur interne est survenue.',
          type: 'is-danger',
        })
      }

      this.loading = false
    },
  },
}
</script>
