<template>
  <div class="box">
    <b-table
      :loading="loading"
      :data="stageRoutes"
      striped
      hoverable
      :draggable="!loading && !selectedRoute"
      @drop="drop"
      @dragstart="dragstart"
      @dragover="dragover"
      @dragleave="dragleave"
    >
      <b-table-column>
        <b-icon size="is-small" icon="grip-lines" />
      </b-table-column>
      <b-table-column v-slot="{ row }" field="name" label="Nom">
        <template v-if="!showRow(row)">
          {{ row.name }}
        </template>
        <template v-else-if="showRow(row)">
          <b-field
            :message="fields.name.error"
            :type="fields.name.error !== null ? 'is-danger' : ''"
          >
            <b-input v-model="fields.name.value" type="text" />
          </b-field>
        </template>
      </b-table-column>
      <b-table-column v-slot="{ row }" field="color" label="Couleur" centered>
        <template v-if="!showRow(row)">
          {{ row.color }}
        </template>
        <template v-else-if="showRow(row)">
          <b-field
            :message="fields.color.error"
            :type="fields.color.error !== null ? 'is-danger' : ''"
          >
            <b-dropdown v-model="fields.color.value" scrollable aria-role="list">
              <template v-if="fields.color.value" #trigger>
                <b-button
                  :label="fields.color.value"
                  type="is-outlined"
                  icon-right="chevron-down"
                />
              </template>
              <b-dropdown-item
                v-for="color in colors"
                :key="color"
                :value="color"
                aria-role="listitem"
              >
                {{ color }}
              </b-dropdown-item>
            </b-dropdown>
          </b-field>
        </template>
      </b-table-column>
      <b-table-column v-slot="{ row }" field="points" label="Points" centered>
        {{ row.pathLength }}
      </b-table-column>
      <b-table-column v-slot="{ row }" label="">
        <div v-if="!showRow(row)" class="buttons are-small is-right">
          <b-button type="is-primary" @click="editRoute(row)">
            <b-icon icon="edit" />
          </b-button>
          <b-button type="is-danger" @click="toggleModal(row)">
            <b-icon icon="trash" />
          </b-button>
        </div>
        <div v-else-if="showRow(row)" class="buttons are-small is-right">
          <b-button type="is-success" @click="submit">
            <b-icon icon="check" />
          </b-button>
          <b-button type="is-warning" @click="selectedRoute = null">
            <b-icon icon="times" />
          </b-button>
        </div>
      </b-table-column>
    </b-table>
    <div
      v-show="!loading && errorMessage && stageRoutes.length === 0"
      class="has-text-grey has-text-centered"
    >
      {{ errorMessage }}
    </div>
    <o-modal
      :title="modalTitle"
      :isOpen="!!selectedRoute && isOpenModal"
      @closeModal="toggleModal"
      @confirmModalAction="$emit('delete', selectedRoute)"
    >
      <template>
        La suppression d'une route est <strong>définitive</strong>. <br />
        Cela <strong>inclut la suppression de ses zones de vitesse</strong>.
      </template>
    </o-modal>
  </div>
</template>

<script>
import OModal from '@components/Modal.vue'
import { iconNames } from '@data/icons'

const COLORS = ['red', 'green', 'gray', 'blue', 'orange', 'pink', 'purple']

export default {
  name: 'LiveStageRouteTable',

  components: {
    OModal,
  },

  props: {
    loading: {
      type: Boolean,
      required: true,
    },
    stageRoutes: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      errorMessage: null,
      selectedRoute: null,
      fields: {
        name: {
          value: '',
          error: null,
        },
        color: {
          value: '',
          error: null,
        },
      },
      isOpenModal: false,
      draggingRow: null,
      draggingRowIndex: null,
    }
  },

  created() {
    this.colors = COLORS
    this.icons = iconNames
  },

  watch: {
    stageRoutes(routes) {
      if (!this.errorMessage && routes.length === 0) {
        this.errorMessage = 'Aucune route'
      }
    },
  },

  computed: {
    modalTitle() {
      return `Suppression de la route ${this.selectedRoute && this.selectedRoute.name}`
    },
  },

  methods: {
    showRow(row) {
      return this.selectedRoute && !this.isOpenModal && row.id === this.selectedRoute.id
    },

    submit() {
      this.$emit('submit', {
        ...this.selectedRoute,
        name: this.fields.name.value,
        color: this.fields.color.value,
      })

      this.selectedRoute = null
    },

    editRoute(route) {
      this.selectedRoute = route
      this.fields = {
        name: {
          value: route.name,
          error: null,
        },
        color: {
          value: route.color,
          error: null,
        },
      }
    },

    dragstart(payload) {
      this.draggingRow = payload.row
      this.draggingRowIndex = payload.index
      payload.event.dataTransfer.effectAllowed = 'copy'
    },

    dragover(payload) {
      payload.event.dataTransfer.dropEffect = 'copy'
      payload.event.target.closest('tr').classList.add('is-selected')
      payload.event.preventDefault()
    },

    dragleave(payload) {
      payload.event.target.closest('tr').classList.remove('is-selected')
      payload.event.preventDefault()
    },

    drop(payload) {
      payload.event.target.closest('tr').classList.remove('is-selected')
      const droppedOnRowIndex = payload.index

      if (this.draggingRow.position !== droppedOnRowIndex) {
        this.$emit('drop', droppedOnRowIndex, this.draggingRowIndex)
      }
    },

    toggleModal(route = null) {
      this.isOpenModal = !!route
      this.selectedRoute = route
    },
  },
}
</script>
