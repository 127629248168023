<template>
  <div class="box">
    <o-table
      :items="stageWaypoints"
      :loading="loading"
      :draggable="!selectedWaypoint"
      :checkable="true"
      :checked-rows="checkedWaypoints"
      @check="checkedWaypoints = $event"
      @drop="
        (droppedOnRowIndex, draggingRowIndex, row) => $emit('drop', droppedOnRowIndex, draggingRowIndex, row)
      "
    >
      <b-table-column v-slot="{ row }" field="accessType" label="Accès" centered>
        <template v-if="!showRow(row)">
          <b-icon
            size="is-small"
            :type="accessType(row.accessType).type"
            :icon="accessType(row.accessType).icon"
          />
        </template>
        <template v-else>
          <b-field
            :message="fields.accessType.error"
            :type="fields.accessType.error !== null ? 'is-danger' : ''"
          >
            <o-dropdown
              v-model="fields.accessType.value"
              :items="['private', 'public']"
              @input="(value) => (fields.accessType.value = value)"
            >
              <template v-slot:label="{ label }">
                <b-icon
                  size="is-small"
                  :type="accessType(label).type"
                  :icon="accessType(label).icon"
                />
              </template>
              <template v-slot:item="{ item }">
                <b-icon
                  size="is-small"
                  :type="accessType(item).type"
                  :icon="accessType(item).icon"
                />
              </template>
            </o-dropdown>
          </b-field>
        </template>
      </b-table-column>
      <b-table-column v-slot="{ row }" field="name" label="Nom">
        <template v-if="!showRow(row)">
          <b-icon v-show="row.radius" size="is-small" type="is-info" icon="copyright" />
          <b-icon
            v-show="row.role === start"
            size="is-small"
            type="is-success"
            icon="flag-checkered"
          />
          <b-icon
            v-show="row.role === finish"
            size="is-small"
            type="is-danger"
            icon="flag-checkered"
          />
          {{ row.name }}
          <b-icon
            v-show="row.name.length > 30"
            size="is-small"
            type="is-warning"
            icon="exclamation-triangle"
          />
        </template>
        <template v-else>
          <b-field
            :message="fields.name.error"
            :type="fields.name.error !== null ? 'is-danger' : ''"
          >
            <b-input
              v-model="fields.name.value"
              type="text"
              :icon-right="fields.name.value ? 'close-circle' : ''"
              :icon-right-clickable="!!fields.name.value"
              @icon-right-click="fields.name.value = null"
            />
          </b-field>
        </template>
      </b-table-column>
      <b-table-column v-slot="{ row }" field="label" label="Label">
        <template v-if="!showRow(row)">
          {{ row.label }}
        </template>
        <template v-else>
          <b-field
            :message="fields.label.error"
            :type="fields.label.error !== null ? 'is-danger' : ''"
          >
            <b-input
              v-model="fields.label.value"
              type="text"
              maxlength="6"
              :icon-right="fields.label.value ? 'close-circle' : ''"
              :icon-right-clickable="!!fields.label.value"
              @icon-right-click="fields.label.value = null"
            />
          </b-field>
        </template>
      </b-table-column>
      <b-table-column v-slot="{ row }" field="color" label="Couleur" centered>
        <template v-if="!showRow(row)">
          {{ row.color }}
        </template>
        <template v-else>
          <b-field
            :message="fields.color.error"
            :type="fields.color.error !== null ? 'is-danger' : ''"
          >
            <o-dropdown
              v-model="fields.color.value"
              :items="colors"
              @input="(value) => (fields.color.value = value)"
            />
          </b-field>
        </template>
      </b-table-column>
      <b-table-column v-slot="{ row }" field="icon" label="Icône">
        <template v-if="!showRow(row)">
          <o-icon :name="row.icon" />
        </template>
        <template v-else>
          <b-field
            :message="fields.icon.error"
            :type="fields.icon.error !== null ? 'is-danger' : ''"
          >
            <o-icon-picker v-model="fields.icon.value" :icons="icons" :expanded="false" />
          </b-field>
        </template>
      </b-table-column>
      <b-table-column v-slot="{ row }" field="radius" label="Rayon">
        <template v-if="!showRow(row)">
          {{ radius(row.radius) }}
        </template>
        <template v-else>
          <b-field
            :message="fields.radius.error"
            :type="fields.radius.error !== null ? 'is-danger' : ''"
          >
            <o-dropdown
              v-model="fields.radius.value"
              :items="radiuses"
              @input="(value) => (fields.radius.value = value)"
            >
              <template v-slot:label="{ label }">
                {{ radius(label) }}
              </template>
              <template v-slot:item="{ item }">
                {{ radius(item) }}
              </template>
            </o-dropdown>
          </b-field>
        </template>
      </b-table-column>
      <b-table-column v-slot="{ row }" field="role" label="Rôle">
        <template v-if="!showRow(row)">
          {{ role(row.role) }}
        </template>
        <template v-else>
          <b-field
            :message="fields.role.error"
            :type="fields.role.error !== null ? 'is-danger' : ''"
          >
            <o-dropdown
              v-model="fields.role.value"
              :items="roles"
              @input="fields.role.value = $event"
            >
              <template v-slot:label="{ label }">
                {{ role(label) }}
              </template>
              <template v-slot:item="{ item }">
                {{ role(item) }}
              </template>
            </o-dropdown>
          </b-field>
        </template>
      </b-table-column>
      <b-table-column v-slot="{ row }" field="liveCategoryId" label="Catégorie">
        <template v-if="!showRow(row)">
          {{ getCategoryName(row.liveCategoryId) }}
        </template>
        <template v-else>
          <b-field
            :message="fields.liveCategoryId.error"
            :type="fields.liveCategoryId.error !== null ? 'is-danger' : ''"
          >
            <b-select v-model="fields.liveCategoryId.value" expanded>
              <option :value="null">—</option>
              <option
                v-for="category in filteredCategories"
                :key="category.id"
                :value="category.id"
              >
                {{ category.name }}
              </option>
            </b-select>
          </b-field>
        </template>
      </b-table-column>
      <b-table-column v-slot="{ row }" width="100">
        <div v-if="!showRow(row)" class="buttons are-small is-right">
          <b-button type="is-primary" :loading="loading" @click="selectedWaypoint = row">
            <b-icon icon="edit" />
          </b-button>
          <b-button type="is-danger" :loading="loading" @click="toggleModal(row)">
            <b-icon icon="trash" />
          </b-button>
        </div>
        <div v-else class="buttons are-small is-right">
          <b-button type="is-success" :loading="loading" :disabled="isFormInvalid" @click="submit">
            <b-icon icon="check" />
          </b-button>
          <b-button type="is-warning" @click="selectedWaypoint = null">
            <b-icon icon="times" />
          </b-button>
        </div>
      </b-table-column>
    </o-table>
    <o-modal
      :title="modalTitle"
      :isOpen="!!selectedWaypoint && isOpenModal"
      @closeModal="toggleModal"
      @confirmModalAction="deleteWaypoint"
    >
      <template> La suppression d'un waypoint est <strong>définitive</strong>. </template>
    </o-modal>
    <live-stage-waypoint-form
      v-show="checkedWaypoints.length > 0"
      :loading="loading"
      :live-categories="filteredCategories"
      class="mt-2"
      @submit="submitAll"
      @delete="deleteAll"
    />
  </div>
</template>

<script>
import { iconNames } from '@data/icons'
import { COLORS } from '@constants/waypoint/color'
import { RADIUSES } from '@constants/waypoint/radiuses'
import OModal from '@components/Modal.vue'
import OTable from '@components/Table.vue'
import ODropdown from '@components/Dropdown.vue'
import OIconPicker from '@components/IconPicker.vue'
import LiveStageWaypointForm from './LiveStageWaypointForm'
import { WAYPOINT_ROLE_FINISH, WAYPOINT_ROLE_START } from '@constants/waypoint/role'

export default {
  name: 'EditLiveStageWaypointTable',

  components: {
    OModal,
    OTable,
    ODropdown,
    OIconPicker,
    LiveStageWaypointForm,
  },

  props: {
    loading: {
      type: Boolean,
      required: true,
    },
    stageWaypoints: {
      type: Array,
      required: true,
    },
    liveCategories: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      isOpenModal: false,
      selectedWaypoint: null,
      checkedWaypoints: [],
      fields: {
        name: {
          value: '',
          error: null,
        },
        color: {
          value: '',
          error: null,
        },
        icon: {
          value: '',
          error: null,
        },
        accessType: {
          value: '',
          error: null,
        },
        radius: {
          value: '',
          error: null,
        },
        role: {
          value: null,
          error: null,
        },
        label: {
          value: '',
          error: null,
        },
        liveCategoryId: {
          value: null,
          error: null,
        },
      },
    }
  },

  created() {
    this.colors = COLORS
    this.icons = iconNames
    this.radiuses = [null, ...RADIUSES]
    this.start = WAYPOINT_ROLE_START
    this.finish = WAYPOINT_ROLE_FINISH
    this.roles = [null, WAYPOINT_ROLE_START, WAYPOINT_ROLE_FINISH]
  },

  watch: {
    selectedWaypoint(waypoint) {
      if (!waypoint) {
        this.resetFields()
        return
      }

      this.fields.name.value = waypoint.name
      this.fields.color.value = waypoint.color
      this.fields.icon.value = waypoint.icon
      this.fields.accessType.value = waypoint.accessType
      this.fields.radius.value = waypoint.radius
      this.fields.role.value = waypoint.role
      this.fields.label.value = waypoint.label
      this.fields.liveCategoryId.value = waypoint.liveCategoryId
    },

    'fields.name.value'(name) {
      this.fields.name.error = !name || name.length === 0 ? 'Nom requis' : null
    },

    'fields.icon.value'(icon) {
      this.fields.icon.error = !icon || icon.length === 0 ? 'Icône requise' : null
    },

    stageWaypoints() {
      this.checkedWaypoints = []
    },

    fieldRoleError(error) {
      this.fields.role.error = error
    },

    fieldLiveCategoryIdError(error) {
      this.fields.liveCategoryId.error = error
    },
  },

  computed: {
    fieldRoleError() {
      if (!this.fields.radius.value && this.fields.role.value) {
        return 'Rayon requis'
      }

      const waypointWithSameRole = this.stageWaypoints.find((waypoint) => {
        const condition =
          waypoint.role &&
          waypoint.role === this.fields.role.value &&
          waypoint.id !== this.selectedWaypoint.id
        const optionalCondition =
          waypoint.liveCategoryId === this.fields.liveCategoryId.value ||
          waypoint.liveCategoryId === null

        return this.fields.liveCategoryId.value ? condition && optionalCondition : condition
      })

      return waypointWithSameRole ? 'Rôle déjà attribué' : null
    },

    fieldLiveCategoryIdError() {
      return !this.fields.radius.value && this.fields.liveCategoryId.value ? 'Rayon requis' : null
    },

    isFormInvalid() {
      return (
        Object.values(this.fields).find((field) => field.error !== null) ||
        this.fields.name.value === null ||
        this.fields.icon.value === null ||
        this.loading
      )
    },

    modalTitle() {
      return `Suppression du waypoint ${this.selectedWaypoint && this.selectedWaypoint.name}`
    },

    filteredCategories() {
      return this.liveCategories.filter((category) => category.useCheckpoint)
    },
  },

  methods: {
    async submit() {
      this.$emit('submit', {
        ...this.selectedWaypoint,
        name: this.fields.name.value,
        icon: this.fields.icon.value,
        color: this.fields.color.value,
        accessType: this.fields.accessType.value,
        radius: this.fields.radius.value,
        role: this.fields.radius.value ? this.fields.role.value : null,
        label: this.fields.label.value,
        liveCategoryId: this.fields.liveCategoryId.value,
      })

      this.selectedWaypoint = null
    },

    submitAll(fields) {
      const waypoints = this.checkedWaypoints.map((waypoint) => {
        if (fields.radius === null && fields.role !== null) {
          fields.role = null
          fields.liveCategoryId = null
        }

        return { ...waypoint, ...fields }
      })

      this.$emit('submitAll', waypoints)
    },

    deleteAll() {
      const waypointIds = this.checkedWaypoints.map((waypoint) => waypoint.id)
      this.$emit('deleteAll', waypointIds)
    },

    deleteWaypoint() {
      this.$emit('deleteWaypoint', this.selectedWaypoint)
    },

    accessType(accessType) {
      return {
        type: accessType === 'public' ? 'is-success' : 'is-danger',
        icon: accessType === 'public' ? 'unlock' : 'lock',
      }
    },

    radius(radius) {
      return radius ? `${radius}m` : '—'
    },

    role(role) {
      return role ? role : '—'
    },

    resetFields() {
      Object.values(this.fields).forEach((field) => {
        field.value = null
        field.error = null
      })
    },

    showRow(row) {
      return this.selectedWaypoint && !this.isOpenModal && row.id === this.selectedWaypoint.id
    },

    toggleModal(waypoint = null) {
      this.isOpenModal = !!waypoint
      this.selectedWaypoint = waypoint
    },

    getCategoryName(categoryId) {
      const category = this.filteredCategories.find((cat) => cat.id === categoryId)

      return category?.name ? category.name : '—'
    },
  },
}
</script>
