import {
  AVAILABILITY_AVAILABLE,
  AVAILABILITY_BROKEN,
  AVAILABILITY_LOST,
  AVAILABILITY_UNAVAILABLE,
} from '@constants/tracker/availability.js'

export const availabilityTag = {
  [AVAILABILITY_AVAILABLE]: { label: 'Rendu', type: 'is-light is-success' },
  [AVAILABILITY_UNAVAILABLE]: {
    label: 'Non rendu',
    type: 'is-light is-warning',
  },
  [AVAILABILITY_LOST]: { label: 'Perdu', type: 'is-light is-danger' },
  [AVAILABILITY_BROKEN]: { label: 'Cassé', type: 'is-light is-danger' },
}
