<template>
  <b-table
    :loading="loading"
    :data="trackers"
    checkable
    checkbox-position="left"
    checkbox-type="primary"
    sticky-header
    striped
    narrowed
    default-sort="trackerNumber"
    :mobile-cards="false"
    :checked-rows="checkedTrackers"
    @check="$emit('checkTrackers', $event)"
  >
    <b-table-column v-slot="{ row }" field="trackerNumber" label="Balise" sortable sticky>
      {{ row.trackerNumber }}
    </b-table-column>
    <b-table-column v-slot="{ row }" field="vehicleNumber" label="Dossard" sortable sticky>
      {{ row.vehicleNumber }}
    </b-table-column>
    <b-table-column v-slot="{ row }" field="owner" label="Propriétaire" sortable sticky>
      {{ capitalize(row.owner) }}
    </b-table-column>
    <b-table-column v-slot="{ row }" field="availability" label="Status" sortable sticky>
      <b-tag :type="availabilityTag[row.availability].type">
        {{ availabilityTag[row.availability].label }}
      </b-tag>
    </b-table-column>
    <template v-if="checkedTrackers.length > 0" #bottom-left>
      {{ totalCheckedRowsMessage }}: <b>&nbsp;{{ checkedTrackers.length }}</b>
    </template>
    <template #empty>
      <span class="is-flex is-justify-content-center">Aucun résultat</span>
    </template>
  </b-table>
</template>

<script>
import { availabilityTag } from './index.js'
import { capitalize } from '@helpers/capitalize.js'

export default {
  name: 'LiveTrackerAvailabilityTable',

  props: {
    loading: {
      type: Boolean,
      required: true,
    },
    trackers: {
      type: Array,
      required: true,
    },
    checkedTrackers: {
      type: Array,
      required: true,
    },
  },

  created() {
    this.availabilityTag = availabilityTag
  },

  computed: {
    totalCheckedRowsMessage() {
      return this.checkedTrackers.length > 1 ? `Total sélectionnées` : `Total sélectionnée`
    },
  },

  methods: {
    capitalize(value) {
      return capitalize(value)
    },
  },
}
</script>
