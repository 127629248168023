export const FORMAT_DD = 'dd'
export const FORMAT_DDM = 'ddm'
export const FORMAT_DMS = 'dms'

const formats = {
  [FORMAT_DD]: 'ddd.ddd',
  [FORMAT_DDM]: 'DDD° mm.mmm′ X',
  [FORMAT_DMS]: 'DDD° MM′ ss.sss″ X',
}

function compute(value) {
  let values = {}

  values.degrees = Math.abs(value)
  values.minutes = (values.degrees % 1) * 60
  values.seconds = (values.minutes % 1) * 60

  return values
}

function formatCoord(value, format, plusSign, minusSign) {
  const coord = compute(value)

  let formatted = format
  formatted = formatted.replace('DDD', Math.floor(coord.degrees))
  formatted = formatted.replace('ddd.ddd', value.toFixed(3))
  formatted = formatted.replace('MM', Math.floor(coord.minutes))
  formatted = formatted.replace('mm.mmm', coord.minutes.toFixed(3))
  formatted = formatted.replace('SS', Math.floor(coord.seconds))
  formatted = formatted.replace('ss.sss', coord.seconds.toFixed(3))
  formatted = formatted.replace('X', value > 0 ? plusSign : minusSign)

  return formatted
}

export function formatLatLon(latLon, format) {
  const coordinateFormat = format in formats ? formats[format] : formats[FORMAT_DDM]

  return [
    formatCoord(latLon[0], coordinateFormat, 'N', 'S'),
    formatCoord(latLon[1], coordinateFormat, 'E', 'W'),
  ]
}
