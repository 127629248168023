<template>
  <div class="o-icon-picker">
    <b-field class="m-0 has-addons">
      <p v-if="filteredIcons.includes(value)" class="control">
        <span class="button is-static">
          <o-icon :name="value" />
        </span>
      </p>
      <b-input
        type="text"
        :value="value"
        :expanded="expanded"
        :placeholder="placeholder"
        icon-right="close-circle"
        :icon-right-clickable="!!value"
        @icon-right-click="$emit('input', null)"
        @input="(input) => $emit('input', input)"
        @focus="isOpen = true"
      />
    </b-field>
    <div v-show="isOpen" class="box p-2 o-icon-picker__wrapper">
      <span v-if="filteredIcons.length === 0" class="is-flex is-justify-content-center">
        Aucun résultat
      </span>
      <div v-else class="is-flex is-flex-wrap-wrap">
        <b-button
          v-for="icon of filteredIcons"
          :key="icon"
          type="is-light"
          size="is-small"
          class="m-1"
          @click="
            $emit('input', icon)
            isOpen = false
          "
        >
          <o-icon :name="icon" />
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'OIconPicker',

  props: {
    icons: {
      type: Array,
      required: true,
    },
    value: {
      type: String,
      default: null,
    },
    expanded: {
      type: Boolean,
      default: true,
    },
    placeholder: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      isOpen: false,
    }
  },

  watch: {
    isOpen(isOpen) {
      if (isOpen) {
        ;['click', 'keydown'].forEach((evt) => document.addEventListener(evt, this.closePicker))
      } else {
        ;['click', 'keydown'].forEach((evt) => document.removeEventListener(evt, this.closePicker))
      }
    },
  },

  computed: {
    filteredIcons() {
      return this.value
        ? this.icons.filter(
            (option) => option.toString().toLowerCase().indexOf(this.value.toLowerCase()) >= 0,
          )
        : this.icons
    },
  },

  methods: {
    closePicker(evt) {
      if (!this.$el.contains(evt.target) || evt.code === 'Escape') {
        this.isOpen = false
      }
    },
  },
}
</script>

<style lang="scss">
.o-icon-picker {
  position: relative;

  &__wrapper {
    position: absolute;
    width: 100%;
    bottom: 100%;
    z-index: 5;
    max-height: 200px;
    overflow-y: auto;
  }
}
</style>
