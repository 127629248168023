<template>
  <page-layout :title="title">
    <template v-slot:breadcrumb>
      <li><router-link :to="{ name: 'home' }">Dashboard</router-link></li>
      <li><router-link :to="{ name: 'live.list' }">Lives</router-link></li>
      <li class="is-active">
        <a href="#">{{ title }}</a>
      </li>
    </template>
    <div class="box">
      <add-live-form />
    </div>
  </page-layout>
</template>

<script>
import PageLayout from '@components/PageLayout.vue'
import AddLiveForm from './AddLiveForm.vue'

export default {
  name: 'AddLivePage',

  components: {
    [PageLayout.name]: PageLayout,
    [AddLiveForm.name]: AddLiveForm,
  },

  data() {
    return {
      title: 'Ajouter un live',
    }
  },
}
</script>
