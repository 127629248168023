<template>
  <div>
    <live-vehicle-tracker-form :loading="loading" :live-vehicles="liveVehicles" @submit="submit" />
    <live-vehicle-tracker-history-table
      :loading="loading"
      :spotTrackers="spotTrackers"
      :queclinkTrackers="queclinkTrackers"
      :live-vehicles="liveVehicles"
      :administrators="administrators"
      :live-vehicle-tracker-histories="liveVehicleTrackerHistories"
    />
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import LiveVehicleTrackerForm from './LiveVehicleTrackerForm/index.vue'
import LiveVehicleTrackerHistoryTable from './LiveVehicleTrackerHistoryTable.vue'

export default {
  name: 'SwitchLiveVehicleTracker',

  components: {
    LiveVehicleTrackerForm,
    LiveVehicleTrackerHistoryTable,
  },

  props: {
    live: {
      type: Object,
      required: true,
    },
    spotTrackers: {
      type: Object,
      required: true,
    },
    queclinkTrackers: {
      type: Object,
      required: true,
    },
    liveVehicles: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      loading: false,
      administrators: [],
      liveVehicleTrackerHistories: [],
    }
  },

  async mounted() {
    this.loading = true

    this.liveVehicleTrackerHistories = await this.$services.liveVehicleTrackerHistoryService.getAll(
      this.live.id,
    )
    this.administrators = await this.$services.administratorService.getAll()

    this.loading = false
  },

  computed: {
    ...mapState('auth', ['identity']),
  },

  methods: {
    ...mapActions('ui', ['addToastMessage']),

    async submit({ fromLiveVehicle, fromTracker, toLiveVehicle, toTracker, comment }) {
      this.loading = true

      try {
        const liveVehicleTrackerHistory =
          await this.$services.liveVehicleTrackerHistoryService.switchTrackers({
            administratorId: this.identity.id,
            fromLiveVehicleId: fromLiveVehicle.id,
            fromLiveVehicleNumber: fromLiveVehicle.number,
            fromTrackerId: fromTracker.id,
            fromTrackerType: fromTracker.type,
            toLiveVehicleId: toLiveVehicle.id,
            toLiveVehicleNumber: toLiveVehicle.number,
            toTrackerId: toTracker.id,
            toTrackerType: toTracker.type,
            comment,
          })

        this.$emit('updateLiveVehicles', { fromLiveVehicle, toLiveVehicle })

        this.liveVehicleTrackerHistories = [
          ...this.liveVehicleTrackerHistories,
          liveVehicleTrackerHistory,
        ]

        this.addToastMessage({
          text: 'Les balises ont été inversées.',
          type: 'is-success',
        })
      } catch (err) {
        console.error(err)
        this.addToastMessage({
          text: 'Une erreur interne est survenue.',
          type: 'is-danger',
        })
      }

      this.loading = false
    },
  },
}
</script>
