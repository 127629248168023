<template>
  <form novalidate="true" @submit.prevent="submit">
    <b-field grouped group-multiline class="columns">
      <b-field label="Pays" class="column">
        <b-select v-model="fields.country.value" placeholder="Selectionner un pays" expanded>
          <option :value="null">Selectionner un pays</option>
          <option v-for="country in countries" :key="country.code" :value="country.code">
            {{ country.name }}
          </option>
        </b-select>
      </b-field>
      <b-field label="Genre" class="column">
        <b-select v-model="fields.gender.value" placeholder="Selectionner le genre" expanded>
          <option :value="null">Selectionner le genre</option>
          <option v-for="gender in ['male', 'female', 'mixed']" :key="gender" :value="gender">
            {{ vehicleGender(gender) }}
          </option>
        </b-select>
      </b-field>
    </b-field>
    <b-field
      label="Catégorie"
      :message="fields.liveCategoryId.error"
      :type="fields.liveCategoryId.error !== null ? 'is-danger' : ''"
    >
      <b-select
        v-model="fields.liveCategoryId.value"
        placeholder="Selectionner une catégorie"
        expanded
      >
        <option
          v-for="liveCategory in liveCategories"
          :key="liveCategory.id"
          :value="liveCategory.id"
        >
          {{ liveCategory.name }}
        </option>
      </b-select>
    </b-field>
    <b-field
      label="Numéro"
      :message="fields.number.error"
      :type="fields.number.error !== null ? 'is-danger' : ''"
    >
      <b-input v-model="fields.number.value" type="text" />
    </b-field>
    <b-field
      label="Nom"
      :message="fields.name.error"
      :type="fields.name.error !== null ? 'is-danger' : ''"
    >
      <b-input v-model="fields.name.value" type="text" />
    </b-field>
    <b-field label="Comment">
      <b-input v-model="fields.comment.value" type="text" />
    </b-field>
    <b-field
      label="Type d'accès"
      :message="fields.accessType.error"
      :type="fields.accessType.error !== null ? 'is-danger' : ''"
    >
      <b-field>
        <b-radio-button v-model="fields.accessType.value" native-value="public">
          <span>Public</span>
        </b-radio-button>
        <b-radio-button v-model="fields.accessType.value" native-value="private">
          <span>Privé</span>
        </b-radio-button>
      </b-field>
    </b-field>
    <b-field
      label="Balise SPOT"
      :message="fields.spotTracker.error"
      :type="fields.spotTracker.error !== null ? 'is-danger' : ''"
    >
      <b-field v-if="fields.spotTracker.value">
        <div class="card">
          <div class="card-content">
            <div class="content">
              Balise SPOT #{{ fields.spotTracker.value.number }}
              <br />
              <small>ESN : {{ fields.spotTracker.value.serialNumber }}</small>
            </div>
          </div>
          <footer class="card-footer">
            <a class="card-footer-item" href="#" @click="fields.spotTracker.value = null">
              Modifier
            </a>
          </footer>
        </div>
      </b-field>
      <b-autocomplete
        v-if="fields.spotTracker.value === null"
        :data="spotTrackers.data"
        placeholder="Numéro ou ESN"
        :loading="spotTrackers.loading"
        field="number"
        @typing="(input) => $emit('fetchSpotTrackers', input)"
        @select="(option) => (fields.spotTracker.value = option)"
      >
        <template v-slot="props">
          <div class="media">
            <div class="media-content">
              Balise SPOT #{{ props.option.number }}
              <br />
              <small> ESN : {{ props.option.serialNumber }} </small>
            </div>
          </div>
        </template>
      </b-autocomplete>
    </b-field>
    <b-field
      label="Balise QUECLINK"
      :message="fields.queclinkTracker.error"
      :type="fields.queclinkTracker.error !== null ? 'is-danger' : ''"
    >
      <b-field v-if="fields.queclinkTracker.value">
        <div class="card">
          <div class="card-content">
            <div class="content">
              Balise QUECLINK #{{ fields.queclinkTracker.value.number }}
              <br />
              <small>IMEI : {{ fields.queclinkTracker.value.imei }}</small>
            </div>
          </div>
          <footer class="card-footer">
            <a class="card-footer-item" href="#" @click="fields.queclinkTracker.value = null">
              Modifier
            </a>
          </footer>
        </div>
      </b-field>
      <b-autocomplete
        v-if="fields.queclinkTracker.value === null"
        :data="queclinkTrackers.data"
        placeholder="Numéro ou IMEI"
        :loading="queclinkTrackers.loading"
        field="number"
        @typing="(input) => $emit('fetchQueclinkTrackers', input)"
        @select="(option) => (fields.queclinkTracker.value = option)"
      >
        <template v-slot="props">
          <div class="media">
            <div class="media-content">
              Balise QUECLINK #{{ props.option.number }}
              <br />
              <small> IMEI : {{ props.option.imei }} </small>
            </div>
          </div>
        </template>
      </b-autocomplete>
    </b-field>
    <hr />
    <b-field>
      <div class="buttons">
        <b-button
          native-type="submit"
          type="is-primary"
          :loading="loading"
          :disabled="isLiveFinished"
          label="Ajouter"
        />
        <b-button
          type="is-danger"
          tag="router-link"
          :to="{ name: 'live.edit.vehicle.list', params: { id: live.id } }"
        >
          Annuler
        </b-button>
      </div>
    </b-field>
  </form>
</template>

<script>
import { STATUS_FINISHED } from '@constants/live/status'
import countries from '@data/countries'

export default {
  name: 'LiveAddVehicleForm',

  props: {
    live: {
      type: Object,
      required: true,
      validator: (v) => ['id', 'status'].every((key) => key in v),
    },
    loading: {
      type: Boolean,
      required: true,
    },
    liveCategories: {
      type: Array,
      required: true,
    },
    spotTrackers: {
      type: Object,
      required: true,
      validator: (v) => ['data', 'loading'].every((key) => key in v),
    },
    queclinkTrackers: {
      type: Object,
      required: true,
      validator: (v) => ['data', 'loading'].every((key) => key in v),
    },
  },

  data() {
    return {
      fields: {
        liveCategoryId: {
          value: '',
          error: null,
        },
        number: {
          value: '',
          error: null,
        },
        name: {
          value: '',
          error: null,
        },
        country: {
          value: null,
        },
        accessType: {
          value: 'public',
          error: null,
        },
        spotTracker: {
          value: null,
          error: null,
        },
        queclinkTracker: {
          value: null,
          error: null,
        },
        gender: {
          value: null,
        },
        comment: {
          value: null,
        },
      },
    }
  },

  created() {
    this.countries = countries
  },

  computed: {
    isLiveFinished() {
      return this.live.status === STATUS_FINISHED
    },
  },

  methods: {
    async submit() {
      this.$emit('submit', {
        liveCategoryId: this.fields.liveCategoryId.value,
        number: this.fields.number.value,
        name: this.fields.name.value,
        country: this.fields.country.value,
        accessType: this.fields.accessType.value,
        spotTrackerId: (this.fields.spotTracker.value && this.fields.spotTracker.value.id) || null,
        queclinkTrackerId:
          (this.fields.queclinkTracker.value && this.fields.queclinkTracker.value.id) || null,
        gender: this.fields.gender.value,
        comment: this.fields.comment.value,
      })
    },

    vehicleGender(gender) {
      const vehicleGenders = {
        male: 'Homme',
        female: 'Femme',
        mixed: 'Mixte',
      }

      return vehicleGenders[gender] ? vehicleGenders[gender] : null
    },
  },
}
</script>
