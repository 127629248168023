<template>
  <div>
    <h2 class="title is-6">Sponsors</h2>
    <live-add-sponsor-form
      :liveId="live.id"
      :position="sponsors.length + 1"
      @submit="createSponsor"
    />
    <hr />
    <live-sponsor-table
      :liveSponsors="sponsors"
      @drop="updateSponsorIndexInArray"
      @submit="updateSponsor"
      @delete="deleteSponsor"
    />
  </div>
</template>

<script>
import LiveSponsorTable from './LiveSponsorTable.vue'
import LiveAddSponsorForm from './LiveAddSponsorForm.vue'

export default {
  name: 'EditLiveSponsorTab',

  components: {
    LiveSponsorTable,
    LiveAddSponsorForm,
  },

  props: {
    live: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      liveSponsors: [],
    }
  },

  async mounted() {
    this.liveSponsors = await this.$services.liveSponsorService.getAll(this.live.id)
  },

  computed: {
    sponsors() {
      return [...this.liveSponsors].sort((current, next) => {
        const a = current.position
        const b = next.position

        if (a < b) return -1
        if (a > b) return 1

        return 0
      })
    },
  },
  methods: {
    async createSponsor(createdLiveSponsor) {
      this.liveSponsors.splice(createdLiveSponsor.position - 1, 0, createdLiveSponsor)

      await this.updateSponsorPositions()
    },

    async updateSponsor(updatedLiveSponsor) {
      this.liveSponsors = this.liveSponsors.map((liveSponsor) =>
        liveSponsor.id === updatedLiveSponsor.id ? updatedLiveSponsor : liveSponsor,
      )
    },

    async deleteSponsor(deletedSponsor) {
      this.liveSponsors = this.liveSponsors.filter(
        (liveSponsor) => liveSponsor.id !== deletedSponsor.id,
      )

      await this.updateSponsorPositions()
    },

    updateSponsorIndexInArray(currentIndex, targetIndex) {
      this.liveSponsors.splice(currentIndex, 0, ...this.liveSponsors.splice(targetIndex, 1))

      this.updateSponsorPositions()
    },

    async updateSponsorPositions() {
      for (let i = 0; i < this.liveSponsors.length; i++) {
        if (this.liveSponsors[i].position !== i) {
          this.liveSponsors[i].position = i

          await this.$services.liveSponsorService.update({
            ...this.liveSponsors[i],
            position: i,
          })
        }
      }
    },
  },
}
</script>
