<template>
  <live-edit-vehicle-form
    class="box"
    :live="live"
    :loading="loading"
    :liveVehicle="liveVehicle"
    :liveCategories="liveCategories"
    :spotTrackers="spotTrackers"
    :queclinkTrackers="queclinkTrackers"
    @submit="submit"
    @fetchSpotTrackers="fetchSpotTrackers"
    @fetchQueclinkTrackers="fetchQueclinkTrackers"
  />
</template>

<script>
import { mapActions } from 'vuex'
import LiveEditVehicleForm from './LiveEditVehicleForm.vue'
import { AVAILABILITY_AVAILABLE } from '@constants/tracker/availability'
import { isPast } from 'date-fns'

export default {
  name: 'EditLiveEditVehicleTab',

  components: {
    LiveEditVehicleForm,
  },

  props: {
    live: {
      type: Object,
      required: true,
    },
    liveVehicleId: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      loading: false,
      liveVehicle: null,
      liveCategories: [],
      spotTrackers: {
        loading: false,
        data: [],
      },
      queclinkTrackers: {
        loading: false,
        data: [],
      },
    }
  },

  async mounted() {
    this.loading = true

    const [liveVehicle, liveCategories] = await Promise.all([
      await this.$services.liveVehicleService.getById(this.liveVehicleId),
      await this.$services.liveCategoryService.getAll(this.live.id),
    ])

    this.liveVehicle = liveVehicle
    this.liveCategories = liveCategories

    this.loading = false
  },

  methods: {
    ...mapActions('ui', ['addToastMessage']),

    async submit(vehicle) {
      this.loading = true

      try {
        this.liveVehicle = await this.$services.liveVehicleService.update(this.liveVehicleId, {
          liveCategoryId: vehicle.liveCategoryId,
          spotTrackerId: vehicle.spotTrackerId,
          queclinkTrackerId: vehicle.queclinkTrackerId,
          number: vehicle.number,
          name: vehicle.name,
          country: vehicle.country,
          accessType: vehicle.accessType,
          gender: vehicle.gender,
          comment: vehicle.comment,
        })

        this.addToastMessage({
          text: `Le véhicule "${this.liveVehicle.name}" a été modifié.`,
          type: 'is-success',
        })
      } catch (err) {
        this.addToastMessage({
          text: 'Une erreur interne est survenue.',
          type: 'is-danger',
        })
        console.error(err)
      }

      this.loading = false
    },

    async fetchSpotTrackers(input) {
      const keyword = input.trim().toUpperCase()

      if (keyword.length < 2) {
        this.spotTrackers.data = []
        return
      }

      this.spotTrackers.loading = true
      const spotTrackers = await this.$services.spotTrackerService.getAll({
        availability: AVAILABILITY_AVAILABLE,
        keyword,
      })
      this.spotTrackers.data = spotTrackers.filter(
        (spotTracker) => spotTracker.expiredAt && !isPast(spotTracker.expiredAt),
      )
      this.spotTrackers.loading = false
    },

    async fetchQueclinkTrackers(input) {
      const keyword = input.trim().toUpperCase()

      if (keyword.length < 2) {
        this.queclinkTrackers.data = []
        return
      }

      this.queclinkTrackers.loading = true
      this.queclinkTrackers.data = await this.$services.queclinkTrackerService.getAll({
        availability: 'available',
        keyword,
      })
      this.queclinkTrackers.loading = false
    },
  },
}
</script>
