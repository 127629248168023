<template>
  <o-table :items="liveVehicles" :loading="loading" :draggable="false" default-sort="number">
    <b-table-column v-slot="{ row }" field="accessType" label="Accès" centered>
      <b-icon v-if="row.accessType === 'public'" size="is-small" type="is-success" icon="unlock" />
      <b-icon
        v-else-if="row.accessType === 'private'"
        size="is-small"
        type="is-danger"
        icon="lock"
      />
    </b-table-column>
    <b-table-column v-slot="{ row }" field="trackers" label="Type">
      <template v-if="row.trackers.length > 0">
        {{ row.trackers[0].type }}
      </template>
    </b-table-column>
    <b-table-column v-slot="{ row }" field="trackers" label="Balise" sortable>
      <router-link
        v-if="row.trackers.length > 0"
        :to="{
          name: `${trackerRoutes[row.trackers[0].type].name}.edit`,
          params: { id: row.trackers[0].id },
        }"
      >
        {{ row.trackers[0].number }}
      </router-link>
    </b-table-column>
    <b-table-column v-slot="{ row }" field="number" label="Dossard" sortable>
      {{ row.number }}
    </b-table-column>
    <b-table-column v-slot="{ row }" field="name" label="Nom" sortable>
      {{ row.name }}
    </b-table-column>
    <b-table-column v-slot="{ row }" field="gender" label="Genre">
      <b-icon v-if="row.gender === 'male'" type="is-info" icon="mars" />
      <b-icon v-else-if="row.gender === 'female'" type="is-danger" icon="venus" />
      <o-icon v-else-if="row.gender === 'mixed'" name="venus-mars" />
    </b-table-column>
    <b-table-column v-slot="{ row }" field="country" label="Pays">
      {{ row.country }}
    </b-table-column>
    <b-table-column v-slot="{ row }" field="liveCategory" label="Catégorie">
      {{ row.categoryName }}
    </b-table-column>
    <b-table-column v-slot="{ row }" label="">
      <div class="buttons are-small is-right">
        <b-button download @click="$emit('exportGpx', row)">
          <b-icon icon="file-export" />
        </b-button>
        <b-button
          type="is-primary"
          tag="router-link"
          :to="{
            name: 'live.edit.vehicle.edit',
            params: { liveVehicleId: row.id },
          }"
        >
          <b-icon icon="edit" />
        </b-button>
        <b-button type="is-danger" @click="$emit('delete', row)">
          <b-icon icon="trash" />
        </b-button>
      </div>
    </b-table-column>
  </o-table>
</template>

<script>
import OTable from '@components/Table.vue'
import { ROUTES } from '@constants/tracker/route'

export default {
  name: 'LiveVehicleTable.vue',

  components: {
    OTable,
  },

  props: {
    loading: {
      type: Boolean,
      required: true,
    },
    liveVehicles: {
      type: Array,
      required: true,
      validator: (v) =>
        v.every((obj) =>
          [
            'id',
            'name',
            'number',
            'gender',
            'country',
            'accessType',
            'trackers',
            'categoryName',
          ].every((key) => key in obj),
        ),
    },
  },

  created() {
    this.trackerRoutes = ROUTES
  },
}
</script>
