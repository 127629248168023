<template>
  <div>
    <o-table
      :items="liveExternalLinks"
      :loading="loading"
      :draggable="!selectedExternalLink"
      @drop="
        (droppedOnRowIndex, draggingRowIndex) => $emit('drop', droppedOnRowIndex, draggingRowIndex)
      "
    >
      <template>
        <b-table-column v-slot="{ row }" field="name" label="Nom">
          <template v-if="!showRow(row)">
            {{ row.name }}
          </template>
          <template v-else-if="showRow(row)">
            <b-field
              expanded
              :message="fields.name.error"
              :type="fields.name.error !== null ? 'is-danger' : ''"
            >
              <b-input
                v-model="fields.name.value"
                expanded
                placeholder="Nom du lien"
                type="text"
                :icon-right="fields.name.value ? 'close-circle' : ''"
                :icon-right-clickable="!!fields.name.value"
                @icon-right-click="fields.name.value = null"
              />
            </b-field>
          </template>
        </b-table-column>
        <b-table-column v-slot="{ row }" field="url" label="Url">
          <template v-if="!showRow(row)">
            {{ row.url }}
          </template>
          <template v-else-if="showRow(row)">
            <b-field expanded :message="fields.url.error" :type="{ 'is-danger': fields.url.error }">
              <b-input
                v-model="fields.url.value"
                expanded
                placeholder="https://example.com"
                type="url"
                :icon-right="fields.url.value ? 'close-circle' : ''"
                :icon-right-clickable="!!fields.url.value"
                @icon-right-click="fields.url.value = null"
              />
            </b-field>
          </template>
        </b-table-column>
        <b-table-column v-slot="{ row }" field="icon" label="Icône" width="25%">
          <template v-if="!showRow(row)">
            <o-icon :name="row.icon" />
          </template>
          <template v-else-if="showRow(row)">
            <b-field
              :message="fields.icon.error"
              :type="fields.icon.error !== null ? 'is-danger' : ''"
            >
              <o-icon-picker v-model="fields.icon.value" :icons="icons" :expanded="false" />
            </b-field>
          </template>
        </b-table-column>
        <b-table-column v-slot="{ row }" label="" width="100">
          <div v-if="!showRow(row)" class="buttons are-small is-right">
            <b-button type="is-primary" @click="selectedExternalLink = row">
              <b-icon icon="edit" />
            </b-button>
            <b-button type="is-danger" :loading="loading" @click="toggleModal(row)">
              <b-icon icon="trash" />
            </b-button>
          </div>
          <div v-else-if="showRow(row)" class="buttons are-small is-right">
            <b-button
              type="is-success"
              :loading="loading"
              :disabled="isFormInvalid"
              @click="submit"
            >
              <b-icon icon="check" />
            </b-button>
            <b-button type="is-warning" @click="selectedExternalLink = null">
              <b-icon icon="times" />
            </b-button>
          </div>
        </b-table-column>
      </template>
    </o-table>
    <o-modal
      :title="modalTitle"
      :isOpen="!!selectedExternalLink && isOpenModal"
      @closeModal="toggleModal"
      @confirmModalAction="deleteExternalLink"
    >
      <template> La suppression d'un lien est <strong>définitif</strong>. </template>
    </o-modal>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { isURL } from '@helpers/isURL'
import { iconNames } from '@data/icons'
import OTable from '@components/Table.vue'
import OModal from '@components/Modal.vue'
import OIconPicker from '@components/IconPicker.vue'

export default {
  name: 'LiveExternalLinkTable',

  components: {
    OTable,
    OModal,
    OIconPicker,
  },

  props: {
    liveExternalLinks: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      loading: false,
      isOpenModal: false,
      selectedExternalLink: null,
      fields: {
        url: {
          value: null,
          error: null,
        },
        name: {
          value: null,
          error: null,
        },
        icon: {
          value: null,
          error: null,
        },
      },
    }
  },

  created() {
    this.icons = iconNames
  },

  watch: {
    selectedExternalLink(externalLink) {
      if (!externalLink) {
        this.resetFields()
        return
      }

      this.fields.url.value = externalLink.url
      this.fields.name.value = externalLink.name
      this.fields.icon.value = externalLink.icon
    },

    'fields.name.value'(name) {
      this.fields.name.error = !name || name.length === 0 ? 'Nom requis' : null
    },

    'fields.url.value'(url) {
      this.fields.url.error =
        url && url.length > 0 && !isURL(url)
          ? 'Url incorrect'
          : !url || url.length === 0
          ? 'Url requise'
          : null
    },

    'fields.icon.value'(icon) {
      this.fields.icon.error = !icon || icon.length === 0 ? 'Icône requise' : null
    },
  },

  computed: {
    isFormInvalid() {
      return (
        Object.values(this.fields).find((field) => field.error !== null || field.value === null) ||
        this.loading
      )
    },

    modalTitle() {
      return `Suppression du lien ${
        this.selectedExternalLink && this.selectedExternalLink.position + 1
      }`
    },
  },

  methods: {
    ...mapActions('ui', ['addToastMessage']),

    async submit() {
      this.loading = true

      try {
        const updatedExternalLink = await this.$services.liveExternalLinkService.update({
          ...this.selectedExternalLink,
          url: this.fields.url.value,
          name: this.fields.name.value,
          icon: this.fields.icon.value,
        })

        this.selectedExternalLink = null
        this.$emit('submit', updatedExternalLink)

        this.addToastMessage({
          text: 'Le lien a été modifié.',
          type: 'is-success',
        })
      } catch (err) {
        this.addToastMessage({
          text: 'Une erreur interne est survenue.',
          type: 'is-danger',
        })
      }

      this.loading = false
    },

    async deleteExternalLink() {
      this.loading = true

      try {
        const deletedLiveExternalLink = await this.$services.liveExternalLinkService.delete(
          this.selectedExternalLink.id,
        )

        this.selectedExternalLink = null
        this.$emit('delete', deletedLiveExternalLink)

        this.addToastMessage({
          text: 'Le lien a été supprimé.',
          type: 'is-success',
        })
      } catch (err) {
        this.addToastMessage({
          text: 'Une erreur interne est survenue.',
          type: 'is-danger',
        })
      }

      this.loading = false
    },

    resetFields() {
      Object.values(this.fields).forEach((field) => {
        field.value = null
        field.error = null
      })
    },

    showRow(row) {
      return (
        this.selectedExternalLink && !this.isOpenModal && row.id === this.selectedExternalLink.id
      )
    },

    toggleModal(externalLink = null) {
      this.isOpenModal = !this.isOpenModal
      this.selectedExternalLink = externalLink
    },
  },
}
</script>
