import { FORMAT_DD, FORMAT_DDM, FORMAT_DMS } from '@filters/coordinate'

export const COORDINATE_FORMATS = [
  {
    name: 'Degrés décimaux (DD): ',
    example: '34.653, 58.576',
    value: FORMAT_DD,
  },
  {
    name: 'Degrés minutes (DDM): ',
    example: '34° 39.164′ E, 58° 34.533′ N',
    value: FORMAT_DDM,
  },
  {
    name: 'Degrés minutes secondes (DMS): ',
    example: '34° 39′ 9.860″S, 58° 34′ 31.973″W',
    value: FORMAT_DMS,
  },
]
