<template>
  <form :novalidate="true" @submit.prevent="submit">
    <b-field grouped group-multiline class="columns">
      <b-field
        label="*Label"
        :message="fields.label.error"
        :type="{ 'is-danger': fields.label.error }"
        class="column is-full-mobile"
      >
        <b-input
          v-model="fields.label.value"
          expanded
          placeholder="Label de la statistique"
          type="text"
          :icon-right="fields.label.value ? 'close-circle' : ''"
          :icon-right-clickable="!!fields.label.value"
          @icon-right-click="fields.label.value = null"
        />
      </b-field>
      <b-field
        label="*Valeur"
        :message="fields.value.error"
        :type="{ 'is-danger': fields.value.error }"
        class="column is-full-mobile"
      >
        <b-input
          v-model="fields.value.value"
          expanded
          placeholder="Valeur de la statistique"
          type="text"
          :icon-right="fields.value.value ? 'close-circle' : ''"
          :icon-right-clickable="!!fields.value.value"
          @icon-right-click="fields.value.value = null"
        />
      </b-field>
      <b-field
        label="*Icône"
        :message="fields.icon.error"
        :type="fields.icon.error !== null ? 'is-danger' : ''"
        class="column is-full-mobile"
      >
        <o-icon-picker
          v-model="fields.icon.value"
          :icons="icons"
          placeholder="Rechercher une icône"
        />
      </b-field>
    </b-field>
    <b-field>
      <b-button
        native-type="submit"
        type="is-primary"
        :loading="loading"
        :disabled="isFormInvalid"
        label="Ajouter"
      />
    </b-field>
  </form>
</template>

<script>
import { mapActions } from 'vuex'
import { iconNames } from '@data/icons'
import OIconPicker from '@components/IconPicker.vue'

export default {
  name: 'LiveAddStatForm',

  components: {
    OIconPicker,
  },

  props: {
    liveId: {
      type: String,
      required: true,
    },
    position: {
      type: Number,
      default: 0,
    },
  },

  data() {
    return {
      loading: false,
      fields: {
        label: {
          value: null,
          error: null,
        },
        value: {
          value: null,
          error: null,
        },
        icon: {
          value: null,
          error: null,
        },
      },
    }
  },

  created() {
    this.icons = iconNames
  },

  computed: {
    isFormInvalid() {
      return (
        Object.values(this.fields).find((field) => field.error !== null || field.value === null) ||
        this.loading
      )
    },
  },

  methods: {
    ...mapActions('ui', ['addToastMessage']),

    async submit() {
      this.loading = true

      try {
        const createdLiveStat = await this.$services.liveStatService.create({
          liveId: this.liveId,
          label: this.fields.label.value,
          value: this.fields.value.value,
          icon: this.fields.icon.value,
          position: this.position,
        })

        this.resetFields()
        this.$emit('submit', createdLiveStat)

        this.addToastMessage({
          text: 'La statistique a été créé.',
          type: 'is-success',
        })
      } catch (err) {
        this.addToastMessage({
          text: 'Une erreur interne est survenue.',
          type: 'is-danger',
        })
      }

      this.loading = false
    },

    resetFields() {
      Object.values(this.fields).forEach((field) => {
        field.value = null
        field.error = null
      })
    },
  },
}
</script>
