export const MAP_LAYER_OSM = 'osm'
export const MAP_LAYER_TERRAIN = 'terrain'
export const MAP_LAYER_SATELLITE = 'satellite'

export const MAP_LAYERS = [MAP_LAYER_OSM, MAP_LAYER_TERRAIN, MAP_LAYER_SATELLITE]

export const MAP_ZOOMS = Array.from({ length: 19 }, (_, i) => i)

export const MAP_APPROXIMATE_ZOOM_SCALE = {
  0: 5000000,
  1: 3000000,
  2: 2000000,
  3: 1000000,
  4: 500000,
  5: 300000,
  6: 200000,
  7: 100000,
  8: 50000,
  9: 20000,
  10: 10000,
  11: 5000,
  12: 3000,
  13: 1000,
  14: 500,
  15: 300,
  16: 200,
  17: 100,
  18: 50,
}
