<template>
  <page-layout title="Tutoriels">
    <template v-slot:breadcrumb>
      <li><router-link :to="{ name: 'home' }">Dashboard</router-link></li>
      <li class="is-active"><a href="#">Tutoriels</a></li>
    </template>
    <list-live-tutorials-action />
    <list-live-tutorials-filter @filters="filters = $event" />
    <list-live-tutorials-table
      :live-tutorials="liveTutorialsList"
      :loading="loading"
      @update="updateLiveTutorial"
      @drop="dropLiveTutorial"
      @delete="deleteLiveTutorial"
    />
  </page-layout>
</template>

<script>
import { mapActions } from 'vuex'
import PageLayout from '@components/PageLayout.vue'
import ListLiveTutorialsTable from './ListLiveTutorialsTable.vue'
import ListLiveTutorialsAction from './ListLiveTutorialsAction.vue'
import ListLiveTutorialsFilter from './ListLiveTutorialsFilter.vue'
import { stringNormalizer } from '@helpers/stringNormalizer'

export default {
  name: 'ListLiveTutorialsPage',

  components: {
    PageLayout,
    ListLiveTutorialsTable,
    ListLiveTutorialsAction,
    ListLiveTutorialsFilter,
  },

  data() {
    return {
      filters: null,
      loading: false,
      liveTutorials: [],
    }
  },

  metaInfo() {
    return {
      title: 'Tutoriels',
    }
  },

  async mounted() {
    await this.fetch()
  },

  computed: {
    liveTutorialsList() {
      return this.liveTutorials.filter(({ accessType, title }) => {
        const term = stringNormalizer(this.filters?.keyword?.toLowerCase())
        const normalizedTitle = stringNormalizer(title.fr.toLowerCase())

        return (
          normalizedTitle.indexOf(term) >= 0 &&
          (!this.filters?.accessType || this.filters.accessType === accessType)
        )
      })
    },
  },

  methods: {
    ...mapActions('ui', ['addToastMessage']),

    async fetch() {
      this.loading = true

      try {
        this.liveTutorials = await this.$services.liveTutorialService.getAll()
      } catch (err) {
        this.liveTutorials = []
        console.error(err)
      }

      this.loading = false
    },

    dropLiveTutorial(droppedOnRowIndex, draggingRowIndex) {
      this.liveTutorials.splice(
        droppedOnRowIndex,
        0,
        ...this.liveTutorials.splice(draggingRowIndex, 1),
      )

      this.updatePositions()
    },

    async updatePositions() {
      this.loading = true

      for (const [index, liveTutorial] of this.liveTutorials.entries()) {
        if (liveTutorial.position === index) {
          continue
        }

        this.liveTutorials[index].position = index

        try {
          await this.$services.liveTutorialService.update({
            ...liveTutorial,
            position: index,
          })
        } catch (err) {
          this.addToastMessage({
            text: 'Une erreur interne est survenue.',
            type: 'is-danger',
          })
        }
      }

      this.loading = false
    },

    async deleteLiveTutorial(liveTutorial) {
      this.loading = true

      try {
        const deletedLiveTutorial = await this.$services.liveTutorialService.delete(liveTutorial.id)
        this.liveTutorials = this.liveTutorials.filter(
          (tutorial) => tutorial.id !== deletedLiveTutorial.id,
        )

        await this.updatePositions()

        this.addToastMessage({
          text: `Le tutoriel a été supprimé.`,
          type: 'is-success',
        })
      } catch (err) {
        this.addToastMessage({
          text: err.message ? err.message : 'Une erreur interne est survenue.',
          type: 'is-danger',
        })
      }

      this.loading = false
    },

    async updateLiveTutorial(liveTutorial) {
      this.liveTutorials = this.liveTutorials.map((tutorial) =>
        tutorial.id === liveTutorial.id ? liveTutorial : tutorial,
      )
    },
  },
}
</script>
