var render = function render(){var _vm=this,_c=_vm._self._c;return _c('o-table',{attrs:{"items":_vm.liveVehicles,"loading":_vm.loading,"draggable":false,"default-sort":"number"}},[_c('b-table-column',{attrs:{"field":"accessType","label":"Accès","centered":""},scopedSlots:_vm._u([{key:"default",fn:function({ row }){return [(row.accessType === 'public')?_c('b-icon',{attrs:{"size":"is-small","type":"is-success","icon":"unlock"}}):(row.accessType === 'private')?_c('b-icon',{attrs:{"size":"is-small","type":"is-danger","icon":"lock"}}):_vm._e()]}}])}),_vm._v(" "),_c('b-table-column',{attrs:{"field":"trackers","label":"Type"},scopedSlots:_vm._u([{key:"default",fn:function({ row }){return [(row.trackers.length > 0)?[_vm._v("\n      "+_vm._s(row.trackers[0].type)+"\n    ")]:_vm._e()]}}])}),_vm._v(" "),_c('b-table-column',{attrs:{"field":"trackers","label":"Balise","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function({ row }){return [(row.trackers.length > 0)?_c('router-link',{attrs:{"to":{
        name: `${_vm.trackerRoutes[row.trackers[0].type].name}.edit`,
        params: { id: row.trackers[0].id },
      }}},[_vm._v("\n      "+_vm._s(row.trackers[0].number)+"\n    ")]):_vm._e()]}}])}),_vm._v(" "),_c('b-table-column',{attrs:{"field":"number","label":"Dossard","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function({ row }){return [_vm._v("\n    "+_vm._s(row.number)+"\n  ")]}}])}),_vm._v(" "),_c('b-table-column',{attrs:{"field":"name","label":"Nom","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function({ row }){return [_vm._v("\n    "+_vm._s(row.name)+"\n  ")]}}])}),_vm._v(" "),_c('b-table-column',{attrs:{"field":"gender","label":"Genre"},scopedSlots:_vm._u([{key:"default",fn:function({ row }){return [(row.gender === 'male')?_c('b-icon',{attrs:{"type":"is-info","icon":"mars"}}):(row.gender === 'female')?_c('b-icon',{attrs:{"type":"is-danger","icon":"venus"}}):(row.gender === 'mixed')?_c('o-icon',{attrs:{"name":"venus-mars"}}):_vm._e()]}}])}),_vm._v(" "),_c('b-table-column',{attrs:{"field":"country","label":"Pays"},scopedSlots:_vm._u([{key:"default",fn:function({ row }){return [_vm._v("\n    "+_vm._s(row.country)+"\n  ")]}}])}),_vm._v(" "),_c('b-table-column',{attrs:{"field":"liveCategory","label":"Catégorie"},scopedSlots:_vm._u([{key:"default",fn:function({ row }){return [_vm._v("\n    "+_vm._s(row.categoryName)+"\n  ")]}}])}),_vm._v(" "),_c('b-table-column',{attrs:{"label":""},scopedSlots:_vm._u([{key:"default",fn:function({ row }){return [_c('div',{staticClass:"buttons are-small is-right"},[_c('b-button',{attrs:{"download":""},on:{"click":function($event){return _vm.$emit('exportGpx', row)}}},[_c('b-icon',{attrs:{"icon":"file-export"}})],1),_vm._v(" "),_c('b-button',{attrs:{"type":"is-primary","tag":"router-link","to":{
          name: 'live.edit.vehicle.edit',
          params: { liveVehicleId: row.id },
        }}},[_c('b-icon',{attrs:{"icon":"edit"}})],1),_vm._v(" "),_c('b-button',{attrs:{"type":"is-danger"},on:{"click":function($event){return _vm.$emit('delete', row)}}},[_c('b-icon',{attrs:{"icon":"trash"}})],1)],1)]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }