<template>
  <div>
    <b-button :type="type" :icon-left="icon" :label="label" :size="size" @click="openModal" />
    <o-modal
      :title="title"
      :isOpen="showModal"
      @closeModal="closeModal"
      @confirmModalAction="confirmModalAction"
    >
      <slot />
    </o-modal>
  </div>
</template>

<script>
import OModal from '@components/Modal.vue'

export default {
  name: 'OModalButton',

  components: {
    OModal,
  },

  props: {
    label: {
      type: String,
      default: null,
    },
    icon: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      required: true,
      validator: (v) => ['is-danger', 'is-success', 'is-primary'].indexOf(v) !== -1,
    },
    size: {
      type: String,
      default: '',
      validator: (v) => ['', 'is-small', 'is-medium', 'is-large'].indexOf(v) !== -1,
    },
    title: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      showModal: false,
    }
  },

  methods: {
    openModal() {
      this.showModal = true
    },

    closeModal() {
      this.showModal = false
    },

    confirmModalAction() {
      this.$emit('confirmModalAction')
      this.showModal = false
    },
  },
}
</script>
