<template>
  <div>
    <h2 class="title is-4">Bilan</h2>
    <edit-live-review-tracker :live="live" />
  </div>
</template>

<script>
import EditLiveReviewTracker from './EditLiveReviewTracker'

export default {
  name: 'EditLiveReview',

  components: {
    EditLiveReviewTracker,
  },

  props: {
    live: {
      type: Object,
      required: true,
    },
  },
}
</script>
