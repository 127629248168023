<template>
  <div class="mt-5 pb-5">
    <b-button
      tag="router-link"
      type="is-success"
      :to="{ name: 'live.edit.stage.edit.speedZone.add' }"
      icon-left="plus"
      label="Nouveau"
      class="mb-4"
    />
    <div class="level">
      <div class="level-left">
        <h3 class="title is-5 level-item">Zones de vitesse</h3>
      </div>
      <div class="level-right" />
    </div>
    <live-stage-speed-zone-table
      :loading="loading"
      :stage-speed-zones="stageSpeedZones"
      :live-categories="liveCategories"
      @submit="updateSpeedZone"
      @drop="dropSpeedZone"
      @delete="deleteSpeedZone"
    />
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import LiveStageSpeedZoneTable from './LiveStageSpeedZoneTable.vue'

export default {
  name: 'EditLiveStageSpeedZoneTab',

  components: {
    LiveStageSpeedZoneTable,
  },

  props: {
    live: {
      type: Object,
      required: true,
      validator: (v) => ['name'].every((key) => key in v),
    },
    liveStage: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      loading: false,
      stageSpeedZones: [],
      liveCategories: [],
    }
  },

  async mounted() {
    await this.load()
  },

  watch: {
    stageSpeedZones(stageSpeedZones) {
      this.$emit('update:stageSpeedZones', stageSpeedZones)
    },
  },

  methods: {
    ...mapActions('ui', ['addToastMessage']),

    async load() {
      this.loading = true

      try {
        const [stageSpeedZones, liveCategories] = await Promise.all([
          this.$services.liveStageSpeedZoneService.getAll(this.liveStage.id),
          this.$services.liveCategoryService.getAll(this.live.id),
        ])

        this.stageSpeedZones = stageSpeedZones.map((speedZone) => {
          if (speedZone.liveStageWaypointId) {
            const stageRoute = this.stageRoutes.private.find(
              (route) => route.id === speedZone.liveStageWaypointId,
            )

            if (stageRoute) {
              speedZone.liveStageRoutePath = stageRoute.path
            }
          }

          return speedZone
        })

        this.liveCategories = liveCategories
      } catch (err) {
        this.stageSpeedZones = []
        this.liveCategories = []
      }

      this.loading = false
    },

    async updateSpeedZone(speedZone) {
      this.loading = true

      try {
        const updatedSpeedZone = await this.$services.liveStageSpeedZoneService.update(
          speedZone.id,
          {
            ...speedZone,
          },
        )

        this.stageSpeedZones = this.stageSpeedZones.map((stageSpeedZone) =>
          stageSpeedZone.id === updatedSpeedZone.id ? updatedSpeedZone : stageSpeedZone,
        )

        this.addToastMessage({
          text: `La zone de vitesse "${updatedSpeedZone.name}" a été mise à jour.`,
          type: 'is-success',
        })
      } catch (err) {
        this.addToastMessage({
          text: 'Une erreur interne est survenue.',
          type: 'is-danger',
        })
      }

      this.loading = false
    },

    dropSpeedZone(droppedOnRowIndex, draggingRowIndex) {
      this.stageSpeedZones.splice(
        droppedOnRowIndex,
        0,
        ...this.stageSpeedZones.splice(draggingRowIndex, 1),
      )

      this.updatePositions()
    },

    async updatePositions() {
      this.loading = true

      for (const [index, zone] of this.stageSpeedZones.entries()) {
        if (zone.position === index) {
          continue
        }

        this.stageSpeedZones[index].position = index

        try {
          await this.$services.liveStageSpeedZoneService.update(zone.id, {
            ...zone,
            position: index,
          })
        } catch (err) {
          this.addToastMessage({
            text: 'Une erreur interne est survenue.',
            type: 'is-danger',
          })
        }
      }

      this.loading = false
    },

    async deleteSpeedZone(speedZone) {
      this.loading = true

      try {
        const deletedSpeedZone = await this.$services.liveStageSpeedZoneService.delete(speedZone.id)
        this.stageSpeedZones = this.stageSpeedZones.filter(
          (zone) => zone.id !== deletedSpeedZone.id,
        )

        this.addToastMessage({
          text: `La zone de vitesse "${deletedSpeedZone.name}" a été supprimée.`,
          type: 'is-success',
        })
      } catch (err) {
        this.addToastMessage({
          text: err.message ? err.message : 'Une erreur interne est survenue.',
          type: 'is-danger',
        })
      }

      this.loading = false
    },
  },
}
</script>
