<template>
  <b-dropdown
    :value="value"
    :position="position"
    :scrollable="scrollable"
    :expanded="expanded"
    aria-role="list"
    @input="(input) => $emit('input', input)"
  >
    <template #trigger="{ active }">
      <b-button
        type="is-outlined"
        :icon-right="active ? 'chevron-up' : 'chevron-down'"
        expanded
        class="is-justify-content-space-between"
      >
        <slot name="label" v-bind:label="value">
          {{ value }}
        </slot>
      </b-button>
    </template>
    <b-dropdown-item v-for="item in items" :key="item" :value="item" aria-role="listitem">
      <slot name="item" v-bind:item="item">
        {{ item }}
      </slot>
    </b-dropdown-item>
  </b-dropdown>
</template>

<script>
export default {
  name: 'ODropdown',

  props: {
    items: {
      type: Array,
      required: true,
    },
    value: {
      type: [String, Number],
      default: null,
    },
    position: {
      type: String,
      default: 'is-top-right',
      validator: (v) =>
        ['is-top-right', 'is-top-right', 'is-top-left', 'is-bottom-left'].indexOf(v) !== -1,
    },
    scrollable: {
      type: Boolean,
      default: true,
    },
    expanded: {
      type: Boolean,
      default: true,
    },
  },
}
</script>
