<template>
  <div class="box">
    <edit-live-settings-form :live="live" />
  </div>
</template>

<script>
import EditLiveSettingsForm from './forms/EditLiveSettingsForm.vue'

export default {
  name: 'EditLiveEditSettingsTab',

  components: {
    EditLiveSettingsForm,
  },

  props: {
    live: {
      type: Object,
      required: true,
    },
  },
}
</script>
