<template>
  <div class="is-flex is-flex-direction-column">
    <span> <b-icon size="is-small" type="is-info" icon="copyright" /> : Checkpoint </span>
    <span>
      <b-icon size="is-small" type="is-warning" icon="exclamation-triangle" /> : Caractères maximum
      recommandés atteint (30)
    </span>
  </div>
</template>

<script>
export default {
  name: 'LiveStageWaypointCaption',
}
</script>
