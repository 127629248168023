<template>
  <div class="is-flex is-flex-direction-column is-align-items-center">
    <o-message :errorMessages="errorImportMessages" title="Erreurs d'importation" />
    <o-upload
      v-show="showUploadButton"
      v-model="csvFile"
      label="Import CSV"
      type="text/csv, application/vnd.ms-excel"
      @submit="submitCsvFile"
      @deleteFile="deleteCsvFile"
    />
  </div>
</template>

<script>
import { importCsv } from '@helpers/importCsv'
import {
  getErrorWrongColumn,
  getErrorMessages,
  importLiveVehicleError,
} from '@helpers/importErrorMessages'
import { csvHeader } from './csvHeader.js'
import OUpload from '@components/Upload.vue'
import OMessage from '@components/Message.vue'

export default {
  name: 'LiveVehicleImportCsv.vue',

  components: {
    OMessage,
    OUpload,
  },

  props: {
    live: {
      type: Object,
      required: true,
    },
    showUploadButton: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      csvFile: null,
      errorImportMessages: [],
    }
  },

  methods: {
    submitCsvFile() {
      this.$emit('submit')
      this.errorImportMessages = []

      const reader = new FileReader()
      reader.onload = async () => {
        try {
          const columns = importCsv(reader.result).map((e) => Object.keys(e))[0]
          const expectedColumns = Object.values(csvHeader)

          if (getErrorWrongColumn(columns, expectedColumns)) {
            this.errorImportMessages.push(getErrorWrongColumn(columns, expectedColumns))
            this.$emit('error')
          } else {
            const vehicles = this.formatVehiclesData(reader.result)
            const liveVehicles = await this.$services.liveVehicleService.saveAll(
              this.live.id,
              vehicles,
            )

            this.$emit('done', liveVehicles)
          }
        } catch (err) {
          this.$emit('error')
          this.errorImportMessages = getErrorMessages(err, importLiveVehicleError)
        }
      }
      reader.readAsText(this.csvFile)

      this.deleteCsvFile()
    },

    deleteCsvFile() {
      this.csvFile = null
    },

    formatVehiclesData(data) {
      return importCsv(data).map((vehicle) => ({
        name: vehicle[csvHeader.name],
        gender: vehicle[csvHeader.gender].trim() ? vehicle[csvHeader.gender] : null,
        country: vehicle[csvHeader.country].trim() ? vehicle[csvHeader.country] : null,
        comment: vehicle[csvHeader.comment].trim() ? vehicle[csvHeader.comment] : null,
        number: vehicle[csvHeader.number],
        trackerNumber: vehicle[csvHeader.trackerNumber],
        trackerType: vehicle[csvHeader.type],
        accessType: vehicle[csvHeader.accessType],
        liveCategoryName: vehicle[csvHeader.categoryName],
      }))
    },
  },
}
</script>
