<template>
  <div class="level is-flex-direction-column">
    <div class="level-right ml-auto">
      <p class="level-item">
        <b-input
          :value="$route.query.keyword"
          type="text"
          placeholder="Nom..."
          icon-right-clickable
          :icon-right="$route.query.keyword ? 'close-circle' : ''"
          @icon-right-click="$router.replace({ query: { ...$route.query, keyword: undefined } })"
          @keyup.native.enter="$router.push({ query: { keyword: $event.target.value } })"
        />
      </p>
      <p class="level-item">
        <b-select
          :value="$route.query.status"
          placeholder="Choisir un status"
          icon="rotate"
          @input="$router.push({ query: { ...$route.query, status: $event } })"
        >
          <option :value="null">Choisir un status</option>
          <option value="pending">En attente</option>
          <option value="completed">En cours</option>
          <option value="finished">Terminé</option>
        </b-select>
      </p>
      <p class="level-item">
        <b-select
          :value="$route.query.visitorAccessType"
          placeholder="Choisir par accès"
          icon="lock"
          @input="$router.push({ query: { ...$route.query, visitorAccessType: $event } })"
        >
          <option :value="null">Choisir par accès</option>
          <option value="public">Public</option>
          <option value="private">Privé</option>
          <option value="protected">Protégé</option>
        </b-select>
      </p>
    </div>
    <div class="level-right ml-auto">
      <p class="level-item">
        <b-button
          v-show="showClearFilterButton"
          class="is-small"
          type="is-text"
          @click="$router.push({ query: {} })"
        >
          Supprimer tous les filtres
        </b-button>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ListLivesFilter',

  computed: {
    showClearFilterButton() {
      return Object.values(this.$route.query).filter((v) => v).length > 0
    },
  },
}
</script>
