<template>
  <div>
    <b-field :label="label" :message="errorMessage" :type="{ 'is-danger': errorMessage }">
      <b-upload
        :value="value"
        drag-drop
        native
        expanded
        :loading="loading"
        @input="$emit('input', $event)"
      >
        <section class="p-5">
          <div class="content has-text-centered">
            <p>
              <b-icon icon="upload" size="is-medium" />
            </p>
            <p v-show="!loading" style="overflow-wrap: anywhere">{{ text }}</p>
          </div>
        </section>
      </b-upload>
    </b-field>
    <div class="mt-2">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'DragDrop',

  props: {
    label: {
      type: String,
      default: '',
    },
    textContent: {
      type: String,
      default: 'Déposez votre fichier',
    },
    loading: {
      type: Boolean,
      default: false,
    },
    errorMessage: {
      validator: (v) => typeof v === 'string' || v === null,
    },
    value: {
      validator: (v) => v === null || v instanceof File,
    },
  },

  computed: {
    text() {
      return this.value ? this.value.name : this.textContent
    },
  },
}
</script>
