<template>
  <div>
    <o-table
      :items="liveTutorials"
      :loading="loading"
      default-sort="position"
      :detailed="true"
      @drop="
        (droppedOnRowIndex, draggingRowIndex) => $emit('drop', droppedOnRowIndex, draggingRowIndex)
      "
    >
      <b-table-column v-slot="{ row }" field="accessType" label="Accès" centered sortable>
        <b-icon
          v-if="row.accessType === 'public'"
          size="is-small"
          type="is-success"
          icon="unlock"
        />
        <b-icon
          v-else-if="row.accessType === 'private'"
          size="is-small"
          type="is-danger"
          icon="lock"
        />
      </b-table-column>
      <b-table-column v-slot="{ row }" field="title" label="Titre" sortable>
        {{ row.title.fr }}
      </b-table-column>
      <b-table-column v-slot="{ row }" field="updatedAt" label="Date de modification" sortable>
        {{ row.updatedAt | date }}
      </b-table-column>
      <b-table-column v-slot="{ row, toggleDetails }" label="">
        <div class="buttons are-small is-right">
          <b-button type="is-primary" icon-right="pen" @click="toggleDetails(row)" />
          <b-button
            type="is-danger"
            icon-right="trash"
            @click="
              isOpenModal = true
              selectedLiveTutorial = row
            "
          />
        </div>
      </b-table-column>

      <template #detail="{ row, toggleDetails }">
        <edit-live-tutorial
          :live-tutorial="row"
          @update="
            toggleDetails(row)
            $emit('update', $event)
          "
        />
      </template>
    </o-table>
    <o-modal
      :title="modalTitle"
      :isOpen="!!selectedLiveTutorial && isOpenModal"
      @closeModal="isOpenModal = false"
      @confirmModalAction="$emit('delete', selectedLiveTutorial)"
    >
      <template> La suppression d'un tutoriel est <strong>définitive</strong>. </template>
    </o-modal>
  </div>
</template>

<script>
import { format } from 'date-fns'
import OTable from '@components/Table.vue'
import EditLiveTutorial from '@views/LiveTutorial/EditLiveTutorial.vue'
import OModal from '@components/Modal.vue'

export default {
  name: 'ListLiveTutorialsTable',

  components: {
    OModal,
    OTable,
    EditLiveTutorial,
  },

  props: {
    loading: {
      type: Boolean,
      required: true,
    },
    liveTutorials: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      isOpenModal: false,
      selectedLiveTutorial: null,
    }
  },

  computed: {
    modalTitle() {
      return `Suppression du tutoriel '${
        this.selectedLiveTutorial && this.selectedLiveTutorial.title.fr
      }'`
    },
  },

  filters: {
    date(value) {
      return format(value, 'dd/MM/yyyy')
    },
  },
}
</script>
