<template>
  <div class="level">
    <div class="level-left">
      <p class="level-item">
        <b-button
          tag="router-link"
          type="is-success"
          :to="{ name: 'tutorial.add' }"
          icon-left="plus"
          label="Nouveau"
        />
      </p>
    </div>
    <div class="level-right" />
  </div>
</template>

<script>
export default {
  name: 'ListLiveTutorialsAction',
}
</script>
