<template>
  <o-tabs v-if="liveStage" :labels="tabItems">
    <template #0>
      <div class="card">
        <div class="card-header">
          <h2 class="card-header-title">Informations de l'étape</h2>
        </div>
        <div class="card-content">
          <edit-live-stage-form :live="live" :liveStage.sync="liveStage" />
        </div>
      </div>
    </template>
    <template #1>
      <h1 class="title is-4">{{ liveStage.name }}</h1>
      <live-stage-map
        :stage-routes="stageRoutes"
        :stage-waypoints="stageWaypoints"
        :route-opacity="0.5"
        class="card mb-3"
      />
      <edit-live-stage-waypoint-tab
        :live="live"
        :liveStage="liveStage"
        @update:stageWaypoints="stageWaypoints = $event"
      />
    </template>
    <template #2>
      <h1 class="title is-4">{{ liveStage.name }}</h1>
      <live-stage-map
        :stage-routes="stageRoutes"
        :stage-waypoints="stageWaypoints"
        :waypoint-opacity="0.5"
        class="card mb-3"
      />
      <edit-live-stage-route-tab
        v-for="accessType in ['public', 'private']"
        :key="'edit-live-stage-route-tab' + accessType"
        :live="live"
        :liveStage="liveStage"
        :accessType="accessType"
        @update:stageRoutes="stageRoutes[accessType] = $event"
      />
      <edit-live-stage-path-tab :live="live" :liveStage="liveStage" />
    </template>
    <template #3>
      <h1 class="title is-4">{{ liveStage.name }}</h1>
      <live-stage-map
        class="card mb-3"
        :stage-waypoints="stageWaypoints"
        :stage-routes="stageRoutes"
        :stage-speed-zones="stageSpeedZones"
        :route-opacity="0.5"
        :waypoint-opacity="0.5"
      />
      <edit-live-stage-zone-tab
        :live="live"
        :liveStage="liveStage"
        :stage-speed-zones="stageSpeedZones"
        @update:stageSpeedZones="stageSpeedZones = $event"
      />
    </template>
  </o-tabs>
</template>

<script>
import OTabs from '@components/Tabs.vue'
import LiveStageMap from '@views/MapView/LiveStageMap'
import EditLiveStageForm from './forms/EditLiveStageForm.vue'
import EditLiveStageRouteTab from './EditLiveStageRouteTab/index.vue'
import EditLiveStagePathTab from './EditLiveStagePathTab/index.vue'
import EditLiveStageWaypointTab from './EditLiveStageWaypointTab/index.vue'
import EditLiveStageZoneTab from './EditLiveStageSpeedZoneTab/index.vue'

export default {
  name: 'EditLiveEditStageTab',

  components: {
    OTabs,
    LiveStageMap,
    EditLiveStageForm,
    EditLiveStageZoneTab,
    EditLiveStagePathTab,
    EditLiveStageRouteTab,
    EditLiveStageWaypointTab,
  },

  props: {
    live: {
      type: Object,
      required: true,
    },
    liveStageId: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      loading: false,
      liveStage: null,
      stageWaypoints: [],
      stageSpeedZones: [],
      stageRoutes: { public: [], private: [] },
    }
  },

  created() {
    this.tabItems = ['Général', 'Waypoints', 'Parcours', 'Zones de vitesse']
  },

  async mounted() {
    this.loading = true

    await this.fetchLiveStage()
    await Promise.all([
      this.fetchWaypoints(),
      this.fetchStageByAccessType('public'),
      this.fetchStageByAccessType('private'),
      this.fetchStageSpeedZones(),
    ])

    this.loading = false
  },

  methods: {
    async fetchLiveStage() {
      try {
        this.liveStage = await this.$services.liveStageService.getById(this.liveStageId)
      } catch (e) {
        this.liveStage = null
      }
    },

    async fetchWaypoints() {
      try {
        this.stageWaypoints = await this.$services.liveStageWaypointService.getAll(
          this.liveStage.id,
        )
      } catch (e) {
        this.stageWaypoints = []
      }
    },

    async fetchStageSpeedZones() {
      try {
        this.stageSpeedZones = await this.$services.liveStageSpeedZoneService.getAll(
          this.liveStage.id,
        )
      } catch (err) {
        this.stageSpeedZones = []
      }
    },

    async fetchStageByAccessType(accessType) {
      try {
        this.stageRoutes[accessType] =
          await this.$services.liveStageRouteService.getAllByAccessType(
            this.liveStage.id,
            accessType,
          )
      } catch (e) {
        this.stageRoutes[accessType] = []
      }
    },
  },
}
</script>
