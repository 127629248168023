<template>
  <div class="box">
    <o-table
      :items="stageSpeedZones"
      :loading="loading"
      :draggable="!selectedSpeedZone"
      @drop="
        (droppedOnRowIndex, draggingRowIndex) => $emit('drop', droppedOnRowIndex, draggingRowIndex)
      "
    >
      <b-table-column v-slot="{ row }" field="name" label="Nom">
        <template v-if="!showRow(row)">
          {{ row.name }}
        </template>
        <template v-else>
          <b-field
            :message="fields.name.error"
            :type="fields.name.error !== null ? 'is-danger' : ''"
          >
            <b-input
              v-model="fields.name.value"
              type="text"
              :icon-right="fields.name.value ? 'close-circle' : ''"
              :icon-right-clickable="!!fields.name.value"
              @icon-right-click="fields.name.value = null"
            />
          </b-field>
        </template>
      </b-table-column>
      <b-table-column v-slot="{ row }" field="color" label="Couleur" centered>
        <template v-if="!showRow(row)">
          {{ row.color }}
        </template>
        <template v-else>
          <b-field
            :message="fields.color.error"
            :type="fields.color.error !== null ? 'is-danger' : ''"
          >
            <o-dropdown
              v-model="fields.color.value"
              :items="colors"
              @input="(value) => (fields.color.value = value)"
            />
          </b-field>
        </template>
      </b-table-column>
      <b-table-column v-slot="{ row }" field="speed" label="Vitesse limite">
        <template v-if="!showRow(row)">
          {{ row.speed }}
        </template>
        <template v-else>
          <b-field
            :message="fields.speed.error"
            :type="fields.speed.error !== null ? 'is-danger' : ''"
          >
            <b-input v-model="fields.speed.value" type="number" />
          </b-field>
        </template>
      </b-table-column>
      <b-table-column v-slot="{ row }" field="distance" label="Largeur de zone">
        <template v-if="!showRow(row)">
          {{ row.distance }}
        </template>
        <template v-else>
          <b-field
            :message="fields.distance.error"
            :type="fields.distance.error !== null ? 'is-danger' : ''"
          >
            <b-input v-model="fields.distance.value" type="number" />
          </b-field>
        </template>
      </b-table-column>
      <b-table-column v-slot="{ row }" field="liveCategoryId" label="Catégorie">
        <template v-if="!showRow(row)">
          {{ getCategoryName(row.liveCategoryId) }}
        </template>
        <template v-else>
          <b-field>
            <b-select v-model="fields.liveCategoryId.value" expanded>
              <option :value="null">—</option>
              <option
                v-for="category in filteredCategories"
                :key="category.id"
                :value="category.id"
              >
                {{ category.name }}
              </option>
            </b-select>
          </b-field>
        </template>
      </b-table-column>
      <b-table-column v-slot="{ row }" width="100">
        <div v-if="!showRow(row)" class="buttons are-small is-right">
          <b-button type="is-primary" :loading="loading" @click="selectedSpeedZone = row">
            <b-icon icon="edit" />
          </b-button>
          <b-button type="is-danger" :loading="loading" @click="toggleModal(row)">
            <b-icon icon="trash" />
          </b-button>
        </div>
        <div v-else class="buttons are-small is-right">
          <b-button type="is-success" :loading="loading" :disabled="isFormInvalid" @click="submit">
            <b-icon icon="check" />
          </b-button>
          <b-button type="is-warning" @click="selectedSpeedZone = null">
            <b-icon icon="times" />
          </b-button>
        </div>
      </b-table-column>
    </o-table>
    <o-modal
      :title="modalTitle"
      :isOpen="!!selectedSpeedZone && isOpenModal"
      @closeModal="toggleModal"
      @confirmModalAction="deleteSpeedZone"
    >
      <template> La suppression d'une zone est <strong>définitive</strong>. </template>
    </o-modal>
  </div>
</template>

<script>
import OModal from '@components/Modal.vue'
import OTable from '@components/Table.vue'
import ODropdown from '@components/Dropdown.vue'
import { COLORS } from '@constants/stage/color'

export default {
  name: 'EditLiveStageSpeedZoneTable',

  components: {
    OModal,
    OTable,
    ODropdown,
  },

  props: {
    loading: {
      type: Boolean,
      required: true,
    },
    stageSpeedZones: {
      type: Array,
      required: true,
    },
    liveCategories: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      isOpenModal: false,
      selectedSpeedZone: null,
      fields: {
        name: {
          value: '',
          error: null,
        },
        color: {
          value: '',
          error: null,
        },
        speed: {
          value: '',
          error: null,
        },
        distance: {
          value: '',
          error: null,
        },
        liveCategoryId: {
          value: null,
          error: null,
        },
      },
    }
  },

  created() {
    this.colors = COLORS
  },

  watch: {
    selectedSpeedZone(speedZone) {
      if (!speedZone) {
        this.resetFields()
        return
      }

      this.fields.name.value = speedZone.name
      this.fields.color.value = speedZone.color
      this.fields.speed.value = speedZone.speed
      this.fields.distance.value = speedZone.distance
      this.fields.liveCategoryId.value = speedZone.liveCategoryId
    },

    'fields.name.value'() {
      this.fields.name.error = this.fieldNameError
    },

    'fields.color.value'() {
      this.fields.color.error = this.fieldColorError
    },

    'fields.speed.value'() {
      this.fields.speed.error = this.fieldSpeedError
    },

    'fields.distance.value'() {
      this.fields.distance.error = this.fieldDistanceError
    },
  },

  computed: {
    fieldNameError() {
      return this.fields.name.value?.length > 0 ? null : 'Le nom est requis'
    },

    fieldColorError() {
      return this.fields.color.value?.length > 0 ? null : 'La couleur est requise'
    },

    fieldSpeedError() {
      return this.fields.speed.value > 0 ? null : 'La vitesse limite est requise'
    },

    fieldDistanceError() {
      return this.fields.distance.value > 0 ? null : 'La largeur de zone est requise'
    },

    isFormInvalid() {
      return Object.values(this.fields).find((field) => field.error !== null) || this.loading
    },

    modalTitle() {
      return `Suppression de la zone ${this.selectedSpeedZone && this.selectedSpeedZone.name}`
    },

    filteredCategories() {
      return this.liveCategories.filter((category) => category.useCheckpoint)
    },
  },

  methods: {
    async submit() {
      if (this.isFormInvalid) {
        return
      }

      this.$emit('submit', {
        ...this.selectedSpeedZone,
        name: this.fields.name.value,
        color: this.fields.color.value,
        speed: +this.fields.speed.value,
        distance: +this.fields.distance.value,
        liveCategoryId: this.fields.liveCategoryId.value,
      })

      this.selectedSpeedZone = null
    },

    deleteSpeedZone() {
      this.$emit('delete', this.selectedSpeedZone)
    },

    resetFields() {
      Object.values(this.fields).forEach((field) => {
        field.value = null
        field.error = null
      })
    },

    showRow(row) {
      return this.selectedSpeedZone && !this.isOpenModal && row.id === this.selectedSpeedZone.id
    },

    toggleModal(speedZone = null) {
      this.isOpenModal = !!speedZone
      this.selectedSpeedZone = speedZone
    },

    getCategoryName(categoryId) {
      const category = this.filteredCategories.find((cat) => cat.id === categoryId)

      return category?.name || '—'
    },
  },
}
</script>
