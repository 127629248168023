function compute(value) {
  return value.cardinalDirection.toLowerCase() === 's' ||
    value.cardinalDirection.toLowerCase() === 'w'
    ? -(+value.degrees + value.minutes / 60 + value.seconds / 3600)
    : +value.degrees + value.minutes / 60 + value.seconds / 3600
}

export function dmsToLatLng(coordinate) {
  const cardinalDirections = coordinate.replace(/[^a-zA-Z]+/g, '').split('')
  const coordinateNumbers = coordinate
    .replace(/[^0-9-.]+/g, ' ')
    .split(' ')
    .filter((v) => v !== '')

  if (coordinateNumbers.length !== 6 || coordinateNumbers.find((number) => isNaN(number))) {
    return null
  }

  const latitude = {
    degrees: coordinateNumbers[0],
    minutes: coordinateNumbers[1],
    seconds: coordinateNumbers[2],
    cardinalDirection: cardinalDirections[0],
  }

  const longitude = {
    degrees: coordinateNumbers[3],
    minutes: coordinateNumbers[4],
    seconds: coordinateNumbers[5],
    cardinalDirection: cardinalDirections[1],
  }

  return [compute(latitude), compute(longitude)]
}
