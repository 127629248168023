<template>
  <div class="mt-5 pb-5">
    <b-button
      tag="router-link"
      type="is-success"
      :to="{ name: 'live.edit.alertContact.add' }"
      icon-left="plus"
      label="Nouveau"
      class="mb-4"
    />
    <div class="level">
      <div class="level-left">
        <h3 class="title is-5 level-item">Contacts d'alerte</h3>
      </div>
      <div class="level-right" />
    </div>
    <list-live-alert-contact-table
      v-for="(liveAlertContacts, alertType) in alertContacts"
      :key="alertType"
      class="box"
      :alert-type="alertType"
      :loading="loading"
      :live-alert-contacts="liveAlertContacts"
      @update="updateAlertContact"
      @delete="deleteAlertContact"
    />
    <div v-if="hasSpotTrackers" class="box">
      <list-spot-tracker-profiles
        :live="live"
        :liveSpotTrackerConfig="liveSpotTrackerConfig"
        class="mb-5"
        @input="liveSpotTrackerConfig = $event"
      />
      <switch-spot-tracker-profiles
        v-if="liveSpotTrackerConfig"
        :live="live"
        :live-vehicles="liveVehicles"
        :liveSpotTrackerConfig="liveSpotTrackerConfig"
      />
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import ListLiveAlertContactTable from './ListLiveAlertContactTable.vue'
import ListSpotTrackerProfiles from './ListSpotTrackerProfiles.vue'
import SwitchSpotTrackerProfiles from './SwitchSpotTrackerProfiles'
import {
  ALERT_TYPE_MEDICAL_HELP,
  ALERT_TYPE_TECHNICAL_HELP,
  ALERT_TYPE_OK,
} from '@constants/alert/type'

export default {
  name: 'LiveAlertContact',

  components: {
    ListLiveAlertContactTable,
    ListSpotTrackerProfiles,
    SwitchSpotTrackerProfiles,
  },

  props: {
    live: {
      type: Object,
      required: true,
    },
    liveVehicles: {
      type: Array,
      required: true,
    },
    hasSpotTrackers: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      loading: false,
      liveAlertContacts: [],
      liveSpotTrackerConfig: null,
    }
  },

  async created() {
    await this.fetchLiveAlertContacts()

    if (this.hasSpotTrackers) {
      await this.fetchLiveSpotTrackerConfig()
    }
  },

  computed: {
    alertContacts() {
      const defaultAlertContacts = {
        [ALERT_TYPE_OK]: [],
        [ALERT_TYPE_TECHNICAL_HELP]: [],
        [ALERT_TYPE_MEDICAL_HELP]: [],
      }

      return this.liveAlertContacts.reduce((alertContacts, alertContact) => {
        if (alertContacts[alertContact.type]) {
          alertContacts[alertContact.type] = [...alertContacts[alertContact.type], alertContact]
        }

        return alertContacts
      }, defaultAlertContacts)
    },
  },

  methods: {
    ...mapActions('ui', ['addToastMessage']),

    async fetchLiveAlertContacts() {
      this.loading = true

      try {
        this.liveAlertContacts = await this.$services.liveAlertContactService.getAll(this.live.id)
      } catch (e) {
        this.liveAlertContacts = []
      }

      this.loading = false
    },

    async fetchLiveSpotTrackerConfig() {
      this.loading = true

      try {
        this.liveSpotTrackerConfig = await this.$services.liveSpotTrackerConfigService.getById(
          this.live.id,
        )
      } catch (err) {
        console.error(err)
      }

      this.loading = false
    },

    async updateAlertContact(liveAlertContact) {
      this.loading = true

      try {
        const updatedLiveAlertContact = await this.$services.liveAlertContactService.update(
          liveAlertContact,
        )

        this.liveAlertContacts = this.liveAlertContacts.map((alertContact) =>
          alertContact.id === updatedLiveAlertContact.id ? updatedLiveAlertContact : alertContact,
        )

        this.addToastMessage({
          text: 'Le contact a été mis à jour.',
          type: 'is-success',
        })
      } catch (err) {
        console.error(err)
        this.addToastMessage({
          text: 'Une erreur interne est survenue.',
          type: 'is-danger',
        })
      }

      this.loading = false
    },

    async deleteAlertContact(liveAlertContact) {
      this.loading = true

      try {
        const deletedLiveAlertContact = await this.$services.liveAlertContactService.delete(
          liveAlertContact.id,
        )

        this.liveAlertContacts = this.liveAlertContacts.filter(
          (alertContact) => alertContact.id !== deletedLiveAlertContact.id,
        )

        this.addToastMessage({
          text: 'Le contact a été supprimé.',
          type: 'is-success',
        })
      } catch (err) {
        console.error(err)
        this.addToastMessage({
          text: 'Une erreur interne est survenue.',
          type: 'is-danger',
        })
      }

      this.loading = false
    },
  },
}
</script>
