<template>
  <div class="container image-upload-form">
    <drag-drop
      v-show="showDragDrop"
      v-model="imageFile"
      :label="label"
      :errorMessage="errorMessage"
      :loading="loading"
    >
      <template>
        <div class="level is-mobile">
          <b-button
            class="level-left mr-3"
            type="is-primary"
            :loading="loading"
            :disabled="loading || errorMessage || !imageFile"
            label="Upload"
            @click="submitFile"
          />
          <b-button
            v-show="showCancelButton"
            class="level-right"
            type="is-light"
            label="Annuler"
            @click="toggleShowDragDrop"
          />
        </div>
      </template>
    </drag-drop>
    <b-field v-show="showImage" :label="label">
      <div class="is-flex-direction-column">
        <img :src="`${imageUrl}?${timestamp}`" class="image my-4 mx-auto" width="50%" />
        <b-button type="is-primary" @click="toggleShowDragDrop"> Modifier </b-button>
      </div>
    </b-field>
  </div>
</template>

<script>
import DragDrop from './DragDrop.vue'
import { checkFileType } from '@helpers/checkFileType'
import { convertFileToString } from '@helpers/convertFileToString.js'

export default {
  name: 'ImageUploadForm',

  components: {
    DragDrop,
  },

  props: {
    imageUrl: {
      validator: (v) => typeof v === 'string' || v === null,
    },
    imageType: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      required: true,
    },
    submit: {
      type: Function,
      required: true,
    },
  },

  data() {
    return {
      showDragDrop: false,
      imageFile: null,
      errorMessage: null,
      loading: false,
      timestamp: new Date().getTime(),
    }
  },

  mounted() {
    this.showDragDrop = !this.imageUrl
  },

  watch: {
    imageFile(imageFile) {
      if (imageFile) {
        this.errorMessage = checkFileType(imageFile, this.imageType)
      }
    },
  },

  computed: {
    showImage() {
      return this.imageUrl && !this.showDragDrop
    },

    showCancelButton() {
      return !!this.imageUrl
    },
  },

  methods: {
    toggleShowDragDrop() {
      this.showDragDrop = !this.showDragDrop
      this.imageFile = null
      this.errorMessage = null
    },

    async submitFile() {
      this.loading = true

      const image = await convertFileToString(this.imageFile)
      await this.submit(image)

      this.showDragDrop = false
      this.loading = false
      this.timestamp = new Date().getTime()
    },
  },
}
</script>

<style lang="scss">
.image-upload-form {
  height: fit-content;
}
</style>
