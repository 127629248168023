<template>
  <div>
    <h2 class="title is-6">Statistiques</h2>
    <live-add-stat-form :liveId="live.id" :position="stats.length + 1" @submit="createLiveStat" />
    <hr />
    <live-stat-table
      :liveStats="stats"
      @drop="updateStatIndexInArray"
      @submit="updateLiveStat"
      @delete="deleteLiveStat"
    />
  </div>
</template>

<script>
import LiveStatTable from './LiveStatTable.vue'
import LiveAddStatForm from './LiveAddStatForm.vue'

export default {
  name: 'EditLiveStatTab',

  components: {
    LiveStatTable,
    LiveAddStatForm,
  },

  props: {
    live: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      liveStats: [],
    }
  },

  async mounted() {
    this.liveStats = await this.$services.liveStatService.getAll(this.live.id)
  },

  computed: {
    stats() {
      return [...this.liveStats].sort((current, next) => {
        const a = current.position
        const b = next.position

        if (a < b) return -1
        if (a > b) return 1

        return 0
      })
    },
  },
  methods: {
    async createLiveStat(createdLiveStat) {
      this.liveStats.splice(createdLiveStat.position - 1, 0, createdLiveStat)

      await this.updateStatPositions()
    },

    async updateLiveStat(updatedLiveStat) {
      this.liveStats = this.liveStats.map((liveStat) =>
        liveStat.id === updatedLiveStat.id ? updatedLiveStat : liveStat,
      )
    },

    async deleteLiveStat(deletedStat) {
      this.liveStats = this.liveStats.filter((liveStat) => liveStat.id !== deletedStat.id)

      await this.updateStatPositions()
    },

    updateStatIndexInArray(currentIndex, targetIndex) {
      this.liveStats.splice(currentIndex, 0, ...this.liveStats.splice(targetIndex, 1))

      this.updateStatPositions()
    },

    async updateStatPositions() {
      for (let i = 0; i < this.liveStats.length; i++) {
        if (this.liveStats[i].position !== i) {
          this.liveStats[i].position = i

          await this.$services.liveStatService.update({
            ...this.liveStats[i],
            position: i,
          })
        }
      }
    },
  },
}
</script>
