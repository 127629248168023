<template>
  <div class="card is-flex is-flex-direction-column">
    <div class="card-header">
      <slot name="header" v-bind:title="title">
        <h2 class="card-header-title">{{ title }}</h2>
      </slot>
    </div>
    <div class="card-content is-flex-grow-1">
      <slot name="content" />
    </div>
    <div class="card-footer p-3">
      <slot name="footer" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'OCard',

  props: {
    title: {
      type: String,
      default: '',
    },
  },
}
</script>
