<template>
  <div class="container is-full">
    <div class="columns is-full is-vcentered">
      <h2 class="column is-narrow is-full-mobile">
        Total: <b>{{ trackerAvailabilities.length }}</b> | Non rendu:
        <b>{{ trackerAvailabilitiesNotAvailable.length }}</b>
      </h2>
      <b-field expanded class="column is-full-mobile">
        <b-input
          v-model="keywords"
          type="text"
          placeholder="Balise, Dossard"
          icon-right-clickable
          icon-right="close-circle"
          @icon-right-click="keywords = ''"
        />
      </b-field>
    </div>
    <live-tracker-availability-table
      :loading="loading"
      :trackers="filteredTrackers"
      :checked-trackers="checkedTrackers"
      class="column is-full"
      @checkTrackers="checkedTrackers = $event"
    />
    <live-tracker-availability-form
      v-show="checkedTrackers.length > 0"
      :loading="loading"
      class="column is-full"
      @submit="updateAll"
    />
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import LiveTrackerAvailabilityTable from './LiveTrackerAvailabilityTable.vue'
import LiveTrackerAvailabilityForm from './LiveTrackerAvailabilityForm.vue'
import {
  AVAILABILITY_AVAILABLE,
  AVAILABILITY_UNAVAILABLE,
} from '@constants/tracker/availability.js'

export default {
  name: 'LiveTrackerAvailabilityChecklist',

  components: {
    LiveTrackerAvailabilityForm,
    LiveTrackerAvailabilityTable,
  },

  props: {
    loading: {
      type: Boolean,
      required: true,
    },
    vehicleNumbers: {
      type: Object,
      required: true,
    },
    trackers: {
      type: Array,
      required: true,
    },
    trackerAvailabilities: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      keywords: '',
      checkedTrackers: [],
    }
  },

  computed: {
    filteredTrackers() {
      const trackers = this.trackerAvailabilities.map((liveTrackerAvailability) => ({
        ...liveTrackerAvailability,
        owner: this.trackerList[liveTrackerAvailability.trackerId].owner,
        trackerNumber: this.trackerList[liveTrackerAvailability.trackerId].number,
        vehicleNumber: this.vehicleNumbers[liveTrackerAvailability.trackerId],
      }))

      if (this.keywords.length === 0) {
        return trackers
      }

      return trackers.filter((tracker) => {
        const term = this.keywords?.toLowerCase()
        const vehicleNumber = tracker.vehicleNumber.toLowerCase()
        const trackerNumber = tracker.trackerNumber.toLowerCase()

        return vehicleNumber.startsWith(term) || trackerNumber.startsWith(term)
      })
    },

    trackerList() {
      return this.trackers.reduce(
        (trackers, tracker) => ({
          ...trackers,
          [tracker.id]: {
            ...tracker,
          },
        }),
        {},
      )
    },

    trackerAvailabilitiesNotAvailable() {
      return this.trackerAvailabilities.filter(
        (liveTrackerAvailability) =>
          liveTrackerAvailability.availability === AVAILABILITY_UNAVAILABLE,
      )
    },
  },

  methods: {
    ...mapActions('ui', ['addToastMessage']),

    async updateAll({ availability, owner }) {
      this.$emit('loading', true)

      try {
        if (availability === AVAILABILITY_AVAILABLE) {
          await this.updateTrackers(owner)
        }

        await this.updateTrackerAvailabilities(availability)

        this.checkedTrackers = []

        this.addToastMessage({
          text: 'Les balises ont été mis à jour.',
          type: 'is-success',
        })
      } catch (err) {
        this.addToastMessage({
          text: 'Une erreur interne est survenue.',
          type: 'is-danger',
        })
      }

      this.$emit('loading', false)
    },

    async updateTrackers(owner) {
      return await new Promise((resolve, reject) => {
        const trackers = this.checkedTrackers.map((checkedTracker) => ({
          ...this.trackerList[checkedTracker.trackerId],
          owner,
        }))

        this.$emit('updateTrackers', { resolve, reject, trackers })
      })
    },

    async updateTrackerAvailabilities(availability) {
      return await new Promise((resolve, reject) => {
        const trackerAvailabilities = this.checkedTrackers.map((checkedTracker) => ({
          ...checkedTracker,
          availability,
        }))

        this.$emit('updateTrackerAvailabilities', { resolve, reject, trackerAvailabilities })
      })
    },
  },
}
</script>
