<template>
  <div>
    <h2 class="title is-6">{{ title }}</h2>

    <list-live-alert-contact-message :alert-type="alertType" class="mb-5" />

    <o-table :items="liveAlertContacts" :loading="loading" :draggable="false">
      <b-table-column v-slot="{ row }" field="type" label="Type" width="100">
        <template v-if="!showRow(row)">
          <b-icon
            size="is-small"
            :type="alertTypes[row.type].color"
            :icon="alertTypes[row.type].icon"
          />
        </template>
        <template v-else-if="showRow(row)">
          <b-field
            :message="fields.type.error"
            :type="fields.type.error !== null ? 'is-danger' : ''"
          >
            <o-dropdown v-model="fields.type.value" :items="Object.keys(alertTypes)">
              <template v-slot:label="{ label }">
                <b-icon
                  size="is-small"
                  :type="alertTypes[label].color"
                  :icon="alertTypes[label].icon"
                />
              </template>
              <template v-slot:item="{ item }">
                <b-icon
                  size="is-small"
                  :type="alertTypes[item].color"
                  :icon="alertTypes[item].icon"
                />
              </template>
            </o-dropdown>
          </b-field>
        </template>
      </b-table-column>
      <b-table-column v-slot="{ row }" field="name" label="Nom*">
        <template v-if="!showRow(row)">
          {{ row.name }}
        </template>
        <template v-else-if="showRow(row)">
          <b-field
            :message="fields.name.error"
            :type="fields.name.error !== null ? 'is-danger' : ''"
          >
            <b-input
              v-model="fields.name.value"
              type="text"
              :icon-right="fields.name.value ? 'close-circle' : ''"
              :icon-right-clickable="!!fields.name.value"
              @icon-right-click="fields.name.value = null"
            />
          </b-field>
        </template>
      </b-table-column>
      <b-table-column v-slot="{ row }" field="email" label="Email">
        <template v-if="!showRow(row)">
          {{ row.email }}
        </template>
        <template v-else-if="showRow(row)">
          <b-field
            :message="fields.email.error"
            :type="fields.email.error !== null ? 'is-danger' : ''"
          >
            <b-input
              v-model="fields.email.value"
              type="email"
              :icon-right="fields.email.value ? 'close-circle' : ''"
              :icon-right-clickable="!!fields.email.value"
              @icon-right-click="fields.email.value = null"
            />
          </b-field>
        </template>
      </b-table-column>
      <b-table-column v-slot="{ row }" field="phoneNumber" label="Téléphone">
        <template v-if="!showRow(row)">
          {{ row.phoneNumber }}
        </template>
        <template v-else-if="showRow(row)">
          <b-field
            :message="fields.phoneNumber.error"
            :type="fields.phoneNumber.error !== null ? 'is-danger' : ''"
          >
            <b-input
              v-model="fields.phoneNumber.value"
              :icon-right="fields.phoneNumber.value ? 'close-circle' : ''"
              :icon-right-clickable="!!fields.phoneNumber.value"
              @icon-right-click="fields.phoneNumber.value = null"
            />
          </b-field>
        </template>
      </b-table-column>
      <b-table-column v-slot="{ row }" label="" width="100">
        <div v-if="!showRow(row)" class="buttons are-small is-right">
          <b-button type="is-primary" :loading="loading" @click="selectedLiveAlertContact = row">
            <b-icon icon="edit" />
          </b-button>
          <b-button type="is-danger" :loading="loading" @click="toggleModal(row)">
            <b-icon icon="trash" />
          </b-button>
        </div>
        <div v-else-if="showRow(row)" class="buttons are-small is-right">
          <b-button type="is-success" :loading="loading" :disabled="isFormInvalid" @click="update">
            <b-icon icon="check" />
          </b-button>
          <b-button type="is-warning" @click="selectedLiveAlertContact = null">
            <b-icon icon="times" />
          </b-button>
        </div>
      </b-table-column>
    </o-table>
    <o-modal
      title="Suppression d'un contact"
      :isOpen="!!selectedLiveAlertContact && isOpenModal"
      @closeModal="toggleModal"
      @confirmModalAction="$emit('delete', selectedLiveAlertContact)"
    >
      <template> La suppression d'un contact est <strong>définitif</strong>. </template>
    </o-modal>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import OTable from '@components/Table.vue'
import ODropdown from '@components/Dropdown.vue'
import OModal from '@components/Modal.vue'
import ListLiveAlertContactMessage from './ListLiveAlertContactMessage.vue'
import { isEmail } from '@helpers/isEmail'
import {
  ALERT_TYPE_MEDICAL_HELP,
  ALERT_TYPE_OK,
  ALERT_TYPE_TECHNICAL_HELP,
} from '@constants/alert/type'

const alertTypes = {
  [ALERT_TYPE_MEDICAL_HELP]: {
    title: 'Alertes médicales',
    icon: 'heartbeat',
    color: 'is-danger',
  },
  [ALERT_TYPE_TECHNICAL_HELP]: {
    title: 'Alertes techniques',
    icon: 'wrench',
    color: 'is-danger',
  },
  [ALERT_TYPE_OK]: {
    title: 'Alertes OK',
    icon: 'thumbs-up',
    color: 'is-success',
  },
}

export default {
  name: 'ListLiveAlertContactTable',

  components: {
    OModal,
    OTable,
    ODropdown,
    ListLiveAlertContactMessage,
  },

  props: {
    alertType: {
      type: String,
      required: true,
      validate: (v) =>
        [ALERT_TYPE_MEDICAL_HELP, ALERT_TYPE_TECHNICAL_HELP, ALERT_TYPE_OK].indexOf(v) !== -1,
    },
    loading: {
      type: Boolean,
      required: true,
    },
    liveAlertContacts: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      isOpenModal: false,
      selectedLiveAlertContact: null,
      fields: {
        type: {
          value: null,
          error: null,
        },
        email: {
          value: null,
          error: null,
        },
        phoneNumber: {
          value: null,
          error: null,
        },
        name: {
          value: null,
          error: null,
        },
      },
    }
  },

  created() {
    this.alertTypes = alertTypes
  },

  watch: {
    liveAlertContacts() {
      this.selectedLiveAlertContact = null
    },

    selectedLiveAlertContact() {
      this.initFields()
    },

    'fields.name.value'(name) {
      this.fields.name.error = name?.length > 0 ? null : 'Nom requis'
    },

    'fields.email.value'() {
      this.fields.email.error = this.fieldEmailError
    },

    'fields.phoneNumber.value'() {
      this.fields.email.error = this.fieldEmailError
    },
  },

  computed: {
    title() {
      return this.alertTypes[this.alertType].title
    },

    isFormInvalid() {
      return Object.values(this.fields).find((field) => field.error !== null) || this.loading
    },

    fieldEmailError() {
      if (!this.fields.phoneNumber.value?.length > 0 && !this.fields.email.value?.length > 0) {
        return 'Au moins un email ou téléphone requis'
      }

      if (this.fields.email.value?.length > 0 && !isEmail(this.fields.email.value)) {
        return 'Email incorrect'
      }

      return null
    },
  },

  methods: {
    ...mapActions('ui', ['addToastMessage']),

    update() {
      this.$emit('update', {
        ...this.selectedLiveAlertContact,
        type: this.fields.type.value,
        email: this.fields.email.value,
        phoneNumber: this.fields.phoneNumber.value,
        name: this.fields.name.value?.length > 0 ? this.fields.name.value : null,
      })
    },

    showRow(row) {
      return (
        this.selectedLiveAlertContact &&
        !this.isOpenModal &&
        row.id === this.selectedLiveAlertContact.id
      )
    },

    toggleModal(alertContact = null) {
      this.isOpenModal = !!alertContact
      this.selectedLiveAlertContact = alertContact
    },

    initFields() {
      if (!this.selectedLiveAlertContact) {
        this.resetFields()
        return
      }

      Object.keys(this.fields).forEach(
        (key) => (this.fields[key].value = this.selectedLiveAlertContact[key]),
      )
    },

    resetFields() {
      Object.values(this.fields).forEach((field) => {
        field.value = null
        field.error = null
      })
    },
  },
}
</script>
