import { STATUS_COMPLETED, STATUS_FINISHED, STATUS_PENDING } from '@constants/live/status.js'
import {
  VISITOR_ACCESS_TYPE_PRIVATE,
  VISITOR_ACCESS_TYPE_PROTECTED,
  VISITOR_ACCESS_TYPE_PUBLIC,
} from '@constants/live/visitorAccessType.js'

export const statusTag = {
  [STATUS_PENDING]: { label: 'EN ATTENTE', type: 'is-light is-danger' },
  [STATUS_COMPLETED]: { label: 'EN COURS', type: 'is-light is-warning' },
  [STATUS_FINISHED]: { label: 'TERMINÉ', type: 'is-light is-success' },
}

export const visitorAccessTypeIcon = {
  [VISITOR_ACCESS_TYPE_PUBLIC]: { icon: 'unlock', type: 'is-success' },
  [VISITOR_ACCESS_TYPE_PRIVATE]: { icon: 'lock', type: 'is-danger' },
  [VISITOR_ACCESS_TYPE_PROTECTED]: { icon: 'lock', type: 'is-warning' },
}
