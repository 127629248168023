<template>
  <b-collapse
    animation="slide"
    :open="isOpen"
    aria-id="contentIdForA11y1"
    class="card"
    @open="$emit('click', zone)"
  >
    <template #trigger="props">
      <div
        aria-controls="contentIdForA11y1"
        :aria-expanded="props.open"
        role="button"
        class="card-header"
      >
        <h2 class="card-header-title">{{ zone.name }}</h2>

        <div class="card-header-icon">
          <b-button type="is-primary" icon-left="pen" class="mr-2" :disabled="isOpen" />
          <b-button type="is-danger" icon-left="trash" @click="$emit('delete', zone)" />
        </div>
      </div>
    </template>
    <div class="notification content">
      <div class="columns is-multiline">
        <o-input-location
          label="Début*"
          :value="zone.start"
          lazy
          :coordinate-format="coordinateFormat"
          class="column"
          @input="updatePosition('start', $event)"
        />
        <o-input-location
          label="Fin*"
          :value="zone.end"
          lazy
          :coordinate-format="coordinateFormat"
          class="column"
          @input="updatePosition('end', $event)"
        />
      </div>
      <div class="columns is-multiline">
        <b-field
          label="Largeur de zone (mètre)*"
          :message="fields.distance.error"
          :type="fields.distance.error !== null ? 'is-danger' : ''"
          class="column is-full-mobile"
        >
          <b-input v-model="fields.distance.value" type="number" />
        </b-field>
        <b-field
          label="Color*"
          :message="fields.color.error"
          :type="fields.color.error !== null ? 'is-danger' : ''"
          class="column is-full-mobile"
        >
          <o-dropdown
            v-model="fields.color.value"
            :items="colors"
            @input="fields.color.value = $event"
          />
        </b-field>
        <b-field label="Catégorie" class="column is-full-mobile">
          <b-select v-model="fields.liveCategoryId.value" expanded>
            <option :value="null">—</option>
            <option v-for="category in liveCategories" :key="category.id" :value="category.id">
              {{ category.name }}
            </option>
          </b-select>
        </b-field>
      </div>
      <div class="columns is-multiline">
        <b-field
          label="Nom*"
          :message="fields.name.error"
          :type="fields.name.error !== null ? 'is-danger' : ''"
          class="column is-full-mobile"
        >
          <b-input v-model="fields.name.value" type="text" />
        </b-field>
        <b-field
          label="Vitesse limite (km/h)*"
          :message="fields.speed.error"
          :type="fields.speed.error !== null ? 'is-danger' : ''"
          class="column is-full-mobile"
        >
          <b-input v-model="fields.speed.value" type="number" />
        </b-field>
      </div>
    </div>
  </b-collapse>
</template>

<script>
import ODropdown from '@components/Dropdown.vue'
import OInputLocation from '@components/InputLocation.vue'
import { COLORS } from '@constants/stage/color'
import { FORMAT_DD, FORMAT_DDM, FORMAT_DMS } from '@filters/coordinate'
import { SpeedZone } from '../index'

export default {
  name: 'LiveSpeedZoneFields',

  components: {
    ODropdown,
    OInputLocation,
  },

  props: {
    zone: {
      type: SpeedZone,
      required: true,
    },
    isOpen: {
      type: Boolean,
      required: true,
    },
    coordinateFormat: {
      type: String,
      required: true,
      validator: (v) => [FORMAT_DD, FORMAT_DDM, FORMAT_DMS].indexOf(v) !== -1,
    },
    liveCategories: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      fields: {
        name: {
          value: this.zone.name,
          error: null,
        },
        color: {
          value: this.zone.color,
          error: null,
        },
        speed: {
          value: this.zone.speed,
          error: null,
        },
        distance: {
          value: this.zone.distance,
          error: null,
        },
        liveCategoryId: {
          value: null,
          error: null,
        },
      },
    }
  },

  created() {
    this.colors = COLORS
  },

  watch: {
    'fields.name.value'(name) {
      this.fields.name.error = this.fieldNameError

      if (!this.fieldNameError) {
        this.updateField('name', name)
      }
    },

    'fields.color.value'(color) {
      this.fields.color.error = this.fieldColorError

      if (!this.fieldColorError) {
        this.updateField('color', color)
      }
    },

    'fields.speed.value'(speed) {
      this.fields.speed.error = this.fieldSpeedError

      if (!this.fieldSpeedError) {
        this.updateField('speed', +speed)
      }
    },

    'fields.distance.value'(distance) {
      this.fields.distance.error = this.fieldDistanceError

      if (!this.fieldDistanceError) {
        this.updateField('distance', +distance)
      }
    },

    'fields.liveCategoryId.value'(liveCategoryId) {
      this.updateField('liveCategoryId', liveCategoryId)
    },

    hasError(hasError) {
      this.updateField('hasErrorOnCreate', hasError)
    },
  },

  computed: {
    fieldNameError() {
      return this.fields.name.value?.length > 0 ? null : 'Le nom est requis'
    },

    fieldColorError() {
      return this.fields.color.value?.length > 0 ? null : 'La couleur est requise'
    },

    fieldSpeedError() {
      return this.fields.speed.value > 0 ? null : 'La vitesse limite est requise'
    },

    fieldDistanceError() {
      return this.fields.distance.value > 0 ? null : 'La largeur de zone est requise'
    },

    hasError() {
      return !!Object.values(this.fields).find((field) => field.error !== null)
    },
  },

  methods: {
    updatePosition(position, { latLng, hasError }) {
      this.updateField('hasErrorOnCreate', hasError || this.hasError)

      if (!hasError) {
        this.updateField(position, latLng)
      }
    },

    updateField(field, value) {
      this.$emit('updateField', { field, value })
    },
  },
}
</script>
