<template>
  <div class="box">
    <form novalidate="true" @submit.prevent="submit">
      <h2 class="title is-4">Ajouter un contact d'alerte</h2>
      <b-field
        label="Type*"
        :message="fields.type.error"
        :type="fields.type.error !== null ? 'is-danger' : ''"
      >
        <b-select v-model="fields.type.value" expanded>
          <option v-for="type in alertTypes" :key="type" :value="type">
            {{ type }}
          </option>
        </b-select>
      </b-field>
      <b-field
        label="Nom*"
        :message="fields.name.error"
        :type="fields.name.error !== null ? 'is-danger' : ''"
      >
        <b-input v-model="fields.name.value" type="text" />
      </b-field>
      <b-field
        label="Email"
        :message="fields.email.error"
        :type="fields.email.error !== null ? 'is-danger' : ''"
      >
        <b-input v-model="fields.email.value" type="email" />
      </b-field>
      <b-field
        label="Téléphone"
        :message="fields.phoneNumber.error"
        :type="fields.phoneNumber.error !== null ? 'is-danger' : ''"
      >
        <b-input v-model="fields.phoneNumber.value" type="text" />
      </b-field>
      <b-field>
        <div class="buttons">
          <b-button native-type="submit" type="is-primary" :loading="loading">Ajouter</b-button>
          <b-button
            type="is-danger"
            tag="router-link"
            :to="{ name: 'live.edit.vehicle.list', params: { tab: 2 } }"
          >
            Annuler
          </b-button>
        </div>
      </b-field>
    </form>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import {
  ALERT_TYPE_MEDICAL_HELP,
  ALERT_TYPE_OK,
  ALERT_TYPE_TECHNICAL_HELP,
} from '@constants/alert/type'
import { isEmail } from '@helpers/isEmail'

export default {
  name: 'EditLiveAddAlertContact',

  props: {
    live: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      loading: false,
      fields: {
        name: {
          value: null,
          error: null,
        },
        type: {
          value: null,
          error: null,
        },
        phoneNumber: {
          value: null,
          error: null,
        },
        email: {
          value: null,
          error: null,
        },
      },
    }
  },

  created() {
    this.alertTypes = [ALERT_TYPE_MEDICAL_HELP, ALERT_TYPE_TECHNICAL_HELP, ALERT_TYPE_OK]
  },

  watch: {
    'fields.type.value'() {
      this.fields.type.error = this.fieldTypeError
    },

    'fields.name.value'() {
      this.fields.name.error = this.fieldNameError
    },

    'fields.email.value'() {
      this.fields.email.error = this.fieldEmailError
    },

    'fields.phoneNumber.value'() {
      this.fields.phoneNumber.error = this.fieldPhoneNumberError
    },
  },

  computed: {
    fieldTypeError() {
      return this.fields.type.value?.length > 0 ? null : 'Le type est requis'
    },

    fieldNameError() {
      return this.fields.name.value?.length > 0 ? null : 'Le nom est requis'
    },

    fieldEmailError() {
      if (!this.fields.email.value) {
        return null
      }

      return isEmail(this.fields.email.value) ? null : 'Email incorrect'
    },

    fieldPhoneNumberError() {
      return this.fields.phoneNumber.value?.length > 0 || this.fields.email.value?.length > 0
        ? null
        : 'Téléphone ou email requis'
    },

    isFormInvalid() {
      return !!Object.values(this.fields).find((field) => field.error !== null)
    },
  },

  methods: {
    ...mapActions('ui', ['addToastMessage']),

    async submit() {
      this.setFormError()
      if (this.isFormInvalid) {
        return
      }

      this.loading = true

      try {
        await this.$services.liveAlertContactService.create({
          liveId: this.live.id,
          type: this.fields.type.value,
          name: this.fields.name.value?.length > 0 ? this.fields.name.value : null,
          email: this.fields.email.value?.length > 0 ? this.fields.email.value : null,
          phoneNumber:
            this.fields.phoneNumber.value?.length > 0 ? this.fields.phoneNumber.value : null,
        })

        this.addToastMessage({
          text: `Le contact d'alerte a été créé.`,
          type: 'is-success',
        })

        this.$router.push({
          name: 'live.edit.vehicle.list',
          params: { tab: 2 },
        })
      } catch (err) {
        this.error = 'Une erreur interne est survenue.'
        console.error(err)
      }

      this.loading = false
    },

    setFormError() {
      this.fields.type.error = this.fieldTypeError
      this.fields.name.error = this.fieldNameError
      this.fields.email.error = this.fieldEmailError
      this.fields.phoneNumber.error = this.fieldPhoneNumberError
    },
  },
}
</script>
