<template>
  <div class="level is-flex-direction-column">
    <div class="level-right ml-auto">
      <p class="level-item">
        <b-input
          v-model="filters.keyword"
          type="text"
          placeholder="Rechercher par titre"
          :icon-right="filters.keyword ? 'close-circle' : ''"
          icon-right-clickable
          @icon-right-click="clearFilter('keyword')"
        />
      </p>
      <p class="level-item">
        <b-select v-model="filters.accessType" placeholder="Choisir un accès" icon="lock">
          <option :value="null">Choisir un accès</option>
          <option v-for="accessType in accessTypes" :key="accessType" :value="accessType">
            {{ accessType }}
          </option>
        </b-select>
      </p>
    </div>
    <div class="level-right ml-auto">
      <p class="level-item">
        <b-button
          v-show="showClearFilterButton"
          class="is-small"
          type="is-text"
          @click="clearFilter('all')"
        >
          Supprimer tous les filtres
        </b-button>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ListLiveTutorialsFilter',

  data() {
    return {
      filters: {
        keyword: '',
        accessType: null,
      },
    }
  },

  created() {
    this.accessTypes = ['public', 'private']
  },

  watch: {
    filters: {
      handler() {
        this.$emit('filters', this.filters)
      },
      immediate: true,
      deep: true,
    },
  },

  computed: {
    showClearFilterButton() {
      return this.filters.accessType !== null
    },
  },

  methods: {
    clearFilter(filter) {
      this.filters =
        filter !== 'all' ? { ...this.filters, [filter]: '' } : { keyword: '', accessType: null }
    },
  },
}
</script>
