<template>
  <b-button type="is-light" :disabled="loading || liveVehicles.length === 0" @click="exportCsv">
    <b-icon icon="file-export" size="is-small" />
    <span>Export CSV</span>
  </b-button>
</template>

<script>
import { csvHeader } from './csvHeader.js'
import { exportToCSV } from '@helpers/exportToCsv'
import { downloadFile } from '@helpers/downloadFile'

export default {
  name: 'LiveVehicleExportCsv.vue',

  props: {
    fileName: {
      type: String,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
    liveVehicles: {
      type: Array,
      required: true,
      validator: (v) =>
        v.every((obj) =>
          ['name', 'number', 'gender', 'country', 'accessType', 'trackers', 'categoryName'].every(
            (key) => key in obj,
          ),
        ),
    },
  },

  methods: {
    exportCsv() {
      const data = this.liveVehicles.map((vehicle, index) => {
        return {
          [csvHeader.line]: index + 1,
          [csvHeader.accessType]: vehicle.accessType,
          [csvHeader.type]: vehicle.trackers[0]?.type,
          [csvHeader.trackerNumber]: vehicle.trackers[0]?.number,
          [csvHeader.number]: vehicle.number,
          [csvHeader.name]: vehicle.name,
          [csvHeader.gender]: vehicle.gender,
          [csvHeader.country]: vehicle.country,
          [csvHeader.categoryName]: vehicle.categoryName,
        }
      })

      downloadFile(exportToCSV(data), 'csv', this.fileName)
    },
  },
}
</script>
