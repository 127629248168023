<template>
  <div class="columns is-multiline">
    <div v-if="!selectedVehicle" class="column is-full">
      <b-field :label="title">
        <b-autocomplete
          v-model="keywords"
          open-on-focus
          :data="liveVehicleList"
          placeholder="Nom, Dossard, Balise"
          :custom-formatter="(option) => `${option.number} - ${option.name}`"
          @select="(option) => (selectedVehicle = option)"
        >
          <template v-slot="{ option }"> {{ option.number }} - {{ option.name }} </template>
        </b-autocomplete>
      </b-field>
    </div>
    <div v-else class="column is-full">
      <b-message :title="messageTitle" type="is-light" @close="resetData">
        <div style="overflow: hidden">
          <div
            v-if="fields.tracker.value && toTracker"
            class="columns is-vcentered is-centered mt-1"
          >
            <b-tag
              type="is-danger is-light"
              size="is-large"
              class="column is-narrow is-full-mobile"
              style="text-decoration: line-through"
            >
              {{ fields.tracker.value.type }} - {{ fields.tracker.value.number }}
            </b-tag>
            <div class="column is-narrow is-hidden-mobile">
              <b-icon icon="arrow-right" />
            </div>
            <b-tag
              type="is-success is-light"
              size="is-large"
              class="column is-narrow is-full-mobile"
            >
              {{ toTracker.type }} - {{ toTracker.number }}
            </b-tag>
          </div>
          <template v-if="selectedVehicle.trackers.length > 0">
            <b-field
              label="Balise à changer"
              :message="fields.tracker.error"
              :type="fields.tracker.error !== null ? 'is-danger' : ''"
            >
              <b-select
                v-model="fields.tracker.value"
                placeholder="Sélectionner la balise"
                expanded
              >
                <option
                  v-for="tracker in selectedVehicle.trackers"
                  :key="tracker.id"
                  :value="tracker"
                >
                  {{ tracker.type }} - {{ tracker.number }}
                </option>
              </b-select>
            </b-field>
            <b-field
              label="Dossard"
              :message="fields.vehicleNumber.error"
              :type="fields.vehicleNumber.error !== null ? 'is-danger' : ''"
            >
              <b-input v-model="fields.vehicleNumber.value" type="text" />
            </b-field>
          </template>
          <b-message v-else type="is-danger"> Aucune balise associé à ce participant </b-message>
        </div>
      </b-message>
    </div>
  </div>
</template>

<script>
import { stringNormalizer } from '@helpers/stringNormalizer.js'

export default {
  name: 'LiveVehicleTrackerCard',

  props: {
    title: {
      type: String,
      required: true,
    },
    toTracker: {
      type: Object,
      default: null,
      validator: (v) => ['id', 'type', 'number'].every((key) => key in v),
    },
    liveVehicles: {
      type: Array,
      required: true,
      validator: (v) =>
        v.every((obj) => ['id', 'name', 'number', 'trackers'].every((key) => key in obj)),
    },
  },

  data() {
    return {
      keywords: '',
      selectedVehicle: null,
      fields: {
        tracker: {
          value: null,
          error: null,
        },
        vehicleNumber: {
          value: null,
          error: null,
        },
      },
    }
  },

  watch: {
    liveVehicles(liveVehicles) {
      if (this.selectedVehicle) {
        const updatedVehicle = liveVehicles.find(
          (vehicle) => vehicle.id === this.selectedVehicle.id,
        )

        if (updatedVehicle) {
          this.resetData()
        }
      }
    },

    selectedVehicle(selectedVehicle) {
      this.fields.vehicleNumber.value = this.selectedVehicle?.number
      this.$emit('selectVehicle', selectedVehicle)
    },

    toTracker() {
      this.fields.tracker.error = this.trackerErrorMessage
      this.$emit('hasError', this.hasError)
    },

    'fields.tracker.value'(value) {
      this.fields.tracker.error = this.trackerErrorMessage

      this.$emit('hasError', this.hasError)
      this.$emit('selectTracker', value)
    },

    'fields.vehicleNumber.value'(value) {
      this.fields.vehicleNumber.error = value?.length === 0 ? 'Le dossard est requis' : null

      this.$emit('hasError', this.hasError)
      this.$emit('updateSelectedVehicle', {
        ...this.selectedVehicle,
        number: this.fields.vehicleNumber.error ? this.selectedVehicle.number : value,
      })
    },
  },

  computed: {
    hasError() {
      return !!(this.fields.vehicleNumber.error || this.fields.tracker.error)
    },

    trackerErrorMessage() {
      if (!this.fields.tracker.value?.type || !this.toTracker?.type) {
        return null
      }

      if (this.fields.tracker.value.type !== this.toTracker.type) {
        return "La balise n'est pas du même type"
      }

      return this.fields.tracker.value.id === this.toTracker.id
        ? 'Les balises sont identiques'
        : null
    },

    messageTitle() {
      return `${this.selectedVehicle.number} - ${this.selectedVehicle.name}`
    },

    liveVehicleList() {
      return this.liveVehicles.filter((vehicle) => {
        const term = stringNormalizer(this.keywords?.toLowerCase())
        const name = stringNormalizer(vehicle.name.toLowerCase())
        const number = stringNormalizer(vehicle.number.toLowerCase())
        const trackerNumbers = stringNormalizer(
          vehicle.trackers
            .map((tracker) => tracker.number)
            .join(' ')
            .toLowerCase(),
        )

        return (
          name.indexOf(term) >= 0 || number.indexOf(term) >= 0 || trackerNumbers.indexOf(term) >= 0
        )
      })
    },
  },

  methods: {
    resetData() {
      this.keywords = ''
      this.selectedVehicle = null
      this.fields = {
        tracker: {
          value: null,
          error: null,
        },
        vehicleNumber: {
          value: null,
          error: null,
        },
      }
    },
  },
}
</script>
