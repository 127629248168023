<template>
  <page-layout :title="title" :nav-items="navItems">
    <template v-slot:breadcrumb>
      <li><router-link :to="{ name: 'home' }">Dashboard</router-link></li>
      <li><router-link :to="{ name: 'live.list' }">Lives</router-link></li>
      <li class="is-active">
        <a href="#">{{ title }}</a>
      </li>
    </template>
    <div v-if="live">
      <router-view :live.sync="live" />
    </div>
  </page-layout>
</template>

<script>
import PageLayout from '@components/PageLayout.vue'

export default {
  name: 'EditLivePage',

  components: {
    [PageLayout.name]: PageLayout,
  },

  props: {
    id: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      live: null,
    }
  },

  watch: {
    id: {
      handler: 'load',
      immediate: true,
    },
  },

  computed: {
    title() {
      return this.live ? this.live.name : '...'
    },

    navItems() {
      return this.live
        ? [
            {
              label: 'Général',
              active: this.$route.name === 'live.edit.informations',
              to: {
                name: 'live.edit.informations',
                params: { id: this.live.id },
              },
            },
            {
              label: 'Checklist',
              active: this.$route.name === 'live.edit.checklist',
              to: {
                name: 'live.edit.checklist',
                params: { id: this.live.id },
              },
            },
            {
              label: 'Étapes',
              active: this.$route.name.startsWith('live.edit.stage'),
              to: {
                name: 'live.edit.stage.list',
                params: { id: this.live.id },
              },
            },
            {
              label: 'Catégories',
              active: this.$route.name.startsWith('live.edit.category'),
              to: {
                name: 'live.edit.category.list',
                params: { id: this.live.id },
              },
            },
            {
              label: 'Véhicules',
              active: this.$route.name.startsWith('live.edit.vehicle'),
              to: {
                name: 'live.edit.vehicle.list',
                params: { id: this.live.id },
              },
            },
            {
              label: 'Interface',
              active: this.$route.name.startsWith('live.edit.settings'),
              to: {
                name: 'live.edit.settings.edit',
                params: { id: this.live.id },
              },
            },
          ]
        : []
    },
  },

  methods: {
    async load() {
      this.live = await this.$services.liveService.getById(this.id)
    },
  },
}
</script>
